import React from "react";
import { useNavigate } from "react-router-dom";
import { AnticipationContext } from "..";
import { FormatRealNumber } from "../../../../Components/Helper/FormatValues";
import CustomTable from "../../../../Components/Table";
 
export default function ReadTable() {
  const navigate = useNavigate();
  const { invoiceList } = React.useContext(AnticipationContext);

  const options = {
    onRowClick: (rowData: any, rowState:any) => {
      const { rowIndex } = rowState;
      if(invoiceList[rowIndex].nature.id === "1") window.open(`/receipts/description/${invoiceList[rowIndex].id}`, '_blank') //navigate(`/receipts/description/${invoiceList[rowIndex].id}`)
      if(invoiceList[rowIndex].nature.id === "2") window.open(`/payments/description/${invoiceList[rowIndex].id}`, '_blank')//navigate(`/payments/description/${invoiceList[rowIndex].id}`)
    },
  };

  return (
   <CustomTable data={invoiceList} opt={options} columns={columns} elevation={2}/>
  );
}

const columns = [
  { name: "id", label: "Número da fatura", options: { filter: true, sort: true, },},
  { name: "nature",
    label: "Natureza",
    options: { filter: true, sort: true, 
    customBodyRender: (value:any) => value.name },
  },
  { name: "status",
    label: "Status",
    options: { filter: true, sort: true, },},
  { name: "total",
    label: "Valor",
    options: { filter: true, sort: true, 
    customBodyRender: (value:any) => FormatRealNumber(value) },
  },
];


import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link } from "react-router-dom";
import { Box, createStyles, makeStyles, Theme, Button, Divider, InputAdornment, Paper, TextField, Typography} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MailIcon from '@material-ui/icons/Mail';
import FormLoadingComponent from '../../../Components/FormLoading';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../../Services/options/useAuth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      padding: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
    },
    sloganTitle: {
      marginBottom: theme.spacing(2),
    },
    form: {
      padding: theme.spacing(4),
      maxWidth: '400px',
    },
    submit: {
      marginTop: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
  })
);

interface IFormData {
  email?: string;
}

export default function ForgotPassword() {
  const classes = useStyles();
  const {recoverPassword, loading, error} = useAuth()
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: IFormData = {
    email: '',
  };

  const formSchema = Yup.object().shape({
    email: Yup.string().email('E-mail inválido').required('Obrigatório'),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      await recoverPassword(values).then(r=>{
        enqueueSnackbar("Verifique seu email", { variant: "info" });
      }).catch(e=>{
        enqueueSnackbar("Erro ao recuperar senha", { variant: "error" });
      })
     !loading && formik.setSubmitting(false);
    },
  });

  return (
    <div className={classes.root}>
      <Box style={{marginRight: '2rem'}}>
       <img src='/img/logo/logo_kikai.png' alt='' style={{width: 250, height: "100%"}} />
      </Box>
      <Paper className={classes.form} elevation={3}>
        <Box textAlign="center">
          <Typography component="h3" variant="h5">
            Esqueceu sua senha?
          </Typography>
        </Box>

        <form noValidate onSubmit={formik.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            placeholder="Seu e-mail"
            name="email"
            autoComplete="email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailIcon />
                </InputAdornment>
              ),
            }}
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <Button
            className={classes.submit}
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
          >
            Esqueci a minha senha
          </Button>
          {formik.isSubmitting && <FormLoadingComponent/>}
        </form>

        <Divider className={classes.divider} variant="fullWidth" />

        <Box mt={1} textAlign="center">
          <Link to="/auth/login">
            <Button color="primary" startIcon={<ArrowBackIcon />}>
              Voltar
            </Button>
          </Link>
        </Box>
      </Paper>
    </div>
  );
}
import { saveAs } from "file-saver";
import { write, utils } from 'xlsx';

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

export const jsonToExcel = ( data: any,  fileName: string = 'arquivo') => {
  if(data?.length <= 0){
    return
  }
    const ws = utils.json_to_sheet(data);
    const wb = {
      Sheets: {
        data: ws
      },
      SheetNames: ["data"]
    };

    const eb = write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([eb], { type: EXCEL_TYPE });
    saveAs(blob, fileName + EXCEL_EXTENSION);
};
import React from "react";
import CustomTable from "../../../../Components/Table";

interface PropsTable {
  data   : any;
  load?  : boolean;
  error? : any;
}
 
export default function Table({data, error, load }:PropsTable) {
 return (
  <CustomTable data={data} columns={columns} error={error} load={load} elevation={1}/>
 );
}

const columns = [
  { name: "id", options: { display: "false" } },
  { name: "bank_name",
    label: "Banco",
    options: { filter: true, sort: true, width: 100, },
  },
  { name: "agency",
    label: "Agência",
    options: { filter: true, sort: true, },
  },
  { name: "account_number",
  label: "Conta",
  options: { filter: true, sort: true, },},
];


import React from 'react'
import * as Icons from "@material-ui/icons";
import { List, ListItem, Typography,  ListItemIcon, ListItemText, Grid, Box} from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DefaultModal, { DefaultModalContent } from '../../../../../../Components/DefaultModal';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';
import DeleteBank from './DeleteBank';
import EditBank from './EditBank';
import { InformationContext } from '../../..';
import { ShowDetailList } from '../../../../../../Components/ShowDetailList';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root:{
    margin: 0,
    padding: 0,
    minHeight: "15rem",
  },
  main: {
    padding: "0rem 0.5rem 1rem 0.5rem",
    margin:  "0px",
    [theme.breakpoints.up("xs")]: { minWidth: 380 },
    [theme.breakpoints.up("sm")]: { minWidth: 380 },
    [theme.breakpoints.up("md")]: { minWidth: 400 },  
    [theme.breakpoints.up("lg")]: { minWidth: 400 },
    [theme.breakpoints.up("xl")]: { minWidth: 400 },
  },
  speedDial:{
    transform: 'scale(0.825)',
  },
  listTextItem:{
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.primary.main,
    [theme.breakpoints.up("xl")]: { fontSize: 18 },
  },
  listTextSubItem:{
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    margin:"0px 0px 0px 10px",
    [theme.breakpoints.up("xl")]: { fontSize: 16 },
  },
})
);

export const ShowBank = ({select, open, close}: any) => {
  const classes = useStyles();
  const [openDial, setOpenDial] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [option, setOption] = React.useState(null);
  const { banks } = React.useContext(InformationContext)
  const selectData = banks[select]
 
  const listBank = [
    {
      icon: <Icons.AccountBalance />,
      text: "Banco: ",
      data: selectData?.bank_name,
    },
    {
      icon: <Icons.AccountBalanceWallet />,
      text: "Agência: ",
      data: selectData?.agency,
    },
    {
      icon: <Icons.Payment />,
      text: "Conta: ",
      data: selectData?.account_number,
    },
  ]

  const actions = [
    { icon: <Icons.Edit />, 
      name: 'Atualizar banco',
      option: 0, 
      disabled: false},  
    { icon: <Icons.Cancel/>, 
      name: 'Apagar banco',
      option: 1,  
      disabled: false},     
    ]

  const handlActionsOpen = (e:any) => {
    if(e.type === "mouseenter" || e.type === "click")
    setOpenDial(true);
  };

  const handlActionsClose = (e:any) => {//mouseleave
    if(e.type === "mouseleave")
    setOpenDial(false);
  };

  const handleOpenView =(id: any)=>{
    setOption(id)
    setOpenView(true)
  }

  const handleCloseView =()=>{
    setOpenView(false)
    setOption(null)
  }

  const getSelectContent = React.useCallback((option: any) => { 
    switch (option) {
     case 0: return <EditBank   open={openView} close={handleCloseView} select={selectData}/>
     case 1: return <DeleteBank open={openView} close={close} closeView={handleCloseView} id={selectData.id}/>
     default: return null;
    }
  }, [option]);

  return (
  <>
   {getSelectContent(option)}
   <DefaultModal open={open} handleClose={close} title={"Banco"} maxWidth="sm">
    <DefaultModalContent dividers className={classes.root}>
     <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start"className={classes.main}>
      <Grid item xs={12}>
        <SpeedDial
          ariaLabel="SpeedDialClick"
          className={classes.speedDial}
          icon={ <Icons.Settings  />  }
          direction="right"
          FabProps={{size:"small" }}
          onClose={handlActionsClose}
          onOpen={handlActionsOpen}
          open={openDial} 
          >
          {actions.map((action: any) => (
           <SpeedDialAction
            tooltipPlacement="top"
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            hidden={action.disabled}
            onClick={()=>handleOpenView(action.option)}
            />
          ))}
         </SpeedDial>  
       </Grid>
  
       <Grid item xs={12}>
        <ShowDetailList list={listBank}/>
       </Grid> 
        
       </Grid>  
       </DefaultModalContent>
      </DefaultModal>
    </>
    )
  }

export default ShowBank
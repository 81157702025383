import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import * as Icons from "@material-ui/icons";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import NewTable from "./NewTable";
import { UserContext } from "../../../../../Context/UserContext";
import { useCompany } from "../../../../../Services/options/useCompany";
import { InformationContext } from "../..";
import { filterNumbers } from "../../../../../Components/Helper/FormatValues";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root:{
      display: "grid",
      padding: "1rem 1rem",
      maxWidth: "450px", 
      border: `1px solid  ${theme.palette.divider}`,
      borderRadius: "1rem",
      boxShadow: "1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    },
    table:{
     maxWidth: 500,
    },
    errorMsg:{
      fontWeight: "bold",
      fontSize: 14,
      color: "red",
      paddingLeft: "1rem",
    },
    dropContainer:{
      width: "100%",
      cursor: "pointer",
      minHeight: "70px",
      border: `1px solid  ${theme.palette.divider}`,
      borderRadius: "1rem",
      boxShadow: "1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
      alignContent: "flex-start",
      alignItems  : "flex-start",
      marginBottom: "0.5rem",
      //[theme.breakpoints.down("lg")]: { width: "50%" },
    },
    dropText: {
      color: theme.palette.primary.main,
      fontFamily: "Montserrat",
      fontWeight: "bold",
      fontSize: "1.1em",
      textAlign: "center",
      padding: "0.5rem 0rem",
    },
    dropIcon: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      color: theme.palette.secondary.main,
    },
    itemContainer: {
      padding: "0.5rem",
    },
    chipError: {
      textAlign: "center",
      fontSize: "0.7em",
      backgroundColor: "red",
      color: "#FFFFFF",
      fontFamily: "Montserrat",
      fontWeight: "bold",
    },
    title: {
      fontWeight: "bolder",
      fontSize: 20,
      color: theme.palette.secondary.main,
    },
    subText: {
      fontWeight: "bolder",
      fontSize: 14,
      marginBottom: "1rem",
      color: theme.palette.primary.main,
    },
  })
);

const ChargeImported = () => {
  const classes = useStyles();
  const { formik } = React.useContext(InformationContext);
  const { userInfo } = React.useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const { chargeParamsView, loading } = useCompany();

  const onDrop = React.useCallback((acceptedFiles: any) => {
    const file = acceptedFiles[0];
    handleUpload(file);
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "application/xml": [".xlsx", ".xml"],
    },
  });

  const files = acceptedFiles.map((file: any) => (
    <Chip
      key={file.path}
      icon={<Icons.Description />}
      label={file.path}
      color="primary"
    />
  ));

  const handleUpload = async (selectedFile: any) => {
    const formatCNPJ = filterNumbers(formik.values?.cnpj)
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("cnpj", formatCNPJ);
    formData.append("updatedBy", `{email: ${userInfo().email}}`);

    await chargeParamsView(formData)
      .then((r) => {
        const { data } = r.data;
        if (!r.data.hasError) {
          formik.setFieldValue("discountParams.chargeImported.chargeMin", data.chargeMin);
          formik.setFieldValue("discountParams.chargeImported.interval", data.chargeInterval);
        } else {
          enqueueSnackbar("Erro ao ler arquivo", { variant: "error" });
        }
      })
      .catch((e) => {
        enqueueSnackbar("Erro ao ler arquivo, preencha o CNPJ.", { variant: "error" });
      });
  };

  const onDelete = () => {
    acceptedFiles.pop();
    formik.setFieldValue("discountParams.chargeImported.chargeMin", null);
    formik.setFieldValue("discountParams.chargeImported.interval", []);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography gutterBottom className={classes.title}>
          Taxa variável
        </Typography>
      </Grid>
      {formik?.values?.discountParams?.chargeImported?.interval?.length === 0 && (
        <Grid item xs={12}>
          <Box {...getRootProps({ className: "dropzone" })}>
            <Box className={classes.dropContainer}>
              <input {...getInputProps()} />
              <Box style={{ textAlign: "center" }}>
                <Typography className={classes.dropText}>
                  Selecione o arquivo
                </Typography>
                {!loading ? (
                  <Icons.CloudUpload className={classes.dropIcon} />
                ) : (
                  <Icons.Cached className={classes.dropIcon} />
                )}
              </Box>
              <Box
                className={classes.itemContainer}
                style={{ textAlign: "center" }}
              >
                {formik.touched?.discountParams?.chargeImported &&
                  formik.errors?.discountParams?.chargeImported && (
                    <Chip
                      icon={<Icons.ErrorOutline style={{ color: "#FFFF" }} />}
                      label={formik.errors?.discountParams?.chargeImported}
                      className={classes.chipError}
                    />
                  )}
              </Box>
            </Box>
          </Box>
        </Grid>
      )}

      <Grid item xs={12} className={classes.table}>
        <NewTable
          rate={formik?.values?.discountParams?.chargeImported?.chargeMin}
          list={formik?.values?.discountParams?.chargeImported?.interval || []}
          onDelete={onDelete}
        />
      </Grid>
    </Grid>
  );
};

export default ChargeImported;

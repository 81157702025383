import React from 'react';
import { Formik } from "formik";
import * as Icons from "@material-ui/icons";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Button, Grid, TextField } from '@material-ui/core';
import { SelectApi } from '../../Components/SelectApi';
import { useGeneric } from '../../Services/options/useGeneric';
import { CustomDatePicker } from '../../Components/CustomDatePicker';
import { filterNumbers, formatDate, MaskCNPJ } from '../../Components/Helper/FormatValues';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
     width: "auto",
    },
    actionContent:{
      display: "flex", 
      justifyContent:"flex-start",
    //  [theme.breakpoints.up("lg")]: { justifyContent:"flex-end" },
    },
    button:{
      fontFamily: 'Montserrat',
      letterSpacing: 1.2,
      fontWeight: "bold",
      fontSize: 12,
      borderRadius: "1rem",
      [theme.breakpoints.up("xs")]: { padding: "0.4rem 1rem",  fontSize: 10 },
      [theme.breakpoints.up("xl")]: { padding: "0.5rem 2rem",  fontSize: 12 },
    },
  })
);

const DefaultValue = {
  createdAt: null,
  expected_date: null,
  liquidation_date: null,

  corporateName:  "",
  cnpj: "",
  document_id: "",

  typeId: null,
}

export const Filter =({setSearch}: any)=>{

  const submit = (values: any) => {
   const {createdAt, liquidation_date, expected_date, corporateName, cnpj, typeId, document_id} = values;
   const buffer = [];
   const cnpjStr = filterNumbers(cnpj);
                                 buffer.push({natureId: "2"});//pagamento
   if(createdAt !== null)        buffer.push({createdAt: formatDate(createdAt)});
   if(liquidation_date !== null) buffer.push({liquidation_date: formatDate(liquidation_date)});
   if(expected_date !== null)    buffer.push({expected_date: formatDate(expected_date)});
   if(corporateName  !== "")     buffer.push({corporateName});
   if(cnpjStr  !== "")           buffer.push({cnpj: cnpjStr});
   if(document_id  !== "")       buffer.push({document_id: document_id.replace(/\s+/g, '').split(";")});
   if(typeId !== null)           buffer.push({typeId: typeId.id});
 
   setSearch({data: buffer})
  }

return(
 <Formik
  initialValues={DefaultValue}
  onSubmit={submit}
 >
  {(props) => <Form {...props} />}
 </Formik>
)
}

const Form = (props: any) =>{
  const classes = useStyles();
  const { listByType } = useGeneric();
  const [listTypes, setListTypes] = React.useState<any>()
  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = props;

  const requestApi = React.useCallback(async()=>{
    if(listTypes) return listTypes;
    const response = await listByType("5");//5 anticipation
    const { data } = response.data;
    if(!data.hasError){
      const list = data.filter((value:any)=> {
       const id = Number(value.id);
       return( id === 6 || id === 7 )
      });
      setListTypes(list)
      return list
     }else{
      return []
     }
  },[listTypes])

 return(
  <form onSubmit={handleSubmit} noValidate>
   <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={3} className={classes.root}>
   <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
      <CustomDatePicker
       fullWidth
       variant="standard"
       inputVariant="standard"
       id="createdAt"
       name="createdAt"
       label="Data de solicitação"
       margin="none"
       onChange={(value: any) =>{
       setFieldValue("createdAt",  value || null)}}
       value={values.createdAt}
      />
    </Grid>

    <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
      <CustomDatePicker
       fullWidth
       variant="standard"
       inputVariant="standard"
       id="expected_date"
       name="expected_date"
       label="Data de vencimento"
       margin="none"
       onChange={(value: any) =>{
       setFieldValue("expected_date",  value || null)}}
       value={values.expected_date}
      />
    </Grid>

    <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
      <CustomDatePicker
       fullWidth
       variant="standard"
       inputVariant="standard"
       id="liquidation_date"
       name="liquidation_date"
       label="Data de recebimento"
       margin="none"
       onChange={(value: any) =>{
       setFieldValue("liquidation_date",  value || null)}}
       value={values.liquidation_date}
      />
    </Grid>

    <Grid item xs={12} sm={6} md={4} lg={3} xl={4}>
     <TextField
       fullWidth
       margin="none"
       size="small"
       id="corporateName"
       name="corporateName"
       label="Razão social"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.corporateName}
     />
    </Grid>

    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
     <TextField
       fullWidth
       margin="none"
       size="small"
       id="cnpj"
       name="cnpj"
       label="CNPJ"
       inputProps={{ autoComplete: "off" }}
       InputProps={{ inputComponent: MaskCNPJ }}
       onChange={handleChange}
       value={values.cnpj}
     />
    </Grid>

    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
     <TextField
       fullWidth
       margin="none"
       size="small"
       id="document_id"
       name="document_id"
       label="Número (Separe os números por ; )"
       inputProps={{ autoComplete: "off" }} 
       onChange={handleChange}
       value={values.document_id}
     />
    </Grid>

    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
     <SelectApi
      id="typeId"
      name="typeId"
      onChange={(event: any, value:any) => setFieldValue("typeId", value || null)}
      config={requestApi}
      getOptionSelected={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option: any) => option.name}
      value={values.typeId}
      textFieldProps={{
       fullWidth: true,
       size: "small",
       margin: "none",
       label: "Tipo",}}
     />
    </Grid>

    <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.actionContent}>
     <Button className={classes.button} 
      //disabled={!dirty}
      variant="contained" 
      color="primary"
      type="submit"> 
      Pesquisar
      <Icons.Search style={{marginLeft: "1.2rem"}}/>
     </Button>
    </Grid>
 
   </Grid>
  </form>
  )
 }
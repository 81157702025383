import React, { SetStateAction, Dispatch } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme, } from '@material-ui/core/styles';
import DefaultModal, { DefaultModalActions, DefaultModalContent } from '../../../../../Components/DefaultModal'
import { useSnackbar } from 'notistack';
import { InformationContext } from '../..';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    display: "flex",
    [theme.breakpoints.up("xs")]: {width: 350, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 380, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 400, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 400, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},  
  },
  buttonSave:{
    fontFamily: 'Montserrat',
    letterSpacing: 1.2,
    fontWeight: "bold",
    fontSize: 12,
    borderRadius: "1rem",
    padding: "0.5rem 1rem",
  },
})
);

export const DefaultValue = {
  bank_name: '',
  agency: '',
  account_number: '',
}

const validationSchema = Yup.object().shape({ 
  bank_name: Yup.string().required('Obrigatório'),
  agency: Yup.string().required('Obrigatório'),
  account_number: Yup.string().required('Obrigatório'),
});

interface ModalBankProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const ActionCreate: React.FC<ModalBankProps> = ({open, setOpen}) => {
  const { formik } = React.useContext(InformationContext);
  const { setFieldValue } = formik;
  const { enqueueSnackbar } = useSnackbar();
  const submit = async (values: any, { setSubmitting }: any) => {
    const data = {
      id: Date.now(),
      ...values
    }
    //console.log([...formik.values.legalPerson, data])
    setFieldValue("bank", [...formik.values.bank, data])
    enqueueSnackbar("Banco adicionado", { variant: "success" });
    handleClose()
  }

  const handleClose=()=>{
    setOpen(false)
  }

  return (
   <DefaultModal open={open} handleClose={handleClose} title="Novo banco">
    <Formik
      initialValues={DefaultValue}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {(props) => <Form {...props} />}
    </Formik>
   </DefaultModal>
  )
}

export default ActionCreate

const Form = (props: any) => {
  const classes = useStyles();
  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    handleChange,
    handleSubmit,
  } = props;
 
  return (
    <form onSubmit={handleSubmit} noValidate>
     <DefaultModalContent dividers>
      <Grid container className={classes.root} spacing={3}> 
       <Grid item xs={12}>
       {/* <SelectApi
         id="bank_name"
         name="bank_name"
         onChange={(event: any, value:any) => setFieldValue("bank_name", value || null)}
         config={requestApi}
         getOptionSelected={(option: any, value: any) => option === value}
         getOptionLabel={(option: any) => option}
         value={values.bank_name}
         textFieldProps={{
          fullWidth: true,
          size: "small",
          multiline: true,
          margin: "normal",
          label: "Banco",}}
          error={touched.bank_name && Boolean(errors.bank_name)}
          helperText={touched.bank_name && errors.bank_name}
       /> */}
        <TextField
         fullWidth
         autoFocus
         multiline
         maxRows={4}
         margin="none"
         id="bank_name"
         name="bank_name"
         label="Banco"
         inputProps={{ autoComplete: "off" }}
         onChange={handleChange}
         value={values.bank_name}
         error={touched.bank_name && Boolean(errors.bank_name)}
         helperText={touched.bank_name && errors.bank_name}
         />  
       </Grid>
   
       <Grid item xs={12}>
        <TextField
         fullWidth
         margin="none"
         id="agency"
         name="agency"
         label="Agência"
         inputProps={{ autoComplete: "off" }}
         onChange={handleChange}
         value={values.agency}
         error={touched.agency && Boolean(errors.agency)}
         helperText={touched.agency && errors.agency}
         />  
       </Grid>
   
       <Grid item xs={12}>
        <TextField
         fullWidth
         margin="none"
         id="account_number"
         name="account_number"
         label="Conta"
         inputProps={{ autoComplete: "off" }}
         onChange={handleChange}
         value={values.account_number}
         error={touched.account_number && Boolean(errors.account_number)}
         helperText={touched.account_number && errors.account_number}
         />
       </Grid>
   
       </Grid>
      </DefaultModalContent>
      <DefaultModalActions style={{marginRight: "0.5rem"}}>
       <Button
       className={classes.buttonSave}
       variant="contained"
       color="secondary"
       type="submit"
       disabled={!dirty}>
       Confirmar
       </Button>
     </DefaultModalActions>
     </form>
    );
   };
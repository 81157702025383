import React from 'react'
import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { NumberFormatPercentage } from "../../../../../Components/Helper/FormatValues";
import { InformationContext } from '../..'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      padding: "2rem 1rem",
      flexGrow: 1,
      height: "auto",
      minHeight: "330px",
      justifyContent: "flex-start",
      border: `1px solid  ${theme.palette.divider}`,
      borderRadius: "1rem",
      boxShadow: "1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    },
    title: {
      fontWeight: "bolder",
      fontSize: 20,
      color: theme.palette.secondary.main,
    },
    titleRadio:{
      padding: "0.2rem 0rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
    radio:{
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
    checkbox:{
      fontWeight: "bold",
      color: theme.palette.primary.main,
    }
  })
);

const DefinedByTotal = () => {
  const classes = useStyles();
  const { formik } = React.useContext(InformationContext);
  const {
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
  } = formik;

  const checkChargeImported = Boolean(values?.discountParams?.chargeImported?.interval?.length > 0)
  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className={classes.main}>
      <Grid item xs={12}>
       <Typography gutterBottom className={classes.title}>Definido por total</Typography>
      </Grid>
      <Grid item xs={5} sm={6} md={3} lg={5} xl={5} style={{display: "grid", gap: "1rem"}}>
      <Grid item xs={12}>
        <FormControlLabel
           label="Faturas vencidas"
           className={classes.checkbox}
           control={
            <Checkbox
            name="discountParams.definedByTotal.overdueInvoice" 
            id="discountParams.definedByTotal.overdueInvoice"
            onChange={handleChange} 
            checked={values.discountParams?.definedByTotal?.overdueInvoice || false}
           />}
        />
      </Grid>
      <Grid item xs={12}>
       <FormControl component="fieldset">
        <FormLabel component="legend" className={classes.titleRadio}>Verificar por:</FormLabel>
         <RadioGroup 
          aria-label="discountParams.definedByTotal.monthOrDay" 
          id="discountParams.definedByTotal.monthOrDay" 
          name="discountParams.definedByTotal.monthOrDay" 
          value={values.discountParams?.definedByTotal?.monthOrDay} 
          onChange={(e: any, value: string)=>{
            setFieldValue("discountParams.definedByTotal.monthOrDay",  value)
            if(value === "day"){
              setFieldValue("discountParams.definedByTotal.monthParams.fixedDay",  null)
              setFieldValue("discountParams.definedByTotal.monthParams.nextMonth",  null)
            }else{
              setFieldValue("discountParams.definedByTotal.dayParams.businessDay",  null)
              setFieldValue("discountParams.definedByTotal.dayParams.freeDay",  null)
            }
          }}>
          <Box style={{display:"grid", paddingTop: "0.5rem"}}>
           <FormControlLabel value="day"   className={classes.radio} control={<Radio />} label="Dia" />
           <FormControlLabel value="month" className={classes.radio} control={<Radio />} label="Mês" />
          </Box>
         </RadioGroup>
       </FormControl>
      </Grid>
     </Grid>

     <Grid item xs={7} style={{display: "grid", gap: "1rem"}}>
      <Grid item xs={12}>
         <TextField
           disabled={checkChargeImported}
           margin="none"
           size="small"
           id="discountParams.definedByTotal.fixedCharge"
           name="discountParams.definedByTotal.fixedCharge"
           label="Taxa fixa"
           inputProps={{ autoComplete: "off" }}
           InputProps={{
             inputComponent: NumberFormatPercentage,
           }}
           onChange={handleChange}
           value={values.discountParams?.definedByTotal?.fixedCharge}
           error={touched.discountParams?.definedByTotal?.fixedCharge && Boolean(errors.discountParams?.definedByTotal?.fixedCharge)}
           helperText={touched.discountParams?.definedByTotal?.fixedCharge && errors.discountParams?.definedByTotal?.fixedCharge}
         />
       </Grid>

     {values.discountParams?.definedByTotal?.monthOrDay !== "month" &&
      <Grid item xs={12}>
       <TextField
        margin="none"
        size="small"
        id="discountParams.definedByTotal.dayParams.businessDay"
        name="discountParams.definedByTotal.dayParams.businessDay"
        label="Dias úteis"
        inputProps={{ autoComplete: "off" }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>)=>
         setFieldValue("discountParams.definedByTotal.dayParams.businessDay", Number(event.target.value) ?? null)}
        value={values.discountParams?.definedByTotal?.dayParams?.businessDay}
        error={(touched.discountParams?.definedByTotal?.dayParams?.businessDay || touched.discountParams?.definedByTotal?.dayParams) && Boolean(errors.discountParams?.definedByTotal?.dayParams?.businessDay)}
        helperText={(touched.discountParams?.definedByTotal?.dayParams?.businessDay || touched.discountParams?.definedByTotal?.dayParams) && errors.discountParams?.definedByTotal?.dayParams?.businessDay }
       />
      </Grid>}

     {values.discountParams?.definedByTotal?.monthOrDay !== "month" &&
      <Grid item xs={12}>
       <TextField
        margin="none"
        size="small"
        id="discountParams.definedByTotal.dayParams.freeDay"
        name="discountParams.definedByTotal.dayParams.freeDay"
        label="Dias livres"
        inputProps={{ autoComplete: "off" }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>)=>
         setFieldValue("discountParams.definedByTotal.dayParams.freeDay", Number(event.target.value) ?? null)}
        value={values.discountParams?.definedByTotal?.dayParams?.freeDay}
        error={(touched.discountParams?.definedByTotal?.dayParams?.freeDay || touched.discountParams?.definedByTotal?.dayParams) && Boolean(errors.discountParams?.definedByTotal?.dayParams?.freeDay)}
        helperText={(touched.discountParams?.definedByTotal?.dayParams?.freeDay || touched.discountParams?.definedByTotal?.dayParams) && errors.discountParams?.definedByTotal?.dayParams?.freeDay}
       />
      </Grid>}

      {values.discountParams?.definedByTotal?.monthOrDay !== "day" &&
      <Grid item xs={12}>
       <TextField
        margin="none"
        size="small"
        id="discountParams.definedByTotal.monthParams.fixedDay"
        name="discountParams.definedByTotal.monthParams.fixedDay"
        label="Dia fixo"
        inputProps={{ autoComplete: "off" }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>)=>
         setFieldValue("discountParams.definedByTotal.monthParams.fixedDay", Number(event.target.value) ?? null)}
        value={values.discountParams?.definedByTotal?.monthParams?.fixedDay}
        error={(touched.discountParams?.definedByTotal?.monthParams?.fixedDay || touched.discountParams?.definedByTotal?.monthParams) && Boolean(errors.discountParams?.definedByTotal?.monthParams?.fixedDay)}
        helperText={(touched.discountParams?.definedByTotal?.monthParams?.fixedDay || touched.discountParams?.definedByTotal?.monthParams) && errors.discountParams?.definedByTotal?.monthParams?.fixedDay}
       />
      
      </Grid>}
      {values.discountParams?.definedByTotal?.monthOrDay !== "day" &&
       <Grid item xs={12}>
       <TextField
        margin="none"
        size="small"
        id="discountParams.definedByTotal.monthParams.nextMonth"
        name="discountParams.definedByTotal.monthParams.nextMonth"
        label="Próximo mês"
        inputProps={{ autoComplete: "off" }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>)=>
         setFieldValue("discountParams.definedByTotal.monthParams.nextMonth", Number(event.target.value) ?? null)}
        value={values.discountParams?.definedByTotal?.monthParams?.nextMonth}
        error={(touched.discountParams?.definedByTotal.monthParams?.nextMonth || touched.discountParams?.definedByTotal?.monthParams) && Boolean(errors.discountParams?.definedByTotal?.monthParams?.nextMonth)}
        helperText={(touched.discountParams?.definedByTotal?.monthParams?.nextMonth || touched.discountParams?.definedByTotal?.monthParams) && errors.discountParams?.definedByTotal?.monthParams?.nextMonth}
       />
      </Grid>}

      </Grid>
      
     </Grid>
 );
};

export default DefinedByTotal
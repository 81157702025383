import React from "react";
import * as Icons from "@material-ui/icons";
import { Box, Button, Grid, IconButton, Tooltip } from "@material-ui/core";

import { showDate, FormatRealNumber, FormatCNPJ } from "../../Components/Helper/FormatValues";
import { ShowStatusAnticipation } from "../../Components/ShowInfos";
import CustomTable from "../../Components/Table";
import ModalAccept from "./Description/TabInformation/Modal/Accept";
import ModalAcceptCheckeds from "./Description/TabInformation/Modal/AcceptCheckeds";
import { jsonToExcel } from "../../Components/Helper/SaveFileExcel";
import moment from "moment";
import ModalEditCheckeds from "./Description/TabInformation/Modal/EditCheckeds";

interface PropsTable {
  data: any;
  load: boolean;
  error: any;
  updateData: any;
}

export default function ReadTable({
  data,
  error,
  load,
  updateData,
}: PropsTable) {
  const [openAccept, setOpenAccept] = React.useState({ id: "",financial_operator: '', open: false });
  const options = {
     selectableRows: "multiple",
     selectableRowsHideCheckboxes: false,
     selectableRowsHeader: true,
     selectToolbarPlacement: 'replace',
     elevation: 0,
    customToolbarSelect: (
      selectedRows: any,
      displayData: any,
      setSelectedRows: any
    ) => (
      <CustomToolbarSelect
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
        updateData={updateData}
      />
    ),
  };

  const handleOpen = (id: string, financial_operator: string, status: boolean) => {
    if(status) return
    setOpenAccept({ id, financial_operator, open: true });
  };

  const handleClose = () => {
    setOpenAccept({ id: "", financial_operator: "", open: false });
  };

  const actions = ({ tableMeta }: any) => {
    const rowId = tableMeta.rowData[1];
    const financial_operator = tableMeta.rowData[2]; 
    const rowStatus = tableMeta.rowData[9].id !== "1";
    return (
      <Grid
        container
        spacing={1}
        direction="row"
        wrap="nowrap"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Tooltip placement="top" title={"Aprovar"} aria-label="view">
            <span>
              <IconButton
                color="secondary"
                aria-label="open"
                style={rowStatus ? { padding: 0 }: {padding: 0, color: "#B6B6B6"}}
                //disabled={rowStatus}
                onClick={() => handleOpen(rowId, financial_operator, rowStatus)}
              >
                <Icons.CheckCircle />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Tooltip placement="top" title="Visualizar" aria-label="view">
            <IconButton
              color="primary"
              aria-label="open"
              style={{ padding: "0rem 0.5rem " }}
              onClick={() =>
                window.open(`/anticipation/description/${rowId}`, "_blank")
              }
            >
              <Icons.Description />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const columns = [
    {
      name: "action",
      label: "Ação",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
          actions({ tableMeta }),
      },
    },
    { name: "id", label: "Id", options: { display: "false" } },
    { name: "financial_operator", label: "Operador financeiro", options: {
      filter: true,
      sort: true,
      customBodyRender: (value: any) => FormatCNPJ(value),
     },
    },
    {
      name: "createdAt",
      label: "Data de criação",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any) => showDate(value, "-"),
      },
    },
    {
      name: "anticipation_date",
      label: "Data de antecipação",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any) => showDate(value, "-"),
      },
    },

    { name: "number", label: "Número", options: { filter: true, sort: true } },

    {
      name: "value",
      label: "Valor",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any) => FormatRealNumber(value),
      },
    },

    { name: "issuer", label: "Emissor", options: { filter: true, sort: true } },

    {
      name: "buyer",
      label: "Destinatário",
      options: { filter: true, sort: true },
    },

    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any) => (
          <ShowStatusAnticipation value={value} />
        ),
      },
    },
  ];

  return (
    <>
      {openAccept.open && (
        <ModalAccept
          open={openAccept.open}
          close={handleClose}
          financial_operator={openAccept.financial_operator}
          id={openAccept.id}
          updateData={updateData}
        />
      )}
      <CustomTable
        data={data}
        columns={columns}
        opt={options}
        error={error}
        load={load}
        elevation={0}
      />
    </>
  );
}

const CustomToolbarSelect = ({
  selectedRows,
  displayData,
  updateData,
}: any) => {
  const [modalAccept, setModalAccept] = React.useState({
    open: false,
    type: null,
    ids: [],
  });

  const handleAcceptCheckeds = (type: any) => {
    const aux = selectedRows.data.map(
      ({ index }: any) => ({ id: displayData[index].data[1], 
                             situation: displayData[index].data[9]?.props?.value?.id,
                             financialOperator: displayData[index].data[2],
                             number: displayData[index].data[5],
                             issuer: displayData[index].data[7],
                            }
                             ));
    setModalAccept({ open: true, type, ids: aux });
  };

  const handleCloseModalCheckeds = () => {
    setModalAccept({ open: false, type: null, ids: [] });
  };

  const handleFormat = () => {
    const aux = selectedRows.data.map(({ index }: any) => ({
      id: displayData[index].data[1],
      "Operador financeiro": displayData[index].data[2],
      "Data de criação": displayData[index].data[3],
      "Data de antecipação": displayData[index].data[4],
      Número: displayData[index].data[5],
      Valor: displayData[index].data[6],
      Emissor: displayData[index].data[7],
      Destinatário: displayData[index].data[8],
      Situação: displayData[index].data[9]?.props?.value?.name,
    }));
     jsonToExcel(aux, `Antecipações - ${moment().format('DD-MM-YYYY HH-mm-ss')}`);
  };

  const getSelectContent = React.useCallback(
    (option: any) => {
      switch (option) {
        case 0:
          return (
            <ModalAcceptCheckeds
            open={modalAccept.open}
            close={handleCloseModalCheckeds}
            selects={modalAccept.ids}
            updateData={updateData}
          />
          );
        case 1:
          return (
            <ModalEditCheckeds
              open={modalAccept.open}
              close={handleCloseModalCheckeds}
              selects={modalAccept.ids}
              updateData={updateData}
            />
          );
        default:
          return null;
      }
    },
    [modalAccept]
  );

  return (
    <Box 
      style={{
        gap: '2rem',
        display: 'flex',
        padding: "0rem 1rem",
        justifyContent: "start",
        width: "88%", 
      }}
    >
      {getSelectContent(modalAccept.type)}
      <Box>
        <Tooltip placement="top" title="Aprovar" aria-label="view">
          <span>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              aria-label="open"
              style={{ padding: "0.5rem 1rem", borderRadius: "1rem" }}
              startIcon={<Icons.CheckCircle />}
              onClick={()=>handleAcceptCheckeds(0)}
            >
              Aprovar
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip placement="top" title="Editar" aria-label="view">
          <span>
            <Button
              size="small"
              variant="contained"
              color="primary"
              aria-label="open"
              style={{ padding: "0.5rem 1rem", borderRadius: "1rem" }}
              startIcon={<Icons.Edit />}
              onClick={()=>handleAcceptCheckeds(1)}
            >
              Editar
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip placement="top" title="Visualizar" aria-label="view">
        <span>
            <Button
              size="small"
              variant="contained"
              color="primary"
              aria-label="open"
              style={{ padding: "0.5rem 1rem", borderRadius: "1rem" }}
              startIcon={<Icons.Save  />}
              onClick={handleFormat}
            >
              Baixar
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};

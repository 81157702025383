import React from 'react';
import { Formik } from "formik";
import * as Icons from "@material-ui/icons";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Button, Grid, TextField } from '@material-ui/core';
import { filterNumbers, MaskCNPJ } from '../../Components/Helper/FormatValues';
import { SelectApi } from '../../Components/SelectApi';
import { useGeneric } from '../../Services/options/useGeneric';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
     width: "100%",
    },
    actionContent:{
      display: "flex", 
      justifyContent:"flex-start",
      [theme.breakpoints.up("md")]: { justifyContent:"flex-end"},
      [theme.breakpoints.up("xl")]: { justifyContent:"flex-start"},
    },
    button:{
      fontFamily: 'Montserrat',
      letterSpacing: 1.2,
      fontWeight: "bold",
      fontSize: 12,
      borderRadius: "1rem",
      [theme.breakpoints.up("xs")]: { padding: "0.4rem 1rem",  fontSize: 10 },
      [theme.breakpoints.up("xl")]: { padding: "0.5rem 2rem",  fontSize: 12 },
    },
  })
);

const DefaultValue = {
  corporate_name: "",
  cnpj: "",
  status: null,
}

export const Filter =({setSearch}: any)=>{

 const submit = async (values: any) => {
  const buffer = [];
  const cnpj = filterNumbers(values.cnpj);
  if(values.corporate_name !== "") buffer.push({corporate_name: values.corporate_name})
  if(cnpj !== "")                  buffer.push({cnpj})
  if(values.status !== null)       buffer.push({status: values.status.id}); 
  setSearch({data: buffer})
 }

return(
 <Formik
  initialValues={DefaultValue}
  onSubmit={submit}
 >
  {(props) => <Form {...props} />}
 </Formik>
)
}

const Form = (props: any) =>{
  const classes = useStyles();
  const { listByType } = useGeneric();
  const [listTypes, setListTypes] = React.useState<any>()
  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = props;

  const requestApi = React.useCallback(async()=>{
    if(listTypes) return listTypes;
    const response = await listByType("1");// 1 company
    const { data } = response.data;
    if(!data.hasError){
      const list = data.filter((value:any)=> Number(value.id) <= 3 || Number(value.id) === 7);
      setListTypes(list)
      return list
     }else{
      return []
     }
  },[listTypes])

 return(
  <form onSubmit={handleSubmit} noValidate>
   <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={2} className={classes.root}>
    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
     <TextField
       fullWidth
       margin="none"
       size="small"
       id="corporate_name"
       name="corporate_name"
       label="Razão Social"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.corporate_name}
     />
    </Grid>
    <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
     <TextField
       fullWidth
       margin="none"
       size="small"
       id="cnpj"
       name="cnpj"
       label="CNPJ"
       inputProps={{ autoComplete: "off" }}
       InputProps={{ inputComponent: MaskCNPJ }}
       onChange={handleChange}
       value={values.cnpj}
     />
    </Grid>

    <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
     <SelectApi
      id="status"
      name="status"
      onChange={(event: any, value:any) => setFieldValue("status", value || null)}
      config={requestApi}
      getOptionSelected={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option: any) => option.name}
      value={values.status}
      textFieldProps={{
       fullWidth: true,
       size: "small",
       margin: "none",
       label: "Status",}}
     />
    </Grid>

    <Grid item xs={12} sm={6} md={3} lg={3} xl={4} className={classes.actionContent}>
     <Button className={classes.button} 
      //disabled={!dirty}
      variant="contained" 
      color="primary"
      type="submit"> 
      Pesquisar
      <Icons.Search style={{marginLeft: "1.2rem"}}/>
     </Button>
    </Grid>
 
   </Grid>
  </form>
 )
}
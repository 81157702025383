import React from 'react'
import * as Icons from "@material-ui/icons";
import { Grid } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DefaultModal, { DefaultModalContent } from '../../../../../Components/DefaultModal';
import ActionDelete from './ActionDelete';
import ActionEdit from './ActionEdit';
import { InformationContext } from '../..'; 
import { ShowDetailList } from '../../../../../Components/ShowDetailList';

import { Bank as InterfaceBank } from 'kikai-model/src/interface/company/Bank';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root:{
    margin: 0,
    padding: 0,
    minHeight: "15rem",
  },
  main: {
    padding: "0rem 0.5rem 1rem 0.5rem",
    margin:  "0px",
    [theme.breakpoints.up("xs")]: { minWidth: 380 },
    [theme.breakpoints.up("sm")]: { minWidth: 380 },
    [theme.breakpoints.up("md")]: { minWidth: 400 },  
    [theme.breakpoints.up("lg")]: { minWidth: 400 },
    [theme.breakpoints.up("xl")]: { minWidth: 400 },
  },
  speedDial:{
    transform: 'scale(0.825)',
  },
  listTextItem:{
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.primary.main,
    [theme.breakpoints.up("xl")]: { fontSize: 18 },
  },
  listTextSubItem:{
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    margin:"0px 0px 0px 10px",
    [theme.breakpoints.up("xl")]: { fontSize: 16 },
  },
})
);

export const ActionShow = ({select, open, close}: any) => {
  const classes = useStyles();
  const [openDial, setOpenDial] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [options, setOptions] = React.useState(null);
  const { formik } = React.useContext(InformationContext);
  const selectData: InterfaceBank = formik.values.bank[select];

  const listInfo = [
    {
      icon: <Icons.AccountBalance />,
      text: "Banco: ",
      data: selectData?.bank_name,
    },
    {
      icon: <Icons.AccountBalanceWallet />,
      text: "Agência: ",
      data: selectData?.agency,
    },
    {
      icon: <Icons.Payment />,
      text: "Conta: ",
      data: selectData?.account_number,
    },
  ]

  const actions = [
    { icon: <Icons.Edit />, 
      name: 'Atualizar',
      option: 0, 
      disabled: false},  
    { icon: <Icons.Cancel/>, 
      name: 'Apagar',
      option: 1,  
      disabled: false},     
    ]

  const handlActionsOpen = (e:any) => {
    if(e.type === "mouseenter" || e.type === "click")
    setOpenDial(true);
  };

  const handlActionsClose = (e:any) => {//mouseleave
    if(e.type === "mouseleave")
    setOpenDial(false);
  };

  const handleOpenView =(id: any)=>{
    setOptions(id)
    setOpenView(true)
  }

  const handleCloseView =()=>{
    setOpenView(false)
    setOptions(null)
  }

  const handleFullClose=()=>{
    handleCloseView()
    close()
  }

  const getSelectContent = React.useCallback((select: any) => { 
    switch (select) {
     case 0: return <ActionEdit   select={selectData} open={openView} close={handleCloseView}/>
     case 1: return <ActionDelete select={selectData} open={openView} close={handleCloseView} handleFullClose={handleFullClose}/>
     default: return null;
    }
  }, [options]);
  return (
    <>
     {getSelectContent(options)}
     <DefaultModal open={open} handleClose={close} title={"Banco"} maxWidth="sm">
      <DefaultModalContent dividers className={classes.root}>
       <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start"className={classes.main}>
        <Grid item xs={12}>
          <SpeedDial
            ariaLabel="SpeedDialClick"
            className={classes.speedDial}
            icon={ <Icons.Settings  />  }
            direction="right"
            FabProps={{size:"small" }}
            onClose={handlActionsClose}
            onOpen={handlActionsOpen}
            open={openDial} 
            >
            {actions.map((action: any) => (
             <SpeedDialAction
              tooltipPlacement="top"
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              hidden={action.disabled}
              onClick={()=>handleOpenView(action.option)}
              />
            ))}
           </SpeedDial>  
         </Grid>
    
         <Grid item xs={12}>
          <ShowDetailList list={listInfo}/>
         </Grid> 
          
         </Grid>  
         </DefaultModalContent>
        </DefaultModal>
      </>
      )
    }

export default ActionShow
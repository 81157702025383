import React from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { NumberFormatPercentage } from "../../../../../../Components/Helper/FormatValues";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      padding: "2rem 1rem",
      flexGrow: 1,
      height: "auto",
      minHeight: "330px",
      justifyContent: "flex-start",
      border: `1px solid  ${theme.palette.divider}`,
      borderRadius: "1rem",
      boxShadow: "1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    },
    title: {
      fontWeight: "bolder",
      fontSize: 20,
      color: theme.palette.secondary.main,
    },
    titleRadio:{
      padding: "0.2rem 0rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
    radio:{
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
    checkbox:{
      fontWeight: "bold",
      color: theme.palette.primary.main,
    }
  })
);

const FormPartner = ({formik}: any) => {
  const classes = useStyles();
  const {
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
  } = formik;

  const checkChargeImported = Boolean(values?.chargeImported?.interval?.length > 0)
  return (
     <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className={classes.main}>
      <Grid item xs={12}>
       <Typography gutterBottom className={classes.title}>Definido por total</Typography>
      </Grid>
      <Grid item xs={5} sm={6} md={3} lg={5} xl={5} style={{display: "grid", gap: "1rem"}}>
       <Grid item xs={12}>
         <FormControlLabel
            label="Faturas vencidas"
            className={classes.checkbox}
            control={
             <Checkbox
             name="definedByTotal.overdueInvoice" 
             id="definedByTotal.overdueInvoice"
             onChange={handleChange} 
             checked={values.definedByTotal?.overdueInvoice || false}
            />}
         />
       </Grid>
       <Grid item xs={12}>
        <FormControl component="fieldset">
         <FormLabel component="legend" className={classes.titleRadio}>Verificar por:</FormLabel>
          <RadioGroup 
           aria-label="definedByTotal.monthOrDay" 
           id="definedByTotal.monthOrDay" 
           name="definedByTotal.monthOrDay" 
           value={values.definedByTotal.monthOrDay} 
           onChange={handleChange}>
           <Box style={{display:"grid", paddingTop: "0.5rem"}}>
            <FormControlLabel value="day"   className={classes.radio} control={<Radio />} label="Dia" />
            <FormControlLabel value="month" className={classes.radio} control={<Radio />} label="Mês" />
           </Box>
          </RadioGroup>
        </FormControl>
       </Grid>
      </Grid>

      <Grid item xs={7} style={{display: "grid", gap: "1rem"}}>
       <Grid item xs={12}>
          <TextField
            disabled={checkChargeImported}
            margin="none"
            size="small"
            id="definedByTotal.fixedCharge"
            name="definedByTotal.fixedCharge"
            label="Taxa fixa"
            inputProps={{ autoComplete: "off" }}
            InputProps={{
              inputComponent: NumberFormatPercentage,
            }}
            onChange={handleChange}
            value={values.definedByTotal?.fixedCharge}
            error={touched.definedByTotal?.fixedCharge && Boolean(errors.definedByTotal?.fixedCharge)}
            helperText={touched.definedByTotal?.fixedCharge && errors.definedByTotal?.fixedCharge}
          />
        </Grid>

      {values.definedByTotal.monthOrDay !== "month" &&
       <Grid item xs={12}>
        <TextField
         margin="none"
         size="small"
         id="definedByTotal.dayParams.businessDay"
         name="definedByTotal.dayParams.businessDay"
         label="Dias úteis"
         inputProps={{ autoComplete: "off" }}
         onChange={(event: React.ChangeEvent<HTMLInputElement>)=>
          setFieldValue("definedByTotal.dayParams.businessDay", Number(event.target.value))}
         value={values.definedByTotal?.dayParams?.businessDay}
         error={(touched.definedByTotal?.dayParams?.businessDay || touched.definedByTotal?.dayParams) && Boolean(errors.definedByTotal?.dayParams?.businessDay)}
         helperText={(touched.definedByTotal?.dayParams?.businessDay || touched.definedByTotal?.dayParams) && errors.definedByTotal?.dayParams?.businessDay }
        />
       </Grid>}

      {values.definedByTotal.monthOrDay !== "month" &&
       <Grid item xs={12}>
        <TextField
         margin="none"
         size="small"
         id="definedByTotal.dayParams.freeDay"
         name="definedByTotal.dayParams.freeDay"
         label="Dias livres"
         inputProps={{ autoComplete: "off" }}
         onChange={(event: React.ChangeEvent<HTMLInputElement>)=>
          setFieldValue("definedByTotal.dayParams.freeDay", Number(event.target.value))}
         value={values.definedByTotal?.dayParams?.freeDay}
         error={(touched.definedByTotal?.dayParams?.freeDay || touched.definedByTotal?.dayParams) && Boolean(errors.definedByTotal?.dayParams?.freeDay)}
         helperText={(touched.definedByTotal?.dayParams?.freeDay || touched.definedByTotal?.dayParams) && errors.definedByTotal?.dayParams?.freeDay}
        />
       </Grid>}
 
       {values.definedByTotal.monthOrDay !== "day" &&
       <Grid item xs={12}>
        <TextField
         margin="none"
         size="small"
         id="definedByTotal.monthParams.fixedDay"
         name="definedByTotal.monthParams.fixedDay"
         label="Dia fixo"
         inputProps={{ autoComplete: "off" }}
         onChange={(event: React.ChangeEvent<HTMLInputElement>)=>
          setFieldValue("definedByTotal.monthParams.fixedDay", Number(event.target.value))}
         value={values.definedByTotal?.monthParams?.fixedDay}
         error={(touched.definedByTotal?.monthParams?.fixedDay || touched.definedByTotal?.monthParams) && Boolean(errors.definedByTotal?.monthParams?.fixedDay)}
         helperText={(touched.definedByTotal?.monthParams?.fixedDay || touched.definedByTotal?.monthParams) && errors.definedByTotal?.monthParams?.fixedDay}
        />
       
       </Grid>}

      
       {values.definedByTotal.monthOrDay !== "day" &&
        <Grid item xs={12}>
        <TextField
         margin="none"
         size="small"
         id="definedByTotal.monthParams.nextMonth"
         name="definedByTotal.monthParams.nextMonth"
         label="Próximo mês"
         inputProps={{ autoComplete: "off" }}
         onChange={(event: React.ChangeEvent<HTMLInputElement>)=>
          setFieldValue("definedByTotal.monthParams.nextMonth", Number(event.target.value) ?? null)}
         value={values.definedByTotal?.monthParams?.nextMonth}
         error={(touched.definedByTotal?.monthParams?.nextMonth || touched.definedByTotal?.monthParams) && Boolean(errors.definedByTotal?.monthParams?.nextMonth)}
         helperText={(touched.definedByTotal?.monthParams?.nextMonth || touched.definedByTotal?.monthParams) && errors.definedByTotal?.monthParams?.nextMonth}
        />
       </Grid>}

       </Grid>
       
      </Grid>
  );
};

export default FormPartner;

import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as Icons from '@material-ui/icons';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DefaultModal, {
  DefaultModalActions,
  DefaultModalContent,
} from '../../../../../Components/DefaultModal';
import {
  Box,
  Button,
  CircularProgress,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useAnticipation } from '../../../../../Services/options/useAnticipation';
import { jsonToExcel } from '../../../../../Components/Helper/SaveFileExcel';
import moment from 'moment';
import {
  FormatCNPJ,
  FormatRealNumber,
  showDate,
} from '../../../../../Components/Helper/FormatValues';
import { UserContext } from '../../../../../Context/UserContext';
import { ShowTwoDetailList } from '../../../../../Components/ShowDetailList';
import { SelectCustom } from '../../../../../Components/SelectsCustom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 400,
      minHeight: 150,
      [theme.breakpoints.up('xs')]: { maxWidth: 480 },
      [theme.breakpoints.up('sm')]: { maxWidth: 550 },
      [theme.breakpoints.up('md')]: { maxWidth: 600 },
      [theme.breakpoints.up('lg')]: { maxWidth: 600 },
      [theme.breakpoints.up('xl')]: { maxWidth: 650 },
    },
    contentText: {
      maxWidth: 500,
      padding: '2rem',
      [theme.breakpoints.up('lg')]: { padding: '3rem' },
    },
    notice: {
      fontSize: 16,
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      [theme.breakpoints.up('lg')]: { fontSize: 18 },
    },
    load: {
      maxWidth: 10,
      maxHeight: 10,
    },
    button: {
      fontFamily: 'Montserrat',
      letterSpacing: 1.2,
      fontWeight: 'bold',
      fontSize: 12,
      borderRadius: '1rem',
      padding: '0.4rem 1rem',
    },
    containerList: {
      padding: '1rem',
      maxHeight: '400px',
      overflowY: 'auto',
    },
  })
);

const initialValues = {
  financial_operator: null,
};

const validationSchema = Yup.object({
 // financial_operator: Yup.object().required('Obrigatório').nullable(),
});

const ModalAcceptCheckeds = ({ open, close, selects, updateData }: any) => {
  const classes = useStyles();
  const { userInfo } = React.useContext(UserContext);
  const { approve, loading } = useAnticipation();
  const [listApprove, setListApprove] = React.useState<any>([]); 
  const { enqueueSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = React.useState<any>(false)
  const listNotApprove = selects.filter((value: any)=> !value.financialOperator || value.situation !== '1' )
  
  const handleSave = () => {
    jsonToExcel(
      listApprove,
      `Antecipações aprovadas - ${moment().format('DD-MM-YYYY HH-mm-ss')}`
    );
  };

  const handleClose = () => {
    if (listApprove?.length > 0) updateData();
    close();
  };

  const listItens = listApprove.map((value: any) => ({
    icon: <Icons.Domain />,
    primary:{
      text: "Emissor: ",
      data: value.Emissor,
    },
    secondary:{
      text: "Número: ",
      data: value["Número"],
    }
  }));

  const listItensNotApprove = listNotApprove.map((value: any) => ({
    icon: <Icons.Domain />,
    primary:{
      text: "Emissor: ",
      data: value.issuer,
    },
    secondary:{
      text: "Número: ",
      data: value.number,
    }
  }));
 
  const submit = async (values: any) => {
    setSubmitting(true)
    const proms = selects
      .filter((item: any) => item.situation === '1' && (item?.financialOperator && item.financialOperator !== ''))
      .map((item: any) =>
        approve({
          _id: item.id,
          //financial_operator: values?.financial_operator?.id,
          updatedBy: { email: userInfo().email },
        })
      );
    await Promise.all(proms)
      .then((response: any) => {
        const data = response.map((value: any) => {
          const { data } = value;
          if (!data.hasError) {
            return {
              id: data.data._id,
              "Operador financeiro": FormatCNPJ(data.data?.financial_operator),
              'Data de criação': showDate(data.data.createdAt),
              'Data de antecipação': showDate(data.data.anticipation_date),
              Número: data.data.document_id,
              Valor: FormatRealNumber(data.data.total),
              Emissor: data.data.issuer.corporate_name,
              Destinatário: data.data.buyer.corporate_name,
              situação: data.data.situation.name,
            };
          }
        });
        setListApprove(data);
        if (data?.length > 0) {
          enqueueSnackbar('Antecipações aprovadas', { variant: 'success' });
        } else {
          enqueueSnackbar('Nenhuma antecipação foi aprovada', {
            variant: 'info',
          });
        }
      })
      .catch((error) => {
        console.log('error ', error);
      });
  };

  const {handleSubmit, isSubmitting, } =
    useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: submit,
    });

  return (
    <DefaultModal
      open={open}
      handleClose={handleClose}
      title="Aprovar antecipações"
    >
      <form noValidate onSubmit={handleSubmit}>
        <DefaultModalContent dividers className={classes.root}>
          {listItens?.length === 0 && !submitting ? (
            <DialogContentText className={classes.contentText}>
              <Typography
                component="span"
                className={classes.notice}
                gutterBottom
              >
                Deseja realmente aprovar as antecipações selecionadas?
              </Typography>
            </DialogContentText>
          ) : (
            <>
            {listItens?.length > 0 &&
              <Box className={classes.containerList}>
              <ShowTwoDetailList title="Antecipações aprovadas" list={listItens} />
            </Box>
            }
            </>
          )}

          {submitting && listItensNotApprove?.length > 0 &&
            <Box className={classes.containerList}>
              <ShowTwoDetailList title="Antecipações sem Operador financeiro" list={listItensNotApprove} />
            </Box>}
        </DefaultModalContent>
        <DefaultModalActions>
          <Button
            size="small"
            variant="contained"
            color="primary"
            aria-label="open"
            disabled={Boolean(listApprove?.length <= 0)}
            className={classes.button}
            style={{
              padding: '0.4rem 1.5rem',
              marginRight: '1rem',
              borderRadius: '1rem',
            }}
            startIcon={<Icons.Save />}
            onClick={handleSave}
          >
            Baixar
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            size="small"
            type="submit"
            disabled={loading || (listItens?.length > 0)}
            startIcon={
              (loading || isSubmitting) && (
                <CircularProgress className={classes.load} />
              )
            }
          >
            Confirmar
          </Button>
        </DefaultModalActions>
      </form>
    </DefaultModal>
  );
};

export default ModalAcceptCheckeds;

import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { isAuthenticated } from '../../Services/auth';

const ProtectedRoute = (props: any) => {
  const login = isAuthenticated()
  
  if (login) return <Route {...props}/>;
  else if (!login) return <Navigate to="/auth/login"/>;
  else return null;
};

export default ProtectedRoute;

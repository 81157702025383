import React from "react";
import * as Icons from "@material-ui/icons";

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, Divider, Grid } from "@material-ui/core";
import { AnticipationContext } from "..";
import { FormatCNPJ, FormatRealNumber, showDate } from "../../../../Components/Helper/FormatValues";
import ModalAccept from "./Modal/Accept";
import ModalReject from "./Modal/Reject";
import { ShowStatusAnticipation } from "../../../../Components/ShowInfos";
import SpanningTable from "./Table";
import ModalBuyer from "./Modal/Buyer";
import { ShowDetailList } from "../../../../Components/ShowDetailList";
import ModalAnticipationDate from "./Modal/Change";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    margin: "0.5rem auto",
    [theme.breakpoints.up("xs")]: {maxWidth: "100%", padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("sm")]: {maxWidth: "75%", padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("md")]: {maxWidth: "70%", padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("lg")]: {maxWidth: "65%", padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("xl")]: {maxWidth: "55%", padding: theme.spacing(0, 6, 0, 6),},
  },
  main:{
    border: `1px solid  ${theme.palette.divider}`,
    borderRadius: "10px",
    boxShadow: "1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    alignContent: "flex-start",
    alignItems  : "flex-start",
    padding: "1rem",
  },
  subTitle: {
    fontWeight: "bold",
    letterSpacing: '0px', 
    fontFamily: 'Montserrat',
    color: theme.palette.secondary.main,
    marginBottom: "0.5rem",
    [theme.breakpoints.up("xs")]: { fontSize: 15, },
    [theme.breakpoints.up("xl")]: { fontSize: 17, },
  },
  ActionContent:{
   display: "inline-flex",
   alignItems: "center",
   float:"right",
   padding:0,
   margin: "1rem 0rem"
  },
  button:{
    fontFamily: 'Montserrat',
    letterSpacing: 1.2,
    fontWeight: "bold",
    fontSize: 12,
    borderRadius: "1rem",
    padding: "0.4rem 1rem",
  },
  table:{
   minHeight: "100px",
   width: "100%",
   padding: "0.5rem 1rem",
  },
  buttonChange:{
    letterSpacing: 0,
    fontSize: 12,
    marginLeft: "1.5rem",
    borderRadius: "1rem",
    padding: "0.4rem 1rem", 
  }
}));

const TabInformation = () => {
  const classes = useStyles();
  const { id }  = useParams();
  const { data, updateData } = React.useContext(AnticipationContext);
  const [openView, setOpenView] = React.useState(false);
  const [option, setOption] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const openLink = async () => {
    const key = data.document_key;
    if(!key){
      enqueueSnackbar("NF-e inválida", { variant: "error" });
      return 
    }
    try {
      await navigator.clipboard.writeText(key);
      window.open("https://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=resumo&tipoConteudo=7PhJ+gAVw2g=");
    } catch (err) {
 
    }
  };

  const listIssuerInformation = [
    {
      icon: <Icons.Domain />,
      text: "Razão Social: ",
      data: data?.issuer?.corporate_name,
    },
    {
      icon: <Icons.Dashboard />,
      text: "CNPJ: ",
      data: data?.issuer?.cnpj && FormatCNPJ(data?.issuer?.cnpj),
    },
  ]

 const BuyerInformation = () =>{
  const list = [];
   if(data?.type?.id === "7"){
      list.push(
      {
        icon: <Icons.Description />,
        text: "Contrato: ",
        data: data?.contract?.reference
      },
      {
        icon: <Icons.EventBusy/>,
        text: "Data de vencimento: ",
        data: showDate(data?.contract?.contractDueDate, "Não definido") ,
      })
    }else{
      list.push(
        {
          icon: <Icons.Domain />,
          text: "Razão Social: ",
          data: data?.buyer?.corporate_name,
        },
        {
          icon: <Icons.Dashboard />,
          text: "CNPJ: ",
          data: data?.buyer?.cnpj && FormatCNPJ(data?.buyer?.cnpj),
        }
      )
    }

  return <ShowDetailList title="Dados do comprador" list={list}/>  
 }

  const listAdicionalInformation = [
    {
      icon: <Icons.Style />,
      text: "Tipo: ",
      data: data?.type?.name
    },
    {
      icon: <Icons.Label />,
      text: "Número: ",
      data: data?.document_id
    },
    {
      icon: <Icons.Label />,
      text: "Operador financeiro: ",
      data: data?.financial_operator && FormatCNPJ(data?.financial_operator),
    },
    {
      icon: <Icons.LiveHelp />,
      text: "Status: ",
      data: <ShowStatusAnticipation value={data?.situation}/>,
    },
    {
      icon: <Icons.EventNote />,
      text: "Solicitado em: ",
      data: showDate(data?.createdAt, "Não definido"),
    },
    {
      icon: <Icons.Event />,
      text: "Emitido em: ",
      data: showDate(data?.issue_date, "Não definido"),
    },
    {
      icon: <Icons.EventAvailable />,
      text: "Antecipar em: ",
      data: showDate(data?.anticipation_date, "Não definido") ,
    },
  ]

  const invoices = [
    {
      icon: <Icons.AttachMoney />,
      text: "Total a receber: ",
      data: FormatRealNumber(data?.total),
    },
    {
      icon: <Icons.MoneyOff />,
      text: "Total a pagar: ",
      data: FormatRealNumber(data?.total_anticipation),
    },
  ]

  const handleOpenView =(id: any)=>{
    setOption(id);
    setOpenView(true);
  }

  const handleCloseView =()=>{
    setOpenView(false);
    setOption(null);
  }

  const getSelectContent = React.useCallback((option: any) => { 
    switch (option) {
     case 0: return <ModalAccept open={openView} close={handleCloseView} id={id} updateData={updateData}/>
     case 1: return <ModalReject open={openView} close={handleCloseView}/>
     case 2: return <ModalBuyer open={openView} close={handleCloseView}/>
     case 3: return <ModalAnticipationDate open={openView} close={handleCloseView}/>
     default: return null;
    }
  }, [option]);

 return (
 <>
  {getSelectContent(option)} 
  <Grid container className={classes.root}>
   <Grid item xs={12} className={classes.main}>

   <ShowDetailList title="Dados do emissor" list={listIssuerInformation}/>
   <Divider style={{ marginBottom: "1rem" }} />

   <BuyerInformation/>
   <Divider style={{ margin: "1rem 0rem" }} />

   <ShowDetailList title="Dados adicionais" list={listAdicionalInformation}/>
   <Button 
    size="small" 
    variant="contained" 
    color="primary" 
    startIcon={<Icons.Search/>} className={classes.button} 
    style={{marginLeft: "1.5rem", paddingRight: "2rem", display: data?.type?.id !== "6"? "none":""}}
    onClick={()=>openLink()}
    > 
     SEFAZ 
   </Button>  

   <Button 
    size="small" 
    variant="contained" 
    color="primary" 
    style={{paddingRight: "1rem"}}
    startIcon={<Icons.EventAvailable/>} className={classes.buttonChange} 
    onClick={()=>handleOpenView(3)}
    > 
     Alterar data de antecipação
   </Button> 

   <Divider style={{ margin: "1rem 0rem" }} />
   <ShowDetailList title="Faturas" list={invoices}/>
    <Box className={classes.table}>
     <SpanningTable/>
    </Box>  
  <Divider style={{ margin: "1rem 0rem" }} />

  <Grid item xs={12}>
   <Box className={classes.ActionContent}>
    <Button
     className={classes.button}
     style={{marginRight: "1rem", padding: "0.5rem 2rem"}}
     variant="contained" 
     color="primary"
     disabled={data?.situation?.id !== "1"} //não está em analise
     onClick={()=>handleOpenView(1)}
     >
     Reprovar
    </Button>
    <Button
     className={classes.button}
     style={{padding: "0.5rem 2rem"}}
     variant="contained" 
     color="secondary"
     disabled={data?.situation?.id !== "1"} //não está em analise
     onClick={()=>handleOpenView(0)}
     >
     Aprovar
    </Button>
   </Box>
  </Grid>
 </Grid>
 </Grid>
 </> 
 )
}

export default TabInformation

import React, { SetStateAction, ReactNode, Dispatch } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme, } from '@material-ui/core/styles';
import DefaultModal, { DefaultModalActions, DefaultModalContent } from '../../../../../../Components/DefaultModal'
import { MaskCNPJ, MaskCPF } from '../../../../../../Components/Helper/FormatValues';
import { BeneficiariesInterface } from '../../../../../../Interfaces/Beneficiaries';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    display: "flex",
    padding: theme.spacing(0, 1, 0, 1),
    [theme.breakpoints.up("xs")]: {width: 350, },
    [theme.breakpoints.up("sm")]: {width: 380, },
    [theme.breakpoints.up("md")]: {width: 400, },
    [theme.breakpoints.up("lg")]: {width: 400, },
    [theme.breakpoints.up("xl")]: {width: 450, },
  },
  buttonSave:{
    fontFamily: 'Montserrat',
    letterSpacing: 1.2,
    fontWeight: "bold",
    fontSize: 12,
    borderRadius: "1rem",
    padding: "0.5rem 2rem",
  },
})
);

export const DefaultValue: BeneficiariesInterface = {
  name: '',
  cpf: '',
}

const validationSchema = Yup.object().shape({
  name : Yup.string().required('Nome obrigatório'),
  cnpj: Yup.string().required("Obrigatório").matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/, "CNPJ Inválido").nullable(),
});

interface StateVariable<T> {
  value: T
  setValue: Dispatch<SetStateAction<T>>
}

interface ModalBankProps {
  open: boolean;
  setOpen:     Dispatch<SetStateAction<boolean>>;
}

const ActionCreate: React.FC<ModalBankProps> = ({open, setOpen}) => {
  const { enqueueSnackbar } = useSnackbar();
  const submit = async (values: any, { setSubmitting }: any) => {
    const data = {
      id: Date.now(),
      ...values
    }
   
    enqueueSnackbar("Beneficiário adicionado", { variant: "success" });
    handleClose()
  }

  const handleClose=()=>{
    setOpen(false)
  }

  return (
   <DefaultModal open={open} handleClose={handleClose} title="Novo beneficiário">
    <Formik
      initialValues={DefaultValue}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {(props) => <Form {...props} />}
    </Formik>
   </DefaultModal>
  )
}

export default ActionCreate

const Form = (props: any) => {
  const classes = useStyles();
  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    handleChange,
    handleSubmit,
  } = props;
 
 return (
 <form onSubmit={handleSubmit} noValidate>
  <DefaultModalContent dividers>
   <Grid container className={classes.root} spacing={3}> 
    <Grid item xs={12}>
     <TextField
      fullWidth
      autoFocus
      margin="none"
      id="name"
      name="name"
      label="Nome"
      inputProps={{ autoComplete: "off" }}
      onChange={handleChange}
      value={values.name}
      error={touched.name && Boolean(errors.name)}
      helperText={touched.name && errors.name}
      />
    </Grid>

    <Grid item xs={12}>
     <TextField
      fullWidth
      margin="none"
      id="cnpj"
      name="cnpj"
      label="CNPJ"
      inputProps={{ autoComplete: "off" }}
      InputProps={{ inputComponent: MaskCNPJ }}
      onChange={handleChange}
      value={values.cnpj}
      error={touched.cnpj && Boolean(errors.cnpj)}
      helperText={touched.cnpj && errors.cnpj}
      />
    </Grid>
    
    </Grid>
   </DefaultModalContent>
   <DefaultModalActions style={{marginRight: "0.5rem"}}>
    <Button
    className={classes.buttonSave}
    variant="contained" 
    color="primary"
    type="submit"
    disabled={!dirty || isSubmitting}>  
    Confirmar
    </Button>
  </DefaultModalActions>
  </form> 
 );
};
import React from "react";
import { FormatCNPJ } from "../../../../Components/Helper/FormatValues";
import CustomTable from "../../../../Components/Table";
 
import ActionShow from "./Actions/ActionShow";

interface PropsTable {
  data   : any;
  load?  : boolean;
  error? : any;
}
 
export default function Table({data, error, load}:PropsTable) {
  const [select, setSelect] = React.useState(null)
  const [open, setOpen] = React.useState(false);
 
  const handleOpen = (id:any)=>{
    setSelect(id)
    setOpen(true)
  }

  const handleClose = ()=>{
    setSelect(null)
    setOpen(false)
  }


  const options ={
    onRowClick: (rowData:any, rowState:any) => handleOpen(rowState.rowIndex)
  }
  return (
  <> 
  {select !== null && 
   <ActionShow  select={select} open={open} close={handleClose} />}
   <CustomTable data={data} columns={columns} opt={options} error={error} load={load} elevation={1}/>
  </> 
  );
}

const columns = [
  { name: "id", options: { display: "false" } },
  { name: "company_name",
    label: "Razão Social",
    options: { filter: true, sort: true, width: 100, },
  },
  { name: "cnpj",
    label: "CNPJ",
    options: { filter: true, sort: true, 
     customBodyRender: (value:any) => FormatCNPJ(value) },
  },
 
];


import React, { ReactNode } from 'react';
import { useNavigate } from "react-router-dom";
import jwt_decode from 'jwt-decode'
import { getToken, removeToken, setToken } from '../Services/auth';
import { useAuth } from '../Services/options/useAuth';

interface ComponentProps {
  children: ReactNode;
}

type UserInformation = {
  id: string | any;
  email: string; 
  name: string;
}

const initUser: UserInformation = {
  id: undefined,
  email: "",
  name: "",
};

export const UserContext = React.createContext<any>(null);

export const UserStorage = ({children}: ComponentProps) => {
  const [data, setData] = React.useState(null);
  const [login, setLogin] = React.useState(false);
  //const [userInfo, setUserInfo] = React.useState<UserInformation>(initUser);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const { token } = useAuth();
  const navigate = useNavigate();
 
  const userLogout = React.useCallback(
    async function () {
      setData(null);
      setError(null);
      setLoading(false);
      setLogin(false);
     // setUserInfo(initUser);
      removeToken();
      navigate("/auth/login");
    },
    [navigate]
  );
 
 function userInfo(){
  const token = getToken() ?? '';
  const { email, id,  name}: UserInformation = jwt_decode(token);
  return {email, id,  name}
 }
 
  async function userLogin({ email, password }: any) {
    try {
      setError(null);
      setLoading(true);
      const { data } = await token({ email, password })
      if (data?.hasError || !data?.data || data?.data?.length < 10) throw new Error("O endereço de email ou a senha que você inseriu não é válido");
      setToken(data.data);
      navigate("/company")
    } catch (e: any) {
      setError(e.message);
      setLogin(false);
      return false;
    } finally {
      setLoading(false);
    }
  }
 
  return (
    <UserContext.Provider
      value={{
        userLogin,
        userLogout,
        data,
        error,
        loading,
        login,
        userInfo,
  
      }}>
     {children}
    </UserContext.Provider>
  );
};
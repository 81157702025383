
import useAxios from "../../Hooks/useAxios";

export function useGeneric(){
   const { request, loading, error, buffer } = useAxios();

   const list = async () => {
    return await request({
      method: "get",
      url: "generic/list",
      headers: { "Content-Type" : "application/json"},
     })
   };

   const listByType = async (id: string) => {
    return await request({
      method: "get",
      url: `generic/${id}/search`,
      headers: { "Content-Type" : "application/json"},
     })
   };

   const listByIdAndType = async (id: string, type: string) => {
    return await request({
      method: "get",
      url: `generic/${id}/${type}/search`,
      headers: { "Content-Type" : "application/json"},
     })
   };
 
  return{ list, listByType, listByIdAndType,
          loading, error, buffer } 
}
import React from 'react'
import { useParams } from 'react-router-dom';
import { Box, Grid, Button, Tooltip, Fab, } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import * as Icons from "@material-ui/icons";
import { InformationContext } from '../..';
import Table from './Table';
import CreateBank from './Actions/CreateBank';
import { useSnackbar } from 'notistack';
import { useCompany } from '../../../../../Services/options/useCompany';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      padding: "1rem",
    },
    table:{
      minHeight: 350,
    },
    contentAction:{
     display: "inline-flex",
     alignItems: "center",
     float:"right",
     padding:0,
     marginTop: "1rem"
    },
    buttonSave:{
      fontFamily: 'Montserrat',
      letterSpacing: 1.2,
      fontWeight: "bold",
      fontSize: 12,
      borderRadius: "1rem",
      padding: "0.5rem 2rem",
    },
  })
);

const StepTwo = () => {
  const classes = useStyles()
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { banks, statusStep, setStatusStep } = React.useContext(InformationContext);
  const [ open, setOpen ] = React.useState(false)
  const { update, loading } = useCompany();

  const handleAction = async () =>{
   const buffer = {
    bank: banks.map((value: any)=>({
      agency: value.agency,
      bank_name: value.bank_name,
      account_number: value.account_number
    }))
   }
   await update(id, buffer).then(r=>{
     const { data } = r;
     if(!data.hasError){
      enqueueSnackbar("Informações salvas", { variant: "info" });
      setStatusStep((prev:any)=>({...prev, bank: "Informações salvas"}))
     }else{
      enqueueSnackbar("Erro", { variant: "error" });
     }
   })
  }
 
  return (
  <>
  <CreateBank open={open} setOpen={setOpen} /> 
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12} style={{ paddingBottom: 10, display: "flex", justifyContent: "space-between" }}>
        <Tooltip placement="top" title="Novo" aria-label="Novo">
         <Fab size="small" color="primary" aria-label="Novo" 
          onClick={()=>setOpen(true)}>
          <Icons.Add/>
         </Fab>
        </Tooltip>
      </Grid>

      <Grid item xs={12} className={classes.table}>
       <Table data={banks} />
      </Grid>
      <Grid item xs={12}>
      <Box className={classes.contentAction}>
       <Button
        disabled={statusStep.bank !== "Em edição" || loading}
        className={classes.buttonSave}
        variant="contained" 
        color="secondary"
        onClick={handleAction}
       >
        Salvar
       </Button>
      </Box>
     </Grid>
    </Grid>
  </>  
  );
};


export default StepTwo
import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Box, Button, Avatar } from '@material-ui/core';
import * as Icons from "@material-ui/icons";

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepSix from './StepSix';
 
import ModalReject from './Modal/ModalReject';
import ModalApprove from './Modal/ModalApprove';
import ModalSave from './Modal/ModalSave';
import FormLoadingComponent from '../../../../Components/FormLoading';
import { InformationContext } from '..';
import { ShowStatusCompanyAccord } from '../../../../Components/ShowInfos';
 
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "10px auto",
      minHeight: "650px",
      display: "grid",
      [theme.breakpoints.up("xs")]: {width: '100%', padding: "0rem 0.5rem"},
      [theme.breakpoints.up("sm")]: {width: '100%', padding: "0rem 1rem"},
      [theme.breakpoints.up("md")]: {width: '95%',  padding: "0rem 1rem"}, 
      [theme.breakpoints.up("lg")]: {width: '100%', },
      [theme.breakpoints.up("xl")]: {width: '90%',},
    },
    accordion:{
     marginBottom: "10px", 
     borderRadius: "15px",
    },
    header: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "15px 15px 0px 0px",
    },
    titleContent:{
      display: "flex",
      alignContent: "center",
      alignItems: "center",
    },
    avatar:{
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: "white",
      backgroundColor: theme.palette.secondary.main,
    },
    icon:{
      width: theme.spacing(2),
      height: theme.spacing(2),
      color: "white",
    },
    heading: {
      fontSize: 13,
      fontWeight: "bold",
      fontFamily: 'Montserrat',
      color: "white",
      paddingLeft: "1rem",
    },
    accordionDetails:{
      margin: "0px auto",
      alignContent: "center",
      alignItems:"center",
      justifyContent: "center"
    },
    actionContent: {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      justifyContent:"flex-end",
      margin: "0.5rem 0rem",
    },
    actionButton:{
      fontFamily: 'Montserrat',
      letterSpacing: 1,
      fontWeight: "bold",
      fontSize: 12,
      borderRadius: "1rem",
      padding: "0.5rem 2rem",
    },
  }),
);

export default function TabInfomation() {
  const classes = useStyles();
  const {loading, rate, paramsCompany, statusStep} = React.useContext(InformationContext)
  const [expanded, setExpanded] = React.useState<any>([true, false, false, false, false,]);
  const [openView, setOpenView] = React.useState(false);
  const [option, setOption] = React.useState<any>(null);
  const [isEditable, setIsEditable] = React.useState<boolean>(true);

  const handleChange = (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded((prev: any) => {
      prev[panel] = isExpanded;
      return [...prev];
  });
  };
 
  const handleOpenView = (id: number) => {
    setOption(id);
    setOpenView(true);
  };

  const handleCloseView = () => {
    setOpenView(false);
    setOption(null);
  };

  const getSelectModalContent = React.useCallback((option: any) => {
   switch (option) {
      case 0: return <ModalSave    open={openView} close={handleCloseView} />;
      case 1: return <ModalReject  open={openView} close={handleCloseView} />;
      case 2: return <ModalApprove open={openView} close={handleCloseView} />;
     default: return null;
    }
   },
   [option]
  );
 
  const listAccord = [
    {name: "Dados da empresa",
     icon: <Icons.Business className={classes.icon}/>,
     item: !loading ? <StepOne/> :<FormLoadingComponent/>,
     unmountOnExit: false,
     status: statusStep.companyData,
    },
    {name: "Dados bancários",
     icon: <Icons.AccountBalance className={classes.icon}/>,
     item: !loading ?<StepTwo/> :<FormLoadingComponent/>,
     unmountOnExit: true,
     status: statusStep.bank,
    },
    {name: "Sócios - Pessoa Jurídica",
     icon: <Icons.PeopleOutline className={classes.icon}/>,
     item: !loading ?<StepThree/> :<FormLoadingComponent/>,
     unmountOnExit: true,
     status: statusStep.person,
    },
    {name: "Sócios - Pessoa Física",
     icon: <Icons.SupervisorAccount className={classes.icon}/>,
     item: !loading ?<StepFour/> :<FormLoadingComponent/>,
     unmountOnExit: true,
     status: statusStep.legalPerson,
    },
    {name: "Taxas",
     icon: <Icons.AttachMoney className={classes.icon}/>,
     item:  rate !== null ?<StepSix/>  :<FormLoadingComponent/>,
     unmountOnExit: false,
     status: statusStep.rate,
    },
  ]; 

return (
 <>
  {getSelectModalContent(option)}
  <Grid container className={classes.root}>
   <Grid item xs={12}>
    {listAccord.map((value:any, index: number)=>
        (<Accordion TransitionProps={{ unmountOnExit: value.unmountOnExit }} key={value.name} className={classes.accordion} expanded={expanded[index]} onChange={handleChange(index)}>
          <AccordionSummary className={classes.header} expandIcon={<Icons.ExpandMore color="secondary"/>}>
           <Box className={classes.titleContent}>
            <Avatar className={classes.avatar}>{value.icon}</Avatar>
            <Typography className={classes.heading}>{value.name}</Typography>
            <Box style={{paddingLeft: "1rem"}}> 
             <ShowStatusCompanyAccord value={value.status}/>
            </Box>
           </Box>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
           {value.item}
          </AccordionDetails>
        </Accordion>)) 
    }
   <Box className={classes.actionContent}>
    <Button
     style={{ marginRight: "1rem"}}
     className={classes.actionButton}
     variant="contained"
     color="primary"
     disabled={paramsCompany.status.id !== "1"}// não esta em analise
     onClick={()=>handleOpenView(1)}
     >
     Reprovar
    </Button>
    <Button
     className={classes.actionButton}
     variant="contained"
     color="secondary"
     disabled={paramsCompany.status.id !== "1"}// não esta em analise
     onClick={()=>handleOpenView(2)}
    >
     Aprovar
    </Button>
   </Box>
   </Grid> 
  </Grid>
 </> 
  );
}

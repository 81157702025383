import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import CssBaseline from "@material-ui/core/CssBaseline";
import GlobalStyles from './Style/GlobalStyles';

import moment from 'moment'
import 'moment/locale/pt-br'
moment.locale('pt-br');

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline/>
    <GlobalStyles/>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

import React from 'react'
import { useParams } from 'react-router-dom';
import { makeStyles, createStyles, Theme} from "@material-ui/core/styles";
import { Button, CircularProgress, DialogContentText, Typography } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import DefaultModal, { DefaultModalActions, DefaultModalContent } from '../../../../../Components/DefaultModal';
import useAxios from '../../../../../Hooks/useAxios';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    [theme.breakpoints.up("xs")]: { maxWidth: 380 },
    [theme.breakpoints.up("sm")]: { maxWidth: 450 },
    [theme.breakpoints.up("md")]: { maxWidth: 500 },
    [theme.breakpoints.up("lg")]: { maxWidth: 500 },
    [theme.breakpoints.up("xl")]: { maxWidth: 550 },
  },
  contentText:{
    padding: "2rem",
  },
  load:{
    maxWidth: 10, 
    maxHeight: 10,
  }
})
);

const ModalSave = ({open, close}: any) => {
  const classes = useStyles();
  const { id }  = useParams();
  const {request, loading} = useAxios()
  const { enqueueSnackbar } = useSnackbar();

  const handleAction = async () =>{

  }

  return (
    <DefaultModal open={open} handleClose={close} title="Salvar alterações">
     <DefaultModalContent dividers className={classes.root}>
      <DialogContentText className={classes.contentText}>
        <Typography variant="subtitle1" component="span" gutterBottom>
          Deseja salvar as alterações?
        </Typography>
      </DialogContentText>
     </DefaultModalContent>
     <DefaultModalActions >
      <Button
       variant="contained"
       color="secondary"
       size="small"
       disabled={loading}
       startIcon={loading && <CircularProgress className={classes.load}/>}
       onClick={handleAction}
       >
       Confirmar
      </Button>
     </DefaultModalActions>
    </DefaultModal>
  )
}
 
export default ModalSave

import React from "react";
import { useFormik } from 'formik'; 
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import { Box, Button, createStyles, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import LockIcon from '@material-ui/icons/Lock';
import MailIcon from '@material-ui/icons/Mail';
import { UserContext } from "../../../Context/UserContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      minHeight: '100vh',
      gap: theme.spacing(5),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
    },
    sloganTitle: {
      marginBottom: theme.spacing(2),
    },
    form: {
      padding: theme.spacing(2),
      width: "400px",
    },
    submit: {
      marginTop: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    errorMsg:{
      marginTop: "0.5rem",
      color: "black",
      fontWeight: "bold",
      fontSize: 12,
    },
    environmentType: {
      fontSize: "18px",
      fontWeight: "bold",
      textAlign: "center",
      fontFamily: 'Montserrat',
      color: theme.palette.primary.main,
     },
  })
);

interface IFormData {
  email?: string;
  password?: string;
}

const initialValues: IFormData = {
  email: "",
  password: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("E-mail inválido").required("Obrigatório"),
  password: Yup.string()
    .required("Obrigatório")
    .min(4, "O a senha deve ter pelo menos 4 caracteres"),
});

export default function LoginPage() {
  const classes = useStyles();
  const { userLogin, error, loading } = React.useContext(UserContext);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
     await userLogin(values);
      !loading && formik.setSubmitting(false);
    },
  });

  return (
    <div className={classes.root}>
      <Box style={{marginRight: '2rem'}}>
          <img src='/img/logo/logo_kikai.png' alt='' style={{width: 250, height: "100%"}} />
       </Box>
      <Paper className={classes.form} elevation={3}>
        <form noValidate onSubmit={formik.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            placeholder="Seu e-mail"
            name="email"
            autoComplete="email"
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailIcon />
                </InputAdornment>
              ),
            }}
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            placeholder="Sua senha"
            type="password"
            id="password"
            autoComplete="current-password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Button
            className={classes.submit}
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
          >
            Entrar
          </Button>
          {loading && <LinearProgress color="secondary" />}
          {error && <Typography className={classes.errorMsg}>{error}</Typography>} 
        </form>
        <Divider className={classes.divider} variant="fullWidth" />
        <Grid container spacing={1}>
          <Grid item xs={12}>
          <Typography className={classes.environmentType}  align="center" >{process.env.REACT_APP_BACKOFFICE_API === "https://api.backoffice-dev.kikaipay.com.br/api/v1/" ? "Ambiente de teste":"Ambiente de produção"}</Typography>
            {/* <Link to="/auth/forgot-password">
              <Button variant="text" fullWidth={true}>
                Esqueceu a senha?
              </Button>
            </Link> */}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
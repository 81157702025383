import React from "react";
import { Fab, Grid, IconButton, Tooltip } from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import { FormatCNPJ, phoneMask } from "../../Components/Helper/FormatValues";
import { ShowStatusCompany } from "../../Components/ShowInfos";
import CustomTable from "../../Components/Table";

interface PropsTable {
  data: any;
  load: boolean;
  error: any;
}

export default function ReadTable({ data, error, load }: PropsTable) {
  const options = {
    elevation: 0,
  };

  return (
    <CustomTable
      data={data}
      columns={columns}
      opt={options}
      error={error}
      load={load}
      elevation={0}
    />
  );
}

const actions = ({ tableMeta }: any) => {
  return (
    <Grid
      container
      spacing={3}
      direction="row"
      wrap="nowrap"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Tooltip placement="top" title="Visualizar" aria-label="view">
          <IconButton
            size="small"
            color="primary"
            style={{ padding: 0 }}
            aria-label="open"
            onClick={() =>
              window.open(
                `/company/description/${tableMeta.rowData[1]}`,
                "_blank"
              )
            }
          >
            <Icons.Description />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const columns = [
  {
    name: "action",
    label: "Ação",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
        actions({ tableMeta }),
    },
  },
  { name: "id", options: { display: "false" } },
  {
    name: "corporate_name",
    label: "Razão social",
    options: { filter: true, sort: true },
  },

  {
    name: "trading_name",
    label: "Nome Fantasia",
    options: { filter: true, sort: true },
  },

  {
    name: "cnpj",
    label: "CNPJ",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value: any) => FormatCNPJ(value),
    },
  },

  {
    name: "telephone",
    label: "Telefone",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value: any) => phoneMask(value),
    },
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value: any) => <ShowStatusCompany value={value} />,
    },
  },
];

import React from "react";
import * as Icons from "@material-ui/icons";

import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";

import {
  FormatCNPJ,
  showDate,
  FormatRealNumber,
  formatDate,
} from "../../../../Components/Helper/FormatValues";

import { PaymentsContext } from "..";
import ModalAccept from "./Modal/Accept";
import ModalReject from "./Modal/Reject";
import Table from "./Table";
import { ShowDetailList } from "../../../../Components/ShowDetailList";
import moment from "moment";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    margin: "0.5rem auto",
    [theme.breakpoints.up("xs")]: { width: "100%", padding: theme.spacing(0, 1, 0, 1), },
    [theme.breakpoints.up("sm")]: { width: "100%", padding: theme.spacing(0, 1, 0, 1), },
    [theme.breakpoints.up("md")]: { width: "70%", padding: theme.spacing(0, 1, 0, 1), },
    [theme.breakpoints.up("lg")]: { width: "65%", padding: theme.spacing(0, 1, 0, 1), },
    [theme.breakpoints.up("xl")]: { width: "50%", padding: theme.spacing(0, 1, 0, 1), },
  },
  main: {
    border: `1px solid  ${theme.palette.divider}`,
    borderRadius: "10px",
    boxShadow: "1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    alignContent: "flex-start",
    alignItems: "flex-start",
    padding: "1rem",
  },
  subTitle: {
    fontWeight: "bold",
    letterSpacing: "0px",
    fontFamily: "Montserrat",
    color: theme.palette.secondary.main,
    marginBottom: "0.5rem",
    [theme.breakpoints.up("xs")]: { fontSize: 15, },
    [theme.breakpoints.up("xl")]: { fontSize: 17, },
  },
  ActionContent: {
    display: "inline-flex",
    alignItems: "center",
    float: "right",
    padding: 0,
    margin: "1rem 0rem",
  },
  button: {
    fontFamily: "Montserrat",
    letterSpacing: 1,
    fontWeight: "bold",
    fontSize: 12,
    borderRadius: "1rem",
    padding: "0.5rem 1rem",
  },
  tableContainer: {
    minHeight: "100px",
    maxWidth: "700px",
    margin: "1rem auto",
    padding: "0rem 1rem"
  },
}));

const TabInformation = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { data, bank, anticipationData, isValid, updateData } = React.useContext(PaymentsContext);
  const [openView, setOpenView] = React.useState(false);
  const [option, setOption] = React.useState(null);

  function status(value: any){
    if(value?.liquidation_date === undefined) return "";
    if(value?.liquidation_date === null){
     // if(moment(formatDate(value?.expected_date)).isSameOrAfter(formatDate(new Date())))
     //   return "A vencer"
     //    else 
      return "Não efetuado"
     }else 
      return "Efetuado"
  }

  const listPeopleInformation = [
    {
      icon: <Icons.Domain />,
      text: "Razão Social: ",
      data: data?.people?.corporate_name,
    },
    {
      icon: <Icons.Dashboard />,
      text: "CNPJ: ",
      data: data?.people && FormatCNPJ(data?.people.cnpj),
    },
  ];

  const listPaymentInformation = [
    {
      icon: <Icons.EventBusy />,
      text: "Vencimento do pagamento: ",
      data: showDate(data?.expected_date, "-"),
    },
    {
      icon: <Icons.EventAvailable />,
      text: "Data efetiva de pagamento: ",
      data: showDate(data?.liquidation_date, "-"),
    },
    {
      icon: <Icons.AttachMoney />,
      text: "Valor pago: ",
      data: FormatRealNumber(data?.total),
    },
  ];

  const listExtraDataInformation = [
    {
      icon: <Icons.Style />,
      text: "Tipo: ",
      data: data?.type?.name
    },
    {
      icon: <Icons.Label />,
      text: "Id: ",
      data: data?._id
    },
    {
      icon: <Icons.Description />,
      text: "Número do documento: ",
      data: anticipationData?.document_id
    },
    {
      icon: <Icons.Label />,
      text: "Operador financeiro: ",
      data: data?.financial_operator && FormatCNPJ(data?.financial_operator)
    },
    {
      icon: <Icons.LiveHelp />,
      text: "Status: ",
      data: status(data)
    },
    {
      icon: <Icons.Event />,
      text: "Data de solicitação: ",
      data: showDate(data?.createdAt, "Não definido"),
    },
  ];

 
  const handleOpenView = (id: any) => {
    setOption(id);
    setOpenView(true);
  };

  const handleCloseView = () => {
    setOpenView(false);
    setOption(null);
  };

  const getSelectContent = React.useCallback(
    (option: any) => {
      switch (option) {
        case 0: return <ModalAccept open={openView} close={handleCloseView} id={id} updateData={updateData} />;
        case 1: return <ModalReject open={openView} close={handleCloseView} />;
        default: return null;
      }
    },
    [option]
  );

 return (
  <>
  {getSelectContent(option)}
   <Grid container className={classes.root}>
    <Grid item xs={12} className={classes.main}>
     <ShowDetailList title="Dados da empresa" list={listPeopleInformation}/>  
     <Divider style={{ marginBottom: "1rem" }} />
     <Typography className={classes.subTitle}> Dados bancários </Typography>
     <Box component="div" className={classes.tableContainer}>
      <Table data={bank} />
     </Box>
     <Divider style={{ marginBottom: "1rem" }}/>

     <ShowDetailList title="Pagamento" list={listPaymentInformation}/>  
     <Divider style={{ marginBottom: "1rem" }} />

     <ShowDetailList title="Dados adicionais" list={listExtraDataInformation}/>  
     <Divider style={{ marginBottom: "1rem" }}/>

    <Grid item xs={12}>
     <Box className={classes.ActionContent}>
      <Button
       disabled={!isValid || data?.liquidation_date !== null}
       className={classes.button}
       variant="contained"
       color="secondary"
       onClick={() => handleOpenView(0)}
      >
       Pagamento efetuado
      </Button>
     </Box>
    </Grid>
   </Grid>
  </Grid>
 </>
 );
};

export default TabInformation;

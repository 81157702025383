import React from 'react'
import { Box, Typography, createStyles, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
     margin: theme.spacing(0, 0, 1, 0),
     display: "grid",
     textAlign:'left',
     alignItems: "baseline"
    },
    primary:{
      fontWeight: "bold",
      letterSpacing: '0px', 
      fontFamily: 'Montserrat',
      color: theme.palette.secondary.main,
      [theme.breakpoints.up("xs")]: { fontSize: "1.15rem"},   
      [theme.breakpoints.up("sm")]: { fontSize: "1.15rem"},     
      [theme.breakpoints.up("md")]: { fontSize: "1.20rem"},  
      [theme.breakpoints.up("lg")]: { fontSize: "1.25rem"},  
      [theme.breakpoints.up("xl")]: { fontSize: "1.4rem"},  
    },
    secondary:{
      fontWeight: 550,
      letterSpacing: '0px',
      fontFamily: 'Montserrat',
      color: theme.palette.primary.main,
      [theme.breakpoints.up("xs")]: { fontSize: "1.20rem"},   
      [theme.breakpoints.up("sm")]: { fontSize: "1.25rem"},     
      [theme.breakpoints.up("md")]: { fontSize: "1.25rem"},  
      [theme.breakpoints.up("lg")]: { fontSize: "1.30rem"},  
      [theme.breakpoints.up("xl")]: { fontSize: "1.50rem"},  
    }
  })
);

interface CurrentPageProps {
  primary: string;
  secondary?: string;
  loading?: boolean;
}

const CurrentPageInfo: React.FC<CurrentPageProps> = ({primary, secondary, loading}) => {
  const classes = useStyles()
   return (
      <Box className={classes.root}>
       <Typography component="span" className={classes.primary}>
       {primary}  
       </Typography> 

       <Typography component="span" className={classes.secondary}>
       {loading ? <Skeleton style={{maxWidth: 300}} /> : secondary}
       </Typography> 
      </Box>      
     )
 }
 
 CurrentPageInfo.defaultProps ={
   primary: "",
   secondary: undefined,
   loading: false,
 }
 
export default CurrentPageInfo

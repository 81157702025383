import React from "react";
import { Routes, Route } from 'react-router-dom';
import { LayoutContext } from "../../Components/Layout";
import ForgotPassword from "./ForgotPassword";
import LoginPage from "./Login";

const Auth = () => {
  const {setShowLayout} = React.useContext(LayoutContext);

  React.useEffect(()=>{
    setShowLayout(false)
  },[])

 return (
 <Routes>
  <Route path="/login"           element={<LoginPage/>}/> 
  <Route path="/forgot-password" element={<ForgotPassword/>}/> 
 </Routes>
  );
};

export default Auth;

import React from "react";
import {
  Typography,
  Button,
  DialogContentText,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme, } from '@material-ui/core/styles';
import DefaultModal, {DefaultModalContent, DefaultModalActions} from "../../../../../../Components/DefaultModal";
import { useSnackbar } from "notistack";
import { InformationContext } from "../../..";

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    display: "flex",
    [theme.breakpoints.up("xs")]: {width: 350, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 380, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 400, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 400, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 550, padding: theme.spacing(0, 1, 0, 1),},  
  },
  button:{
    fontFamily: 'Montserrat',
    letterSpacing: 1.2,
    fontWeight: "bold",
    fontSize: 12,
    borderRadius: "1rem",
    padding: "0.5rem 2rem",
  },
  notice:{
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.primary,
  }
})
);

const DeleteBank = ({open, close, closeView, id}: any) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar();
  const { setBanks } = React.useContext(InformationContext);

  const handleDelete=()=>{
    setBanks((prev:any)=> prev.filter((value: any)=> value.id !== id))
    enqueueSnackbar("Banco apagado", { variant: "info" });
    closeView()
    close()
  }

  return (
    <DefaultModal open={open} handleClose={closeView} title="Apagar banco">
      <DefaultModalContent dividers>
      <DialogContentText style={{padding:"4rem"}}>
        <Typography component="h1" className={classes.notice}>
          Deseja apagar o banco?
        </Typography>
      </DialogContentText>
      </DefaultModalContent>

      <DefaultModalActions>
        <Button
         className={classes.button}
          variant="contained"
          color="primary"
          onClick={close}
        > Não
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={handleDelete}
        > Sim
        </Button>
      </DefaultModalActions>
    </DefaultModal>
  );
}

export default DeleteBank
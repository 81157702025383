import React from 'react'
import { makeStyles, createStyles, Theme} from "@material-ui/core/styles";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from '../../../../../Components/DefaultModal'
import { Button, CircularProgress, DialogContentText, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useAnticipation } from '../../../../../Services/options/useAnticipation';
import { UserContext } from '../../../../../Context/UserContext';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    [theme.breakpoints.up("xs")]: { maxWidth: 380 },
    [theme.breakpoints.up("sm")]: { maxWidth: 450 },
    [theme.breakpoints.up("md")]: { maxWidth: 500 },
    [theme.breakpoints.up("lg")]: { maxWidth: 500 },
    [theme.breakpoints.up("xl")]: { maxWidth: 550 },
  },
  contentText:{
    padding: "2rem",
    [theme.breakpoints.up("lg")]: { padding: "3rem" },
  },
  notice:{
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.primary,
    [theme.breakpoints.up("lg")]: { fontSize: 18 },
  },
  load:{
    maxWidth: 10, 
    maxHeight: 10,
  },
  button:{
    fontFamily: 'Montserrat',
    letterSpacing: 1.2,
    fontWeight: "bold",
    fontSize: 12,
    borderRadius: "1rem",
    padding: "0.4rem 1rem",
  },
})
);

const ModalReject = ({open, close}: any) => {
  const classes = useStyles();
  const { id }  = useParams();
  const { userInfo } = React.useContext(UserContext);
  const { reject, loading } = useAnticipation();
  
  const { enqueueSnackbar } = useSnackbar();

  const handleAction = async () =>{
   const buffer = {
     _id: id,
     updatedBy: {email: userInfo().email},
   }
   await reject(buffer).then(r=>{
    const { data } = r;
    if (!data.hasError){
      enqueueSnackbar("Cancelado", { variant: "success" });
      close()
    }else{
      enqueueSnackbar("Erro", { variant: "error" });
      close()
    }
    }) 
  }
  return (
    <DefaultModal open={open} handleClose={close} title="Operação cancelada">
     <DefaultModalContent dividers className={classes.root}>
      <DialogContentText className={classes.contentText}>
        <Typography className={classes.notice} gutterBottom>
          Deseja confirmar o cancelamento da operação?
        </Typography>
      </DialogContentText>
     </DefaultModalContent>
     <DefaultModalActions >
      <Button
       className={classes.button}
       variant="contained"
       color="secondary"
       size="small"
       disabled={loading}
       startIcon={loading && <CircularProgress className={classes.load}/>}
       onClick={handleAction}
      >
       Confirmar
      </Button>
      </DefaultModalActions>
    </DefaultModal>
  )
}
 
export default ModalReject

import useAxios from "../../Hooks/useAxios";

export function useAuth(){
   const { request, loading, error, buffer } = useAxios();
  
   const recoverPassword = async (body: any) => {
     return await request({
      method: "post",
      url: "auth/recover-password",
      headers: {"Content-Type": "application/json"},
      data: body,
    })
   };

   const userDetail = async (token: any) => {
    return await request({
      method: "get",
      url: `auth/detail`,
      headers: {
        "Content-Type" : "application/json",
        "Authorization": `Bearer ${token}`,
      },
     })
   };

   const token = async (body: any) => {
    return await request({
      method: "post",
      url: "auth/signin",
      headers: { "Content-Type": "application/json" },
      data: body,
     })
   };

  return{ recoverPassword, userDetail, token,
          loading, error, buffer } 
}
import React from 'react'
import { Grid, Typography, TextField } from '@material-ui/core';
import { makeStyles, Theme, } from '@material-ui/core/styles';
import { NumberFormatPercentage } from '../../../../../../Components/Helper/FormatValues';
 
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "grid",
    padding: "2rem 1rem",
    width: "100%",
    gap: "1rem",
    border: `1px solid  ${theme.palette.divider}`,
    borderRadius: "1rem",
    boxShadow: "1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
  },
  title: {
    fontWeight: "bolder",
    fontSize: 20,
    color: theme.palette.secondary.main,
  },
 
}));

const FormClient = ({formik}: any) => {
 const classes = useStyles();
 const {
  values,
  touched,
  errors,
  handleChange,
  setFieldValue, 
} = formik;

const checkChargeImported = Boolean(values?.chargeImported?.interval?.length > 0)
 return(
 <Grid container  className={classes.root}>
  <Grid item xs={12}>
   <Typography className={classes.title}>Definido por fatura</Typography>
  </Grid>
 
  <Grid item xs={8} >
      <TextField
       id="definedByInvoice.fixedDueDate"
       name="definedByInvoice.fixedDueDate"
       label="Dia fixo de vencimento"
       margin="none"
       inputProps={{ autoComplete: 'off' }} 
       onChange={(event: React.ChangeEvent<HTMLInputElement>)=>
        setFieldValue("definedByInvoice.fixedDueDate", Number(event.target.value) )}
       value={values.definedByInvoice?.fixedDueDate}
       error={touched.definedByInvoice?.fixedDueDate && Boolean(errors.definedByInvoice?.fixedDueDate)}
       helperText={touched.definedByInvoice?.fixedDueDate && errors.definedByInvoice?.fixedDueDate}
      />
     </Grid>

     <Grid item xs={8}>
      <TextField
      disabled={checkChargeImported}
       margin="none"
       size="small"
       id="definedByInvoice.dailyFee"
       name="definedByInvoice.dailyFee"
       label="Taxa dia"
       inputProps={{ autoComplete: 'off' }} 
       InputProps={{
        inputComponent: NumberFormatPercentage,
       }}
       onChange={handleChange}
       value={values.definedByInvoice?.dailyFee}
       error={touched.definedByInvoice?.dailyFee && Boolean(errors.definedByInvoice?.dailyFee)}
       helperText={touched.definedByInvoice?.dailyFee && errors.definedByInvoice?.dailyFee}
      />
     </Grid>

     <Grid item xs={8}>
      <TextField
       disabled={checkChargeImported}
       margin="none"
       size="small"
       id="definedByInvoice.chargeFixed"
       name="definedByInvoice.chargeFixed"
       label="Taxa fixa"
       inputProps={{ autoComplete: 'off' }} 
       InputProps={{
        inputComponent: NumberFormatPercentage,
       }}
       onChange={handleChange}
       value={values.definedByInvoice?.chargeFixed}
       error={touched.definedByInvoice?.chargeFixed && Boolean(errors.definedByInvoice?.chargeFixed)}
       helperText={touched.definedByInvoice?.chargeFixed && errors.definedByInvoice?.chargeFixed}
      />
     </Grid>

     <Grid item xs={8}>
      <TextField
       disabled={checkChargeImported}
       margin="none"
       size="small"
       id="definedByInvoice.chargeMin"
       name="definedByInvoice.chargeMin"
       label="Taxa mínima"
       inputProps={{ autoComplete: 'off' }} 
       InputProps={{
        inputComponent: NumberFormatPercentage,
       }}
       onChange={handleChange}
       value={values.definedByInvoice?.chargeMin}
       error={touched.definedByInvoice?.chargeMin && Boolean(errors.definedByInvoice?.chargeMin)}
       helperText={touched.definedByInvoice?.chargeMin && errors.definedByInvoice?.chargeMin}
      />
     </Grid>

  </Grid>
 )
}

export default FormClient
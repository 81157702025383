import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DefinedByTotal from './DefinedByTotal';
import DefinedByInvoice from './DefinedByInvoice';
import { Grid, TextField } from '@material-ui/core';
import { InformationContext } from '..';
import { NumberFormatPercentage } from '../../../../Components/Helper/FormatValues';
import ChargeImported from './ChargeImported';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    padding: '1rem',
    justifyContent: 'space-between',
  },
  rootTab: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    minHeight: 224,
    border: `1px solid  ${theme.palette.divider}`,
    borderRadius: '1rem',
    boxShadow:
      '1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  itemTab: {
    flexGrow: 1,
  },
}));

export default function Discount() {
  const classes = useStyles();
  const { formik } = React.useContext(InformationContext);
  const { values, touched, errors, handleChange, setFieldValue } = formik;

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid
        item
        xs={12}
        style={{ margin: '0.5rem 0.5rem', display: 'flex', gap: '1rem' }}
      >
        <TextField
          margin="none"
          size="small"
          id="discountParams.partnerPercentage"
          name="discountParams.partnerPercentage"
          label="Percentual do parceiro"
          inputProps={{ autoComplete: 'off' }}
          InputProps={{
            inputComponent: NumberFormatPercentage,
          }}
          onChange={handleChange}
          value={values.discountParams?.partnerPercentage}
          error={
            touched.discountParams?.partnerPercentage &&
            Boolean(errors.discountParams?.partnerPercentage)
          }
          helperText={
            touched.discountParams?.partnerPercentage &&
            errors.discountParams?.partnerPercentage
          }
        />

        <TextField
          margin="none"
          size="small"
          id="discountParams.fixedDayAnticipation"
          name="discountParams.fixedDayAnticipation"
          label="Prazo de pagamento"
          inputProps={{ autoComplete: 'off' }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue(
              'discountParams.fixedDayAnticipation',
              Number(event.target.value) ?? null
            )
          }
          value={values.discountParams?.fixedDayAnticipation}
          error={
            touched.discountParams?.fixedDayAnticipation &&
            Boolean(errors.discountParams?.fixedDayAnticipation)
          }
          helperText={
            touched.discountParams?.fixedDayAnticipation &&
            errors.discountParams?.fixedDayAnticipation
          }
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
        <ChargeImported />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
        <DefinedByTotal />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
        <DefinedByInvoice />
      </Grid>
    </Grid>
  );
}

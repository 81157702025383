import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FormLoadingComponent from './FormLoading';
import { Fab, Tooltip } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      
      textAlign:"start", 
      alignContent:"center",
      padding: theme.spacing(2),
    },
    closeButton: {
      transform: 'scale(0.825)',
      display: "flex",
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    title:{
      fontFamily: 'Montserrat',
      fontWeight:"bold",
      fontSize: 17,
      color: theme.palette.primary.main
    }
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: any;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title}>{children}</Typography>
      {onClose ? (
       <Tooltip placement="top" title="Fechar janela" aria-label="Fechar janela" className={classes.closeButton}>   
       <Fab size="small" color="primary" aria-label="close" onClick={onClose} style={{marginLeft: "1rem",}}>
         <CloseIcon fontSize="small"/>
       </Fab>
      </Tooltip>
      ) : null}
    </MuiDialogTitle>
  );
});

export const DefaultModalContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const DefaultModalActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1, 2),
  },
}))(MuiDialogActions);

export default function DefaultModal({open, handleClose, children, actionsTitle, title, scroll, customTitle, disableBackdropClick, loading,  ...others }: any) {
  const [openModal, setOpenModal] = React.useState(false)

  const closeModal = (event: any, reason: any)  => {
    if (reason === "backdropClick") return false;
    //if (reason === "escapeKeyDown") return false;
    if (typeof handleClose === "function") {
      setOpenModal(false)
      handleClose(false)
    }
  }
 
  React.useEffect(()=>{
    setOpenModal(open)
  },[open])

  return (
      <Dialog 
      PaperProps={{ style: { borderRadius: "1rem" }}}
      open={openModal} 
      onClose={closeModal}
      aria-labelledby="default-modal" 
      scroll={scroll} 
      maxWidth="xl"
      //disableBackdropClick={disableBackdropClick} 
      {...others}>
       {!loading ?
       <>
        <DialogTitle 
        id="title-default-modal"
        onClose={closeModal}>
          {title}
        </DialogTitle>
          {children}
       </>:<FormLoadingComponent/>}   
      </Dialog>
  );
}

DefaultModal.defaultProps ={
  open: false,
  scroll: "body",
  disableBackdropClick: true,
  handleClose: ()=>{},
  loading: false,
}
import React from 'react'
import { makeStyles, createStyles, Theme} from "@material-ui/core/styles";
import { Button, CircularProgress, DialogContentText, Typography } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import DefaultModal, { DefaultModalActions, DefaultModalContent } from '../../../../../Components/DefaultModal';

import { InformationContext } from '../..';
import { UserContext } from '../../../../../Context/UserContext';
import { useCompany } from '../../../../../Services/options/useCompany';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    [theme.breakpoints.up("xs")]: { maxWidth: 380 },
    [theme.breakpoints.up("sm")]: { maxWidth: 450 },
    [theme.breakpoints.up("md")]: { maxWidth: 500 },
    [theme.breakpoints.up("lg")]: { maxWidth: 500 },
    [theme.breakpoints.up("xl")]: { maxWidth: 550 },
  },
  contentText:{
    padding: "2rem",
    [theme.breakpoints.up("lg")]: { padding: "2.5rem" },
  },
  notice:{
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.primary,
    [theme.breakpoints.up("lg")]: { fontSize: 17 },
  },
  load:{
    maxWidth: 10, 
    maxHeight: 10,
  },
  buttonAction:{
    fontFamily: 'Montserrat',
    letterSpacing: 1,
    fontWeight: "bold",
    fontSize: 12,
    borderRadius: "1rem",
    padding: "0.5rem 1rem",
  }
})
);

const ModalApprove = ({open, close}: any) => {
  const classes = useStyles();
  const { companyData, rate, updateData }  = React.useContext(InformationContext);
  const { userInfo } = React.useContext(UserContext);
  const { approve, loading} = useCompany();
  const { enqueueSnackbar } = useSnackbar();

  const checkRate = Boolean(rate.partnerParams.fixedCharge !== null || rate.chargeParams.chargeMin !== null);

  const handleAction = async () =>{
    const buffer = {
     _id: companyData._id,
     updatedBy: { email: userInfo.email },
    }
   await approve(buffer).then(r=> {
    const { data } = r;
    if (!data.hasError){
      enqueueSnackbar("Cadastro aprovado", { variant: "success" });
      updateData()
      close()
    }else{
      enqueueSnackbar("Erro", { variant: "error" });
     }
    }
   ) 
  }

  return (
    <DefaultModal open={open} handleClose={close} title="Aprovar cadastro">
     <DefaultModalContent dividers className={classes.root}>
      <DialogContentText className={classes.contentText}>
        <Typography gutterBottom className={classes.notice}>
         {checkRate ? "Deseja aprovar o cadastro?": "É necessário preencher a taxa para aprovar o cadastro."}
        </Typography>
      </DialogContentText>
     </DefaultModalContent>
     <DefaultModalActions>
      <Button
       className={classes.buttonAction}
       variant="contained"
       color="secondary"
       size="small"
       disabled={loading}
       startIcon={loading && <CircularProgress className={classes.load}/>}
       onClick={checkRate ? handleAction: close}
       >
       Confirmar
      </Button>
     </DefaultModalActions>
    </DefaultModal>
  )
}
 
export default ModalApprove
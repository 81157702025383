import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import DefaultModal, {
  DefaultModalActions,
  DefaultModalContent,
} from "../../../../../Components/DefaultModal";
import {
  Button,
  CircularProgress,
  DialogContentText,
  Typography,
} from "@material-ui/core"; 
import { useSnackbar } from "notistack";
import { CustomDatePicker } from "../../../../../Components/CustomDatePicker";
import { useInvoice } from "../../../../../Services/options/useInvoice"; 
import { UserContext } from "../../../../../Context/UserContext";
import { formatDate } from "../../../../../Components/Helper/FormatValues";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("xs")]: { maxWidth: 380 },
      [theme.breakpoints.up("sm")]: { maxWidth: 450 },
      [theme.breakpoints.up("md")]: { maxWidth: 500 },
      [theme.breakpoints.up("lg")]: { maxWidth: 500 },
      [theme.breakpoints.up("xl")]: { maxWidth: 550 },
    },
    contentText: {
      padding: "0.5rem 2rem 3rem 2rem",
    },
    text: {
      fontSize: 16,
      fontWeight: "bold",
      color: theme.palette.text.primary,
      [theme.breakpoints.up("lg")]: { fontSize: 17 },
      marginBottom: "0.5rem",
    },
    subText: {
      fontSize: 14,
      fontWeight: "bold",
      color: theme.palette.text.secondary,
      marginBottom: "1.5rem",
    },
    load: {
      maxWidth: 10,
      maxHeight: 10,
    },
    button: {
      fontFamily: "Montserrat",
      letterSpacing: 1.2,
      fontWeight: "bold",
      fontSize: 12,
      borderRadius: "1rem",
      padding: "0.4rem 1rem",
    },
  })
);

const initialValues = {
  liquidation_date: null,
};

const validationSchema = Yup.object({
  liquidation_date: Yup.date().default(null).required("Obrigatório").nullable(),
});

const ModalAccept = ({ open, close, id, updateData }: any) => {
  const classes = useStyles();
  const { userInfo } = React.useContext(UserContext);
  const { changeLiquidationDate } = useInvoice();
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (values: any) => {
    const buffer = {
      invoiceId: id,
      liquidation_date: formatDate(values.liquidation_date),
      updatedBy: {email: userInfo().email},
    };
    await changeLiquidationDate(buffer).then((r) => {
      const { data } = r; 
      if (!data.hasError) {
        enqueueSnackbar("Aprovado", { variant: "success" });
        updateData();
        close();
      } else {
        enqueueSnackbar("Erro", { variant: "error" });
        close();
      }
    });
  };

  const { setFieldValue, handleSubmit, isSubmitting, values, touched, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: submit,
    });

  return (
    <DefaultModal open={open} handleClose={close} title="Pagamento efetuado">
      <form noValidate onSubmit={handleSubmit}>
        <DefaultModalContent dividers className={classes.root}>
          <DialogContentText className={classes.contentText}>
            <Typography className={classes.text} gutterBottom>
              Deseja confirmar o efetuamento do pagamento?
            </Typography>
            <Typography className={classes.subText} gutterBottom>
              informe a data de pagamento:
            </Typography>
            <CustomDatePicker
              variant="standard"
              inputVariant="standard"
              id="liquidation_date"
              name="liquidation_date"
              label="Data de pagamento"
              margin="none"
              onChange={(value: any) => {
                setFieldValue("liquidation_date", value || null);
              }}
              value={values.liquidation_date}
              error={touched.liquidation_date && Boolean(errors.liquidation_date)}
              helperText={touched.liquidation_date && errors.liquidation_date}
            />
          </DialogContentText>
        </DefaultModalContent>
        <DefaultModalActions>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            size="small"
            type="submit"
            disabled={isSubmitting}
            startIcon={isSubmitting && <CircularProgress className={classes.load} />}
          >
            Confirmar
          </Button>
        </DefaultModalActions>
      </form>
    </DefaultModal>
  );
};

export default ModalAccept;

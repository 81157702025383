import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Grid, List } from '@material-ui/core'
import {ItemCard, NoItem} from './Cards'
import ModalDelete from './ModalDelete';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    margin: "0.5rem auto",
    [theme.breakpoints.up("xs")]: {width: "100%", padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("sm")]: {width: "80%", padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("md")]: {width: "60%", padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("lg")]: {width: "48%", padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("xl")]: {width: "35%", padding: theme.spacing(0, 1, 0, 1),},
  },
  main:{
    border: `1px solid  ${theme.palette.divider}`,
    borderRadius: "10px",
    boxShadow: "1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    alignContent: "flex-start",
    alignItems  : "flex-start",
  },
  list: {
    [theme.breakpoints.up("xs")]: {minHeight: "500px", maxHeight: "500px"},
    [theme.breakpoints.up("sm")]: {minHeight: "500px", maxHeight: "500px"},
    [theme.breakpoints.up("md")]: {minHeight: "500px", maxHeight: "500px"},
    [theme.breakpoints.up("lg")]: {minHeight: "500px", maxHeight: "500px"},
    [theme.breakpoints.up("xl")]: {minHeight: "550px", maxHeight: "600px"},
    minHeight: "500px",
    maxHeight: "500px",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
  },
}));
 
const ListFiles = ({onDelete, list, loading}: any) => {
 const classes = useStyles();
 const [open, setOpen] = React.useState<any>(false)
 return (
<>    
 <ModalDelete open={open} setOpen={()=> setOpen(false)} actionDelete={onDelete}/>
 <Box className={classes.root}>
  <Grid item xs={12} className={classes.main}>

   <List dense className={classes.list}>
   {list && list.length > 0 ? 
    list.map((value: any)=>{
    return(<ItemCard key={value._id} onDelete={onDelete} item={value}/>)}
    ): <NoItem loading={loading}/>}
   </List>  

  </Grid>
 </Box>
 </>
 )
}

export default ListFiles

import { withStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import * as Icons from "@material-ui/icons";

const StyledChip = withStyles((theme: Theme) => ({
  root: {
    display: 'inline-flex',
    position: "relative",
    border: 0,
    borderRadius: 15,
    minWidth: "120px",
    
    //boxShadow: "0 3px 5px 2px #CCC",
    color: "black",
    padding: theme.spacing(0, 2, 0, 1),
  },
  label: {
    marginLeft: "1rem",
    justifyContent:"start",
    textAlign:"start",
    alignItems:"start",
    alignContent: "start",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  icon:{
    position: "absolute",
    left: "0.4rem",
    color: "#FFFF"
  }
}))(Chip);

export const ShowStatusCompany = ({value}: any) => {
  switch (value.id) {
    case "2":
      return (
        <StyledChip
          style={{
            backgroundColor: "#00802B",
            fontWeight: 550,
          }}
          label={value.name}
          icon={<Icons.ThumbUp />}
          size="small"
          variant="outlined"
        />
      );
    case "1":
      return (
        <StyledChip
          style={{
            backgroundColor: "#FFA500",
            fontWeight: 550,
          }}
          label={value.name}
          icon={<Icons.PriorityHigh />}
          size="small"
          variant="outlined"
        />
      );
    case "3":
      return (
        <StyledChip
          style={{
            backgroundColor: "#ff3330",
            fontWeight: 550,
          }}
          label={value.name}
          icon={<Icons.Warning />}
          size="small"
          variant="outlined"
        />
      );

      case "7":
        return (
          <StyledChip
            style={{
              backgroundColor: "#000",
              color: "#FFF",
              fontWeight: 550,
            }}
            label={value.name}
            icon={<Icons.Warning />}
            size="small"
            variant="outlined"
          />
        );
    default:
      return  (
        <StyledChip
          style={{
            backgroundColor: "#FFFFFF",
            fontWeight: 550,
          }}
          label={value.name}
          size="small"
          variant="outlined"
        />
      );
  }
};

export const ShowStatusAnticipation = ( {value} :any) => {
  switch (value?.id) {
    case "1":
      return (
        <StyledChip
          style={{
            backgroundColor: "#7CCCF8",
            fontWeight: 550,
          }}
          label={value?.name}
          icon={<Icons.PriorityHigh />}
          size="small"
          variant="outlined"
        />
      );
    case "3":
      return (
        <StyledChip
          style={{
            backgroundColor: "#00802B",
            fontWeight: 550,
          }}
          label={value?.name}
          icon={<Icons.ThumbUp />}
          size="small"
          variant="outlined"
        />
      );
    case "2":
      return (
        <StyledChip
          style={{
            backgroundColor: "#7CCCF8",
            fontWeight: 550,
          }}
          label={value?.name}
          icon={<Icons.PriorityHigh/>}
          size="small"
          variant="outlined"
        />
      );
    case "4":
      return (
        <StyledChip
          style={{
            backgroundColor: "#ff3330",
            fontWeight: 550,
          }}
          label={value?.name}
          icon={<Icons.Warning />}
          size="small"
          variant="outlined"
        />
      );
      default:
        return  (
          <StyledChip
            style={{
              backgroundColor: "#FFFFFF",
              fontWeight: 550,
            }}
            label={value?.name}
            size="small"
            variant="outlined"
          />
        );
    }
  };
export const ShowStatusCompanyAccord = ({value}: any) => {
  switch (value) {
    case "Informações salvas":
      return (
        <StyledChip
          style={{
            backgroundColor: "#7CCCF8",
            fontWeight: 550,
          }}
          label={value}
          icon={<Icons.ThumbUp />}
          size="small"
          variant="outlined"
        />
      );
    case "Em edição":
      return (
        <StyledChip
          style={{
            backgroundColor: "#FFA500",
            fontWeight: 550,
          }}
          label={value}
          icon={<Icons.Error />}
          size="small"
          variant="outlined"
        />
      );
    case "Pendente":
      return (
        <StyledChip
          style={{
            backgroundColor: "#ff3330",
            fontWeight: 550,
          }}
          label={value}
          icon={<Icons.Warning />}
          size="small"
          variant="outlined"
        />
      );
    default: return null;
  }
};

import useAxios from "../../Hooks/useAxios";

export function useAnticipation(){
   const { request, loading, error, buffer } = useAxios()

   const list = async () => {
     return await request({
      method: "get",
      url: "anticipation",
      headers: { "Content-Type": "application/json" },
    })
   };

   const searchList = async (body: any) => {
    return await request({
      method: "post",
      url: "anticipation/search",
      headers: { "Content-Type": "application/json" },
      data: body
    })
   };

   const description = async (id: any) => {
    return await request({
      method: "get",
      url: `anticipation/search/${id}`,
      headers: { "Content-Type": "application/json" },
    })
   };

   const invoice = async (id: string) => {
    return await request({
     method: "get",
     url: `invoice/anticipation/${id}`,
     headers: { "Content-Type": "application/json" },
   })
  };

   const approve = async (body: any) => {
    return await request({
      method: "post",
      url: "anticipation/approve",
      headers: { "Content-Type": "application/json" },
      data: body
    })
   };

   const recalculation = async (body: any) => {
    return await request({
      method: "post",
      url: "anticipation/recalculation",
      headers: { "Content-Type": "application/json" },
      data: body
    })
   };

   const reject = async (body: any) => {
    return await request({
      method: "post",
      url: "anticipation/reject",
      headers: { "Content-Type": "application/json" },
      data: body
    })
   };

   const changAnticipationDate = async (id: string, body: object) => {
    return await request({
      method: "patch",
      url: `anticipation/${id}`,
      headers: { "Content-Type": "application/json" },
      data: body
    })
   };

   const update = async (id: string, body: any) => {
    return await request({
      method: "patch",
      url: `anticipation/${id}`,
      headers: { "Content-Type": "application/json" },
      data: body,
    })
   };

   const upload = async (body: any) => {
    return await request({
      method: "post",
      url: "anticipation/upload",
      headers: { "Content-Type": "multipart/form-data" },
      data: body
    })
   };

   const removeFile = async (body: any) => {
    return await request({
      method: "post",
      url: "anticipation/upload/remove",
      headers: { "Content-Type": "application/json" },
      data: body
    })
   };
 
  return{ list, searchList, approve, reject, upload, removeFile, update, invoice, description, changAnticipationDate, recalculation,
          loading, error, buffer }
}
import React, { SetStateAction, ReactNode, Dispatch } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme, } from '@material-ui/core/styles';
import DefaultModal, { DefaultModalActions, DefaultModalContent } from '../../../../../../Components/DefaultModal'
import { MaskCEP, MaskCPF, MaskPhone } from '../../../../../../Components/Helper/FormatValues';
import { MemberInterface } from '../../../../../../Interfaces/Member';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    display: "flex",
    padding: theme.spacing(0, 1, 0, 1),
    [theme.breakpoints.up("xs")]: {width: 350, },
    [theme.breakpoints.up("sm")]: {width: 380, },
    [theme.breakpoints.up("md")]: {width: 500, },
    [theme.breakpoints.up("lg")]: {width: 600, },
    [theme.breakpoints.up("xl")]: {width: 750, },
  },
})
);
 
export const DefaultValue: MemberInterface = {
  name : '',
  cpf  : '',
  email: '',
  telephone: '',
  zip_code: '',
  state: '',
  city: '',
  neighbourhood: '',
  address: '',
  number: '',
  complement: '',
}

const validationSchema = Yup.object().shape({
  name : Yup.string().required('Nome obrigatório'),
  cpf  : Yup.string().required("Obrigatório").matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, "CPF Inválido"),
  email: Yup.string().email('E-mail inválido').required('Obrigatório'),
  telephone: Yup.string().required('Obrigatório'),
  zip_code: Yup.string().required("Obrigatório").matches(/^[\d]{2}[\d]{3}-[\d]{3}/, "CEP Inválido"), //
  state: Yup.string().required('Obrigatório'),
  city: Yup.string().required('Obrigatório'),
  neighbourhood: Yup.string().required('Obrigatório'),
  address: Yup.string().required('Obrigatório'),
  number: Yup.string().required('Obrigatório'),
  complement: Yup.string().required('Obrigatório'),
});

interface StateVariable<T> {
  value: T
  setValue: Dispatch<SetStateAction<T>>
}

interface ModalBankProps {
  open: boolean;
  setOpen:  Dispatch<SetStateAction<boolean>>;
}

const ActionCreate: React.FC<ModalBankProps> = ({open, setOpen}) => {
  const { enqueueSnackbar } = useSnackbar();
  const submit = async (values: any, { setSubmitting }: any) => {
    const data = {
      id: Date.now(),
      ...values
    }
    enqueueSnackbar("Sócio adicionado", { variant: "success" });
    handleClose()
  }

  const handleClose=()=>{
    setOpen(false)
  }

  return (
   <DefaultModal open={open} handleClose={handleClose} title="Novo sócio">
    <Formik
      initialValues={DefaultValue}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {(props) => <Form {...props} />}
    </Formik>
   </DefaultModal>
  )
}

export default ActionCreate

const Form = (props: any) => {
  const classes = useStyles();
  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    handleChange,
    handleSubmit,
  } = props;
 
 return (
 <form onSubmit={handleSubmit} noValidate>
  <DefaultModalContent dividers>
   <Grid container className={classes.root} spacing={3}> 
    <Grid item xs={6}>
     <TextField
      fullWidth
      autoFocus
      margin="none"
      id="name"
      name="name"
      label="Nome"
      inputProps={{ autoComplete: "off" }}
      onChange={handleChange}
      value={values.name}
      error={touched.name && Boolean(errors.name)}
      helperText={touched.name && errors.name}
      />
    </Grid>

    <Grid item xs={6}>
     <TextField
      fullWidth
      id="cpf"
      name="cpf"
      label="CPF"
      inputProps={{ autoComplete: "off" }}
      InputProps={{ inputComponent: MaskCPF }}
      onChange={handleChange}
      value={values.cpf}
      error={touched.cpf && Boolean(errors.cpf)}
      helperText={touched.cpf && errors.cpf}
      />
    </Grid>

    <Grid item xs={6}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="email"
       name="email"
       label="E-mail"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.email}
       error={touched.email && Boolean(errors.email)}
       helperText={touched.email && errors.email}
      />
     </Grid>

     <Grid item xs={6}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="telephone"
       name="telephone"
       label="Telefone"
       inputProps={{ autoComplete: "off" }}
       InputProps={{ inputComponent: MaskPhone }}
       onChange={handleChange}
       value={values.telephone}
       error={touched.telephone && Boolean(errors.telephone)}
       helperText={touched.telephone && errors.telephone}
      />
     </Grid>

     <Grid item xs={6}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="city"
       name="city"
       label="Cidade"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.city}
       error={touched.city && Boolean(errors.city)}
       helperText={touched.city && errors.city}
      />
     </Grid>

     <Grid item xs={6}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="neighbourhood"
       name="neighbourhood"
       label="Bairro"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.neighbourhood}
       error={touched.neighbourhood && Boolean(errors.neighbourhood)}
       helperText={touched.neighbourhood && errors.neighbourhood}
      />
     </Grid>

     <Grid item xs={6}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="address"
       name="address"
       label="Logradouro"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.address}
       error={touched.address && Boolean(errors.address)}
       helperText={touched.address && errors.address}
      />
     </Grid>

     <Grid item xs={6}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="complement"
       name="complement"
       label="Complemento"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.complement}
       error={touched.complement && Boolean(errors.complement)}
       helperText={touched.complement && errors.complement}
      />
     </Grid>

     <Grid item xs={4}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="number"
       name="number"
       label="Número"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.number}
       error={touched.number && Boolean(errors.number)}
       helperText={touched.number && errors.number}
      />
     </Grid>

     <Grid item xs={4}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="state"
       name="state"
       label="UF"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.state}
       error={touched.state && Boolean(errors.state)}
       helperText={touched.state && errors.state}
      />
     </Grid>

     <Grid item xs={4}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="zip_code"
       name="zip_code"
       label="CEP"
       inputProps={{ autoComplete: "off" }}
       InputProps={{ inputComponent: MaskCEP }}
       onChange={handleChange}
       value={values.zip_code}
       error={touched.zip_code && Boolean(errors.zip_code)}
       helperText={touched.zip_code && errors.zip_code}
      />
     </Grid>
    
    </Grid>
   </DefaultModalContent>
   <DefaultModalActions style={{marginRight: "0.5rem"}}>
    <Button
    style={{ width: 110 }}
    variant="contained"
    color="primary"
    type="submit"
    disabled={!dirty}>
    Confirmar
    </Button>
  </DefaultModalActions>
  </form>
 );
};
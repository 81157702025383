import React from "react";
import {
  Typography,
  Button,
  DialogContentText,
} from "@material-ui/core";
import DefaultModal, {DefaultModalContent, DefaultModalActions} from "../../../../../Components/DefaultModal";
import { InformationContext } from "../..";
import { useSnackbar } from "notistack";

const ActionDelete = ({select, open, close, handleFullClose}: any) => {
  const {formik} = React.useContext(InformationContext);
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete =()=>{
    const Id = select.id;
    const data = formik.values.legalPerson.filter((value: any)=> value.id !== Id)
    formik.setFieldValue("legalPerson", data);
    enqueueSnackbar("Sócio apagado", { variant: "info" });
    handleFullClose()
  }

  return (
    <DefaultModal open={open} handleClose={close} title="Apagar sócio">
      <DefaultModalContent dividers>
      <DialogContentText  style={{padding:"3rem"}}>
        <Typography component="span" variant="h5">
          Deseja apagar o sócio?
        </Typography>
      </DialogContentText>
      </DefaultModalContent>

      <DefaultModalActions>
        <Button
          variant="contained"
          color="primary"
          onClick={close}
        > Não
        </Button>
        <Button
          onClick={()=>handleDelete()}
          variant="contained"
          color="secondary"
        > Sim
        </Button>
      </DefaultModalActions>
    </DefaultModal>
  );
}

export default ActionDelete
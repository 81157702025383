
import useAxios from "../../Hooks/useAxios";

export function useCompany(){
  const { request, loading, error, buffer } = useAxios();

  const create = async (body: any) => {
    return await request({
      method: "post",
      url: "company",
      headers: { "Content-Type": "application/json" },
      data: body
    })
   };

  const searchList = async (body: object) => {
    return await request({
      method: "post",
      url: "company/search",
      headers: { "Content-Type": "application/json" },
      data: body
    })
   };
  
   const list = async () => {
     return await request({
      method: "get",
      url: "company/list",
      headers: { "Content-Type": "application/json" },
    })
   };

   const description = async (id: any) => {
    return await request({
      method: "get",
      url: `company/${id}/search`,
      headers: { "Content-Type": "application/json" },
    })
   };

   const update = async (id: string, body: any) => {
    return await request({
      method: "patch",
      url: `company/${id}`,
      headers: { "Content-Type": "application/json" },
      data: body,
    })
   };

   const approve = async (body: object) => {
    return await request({
      method: "post",
      url: "company/approve",
      headers: { "Content-Type": "application/json" },
      data: body
    })
   };

   const reject = async (body: object) => {
    return await request({
      method: "post",
      url: "company/reject",
      headers: { "Content-Type": "application/json" },
      data: body
    })
   };
 
   const upload = async (body: object) => {
    return await request({
      method: "post",
      url: "company/upload",
      headers: { "Content-Type": "multipart/form-data" },
      data: body
    })
   };

   const chargeparams = async (body: object) => {
    return await request({
      method: "post",
      url: "company/upload/chargeParams",
      headers: { "Content-Type": "multipart/form-data" },
      data: body
    })
   };

   const chargeParamsView = async (body: object) => {
    return await request({
      method: "post",
      url: "company/upload/chargeParams/view",
      headers: { "Content-Type": "multipart/form-data" },
      data: body
    })
   };

   const removeUpload = async (body: object) => {
    return await request({
      method: "post",
      url: "company/upload/remove",
      headers: { "Content-Type": "application/json" },
      data: body
    })
   };

   const openAttachment = async (path: string) => {
    return await request({
      method: "get",
      url: `attachment/${path}`,
      headers: { "Content-Type": "application/json" },
    })
   }
 
  return{create, list, searchList, upload, description, update, approve, reject, chargeparams, chargeParamsView, removeUpload, openAttachment, 
    loading, error, buffer } 
}

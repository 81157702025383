import axios from "axios";
import { getToken, removeToken } from "./auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKOFFICE_API || "https://api.backoffice-dev.kikaipay.com.br/api/v1/",
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    const auth = token ? `Bearer ${token}` : "";
    config.headers.common["Authorization"] = auth;
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  response => response,
  error => {
   if (error.response.status === 401) {
      removeToken();
      window.location.href = "/auth/login";
   }
  });

export default api;

import React from 'react'
import { makeStyles, createStyles, Theme} from "@material-ui/core/styles";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from '../DefaultModal'
import { Button, DialogContent, DialogContentText, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    [theme.breakpoints.up("xs")]: { maxWidth: 380 },
    [theme.breakpoints.up("sm")]: { maxWidth: 450 },
    [theme.breakpoints.up("md")]: { maxWidth: 500 },
    [theme.breakpoints.up("lg")]: { maxWidth: 500 },
    [theme.breakpoints.up("xl")]: { maxWidth: 550 },
  },
  contentText:{
    padding: "2rem",
    [theme.breakpoints.up("lg")]: { padding: "3rem" },
  },
  notice:{
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.primary,
    [theme.breakpoints.up("lg")]: { fontSize: 18 },
  },
  load:{
    maxWidth: 10, 
    maxHeight: 10,
  },
  button:{
    fontFamily: 'Montserrat',
    letterSpacing: 1.2,
    fontWeight: "bold",
    fontSize: 12,
    borderRadius: "1rem",
    padding: "0.4rem 1rem",
  },
})
);

const ModalDelete = ({open, close, actionDelete}: any) => {
  const classes = useStyles();
  return (
    <DefaultModal open={open} handleClose={close} title="Apagar arquivo">
    <DefaultModalContent dividers className={classes.root}>
     <DialogContentText className={classes.contentText}>
       <Typography component="span" className={classes.notice} gutterBottom>
         Deseja realmente apagar o arquivo?
       </Typography>
     </DialogContentText>
    </DefaultModalContent>
    <DefaultModalActions style={{margin: "0.5rem"}}>
     <Button
      className={classes.button}
      variant="contained"
      color="secondary"
      size="small"
      >
      Confirmar
     </Button>
    </DefaultModalActions>
   </DefaultModal>
  )
}

export default ModalDelete
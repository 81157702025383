import React from 'react';
import { useParams} from "react-router-dom";
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TabInformation from './TabInfomation';
import TabAttachments from './TabAttachments';
import TabObservation from './TabObservation';
import CurrentPageInfo from '../../../Components/Helper/CurrentPageInfo';
import { insertId } from '../../../Components/Helper/formatCompanyData';
import { FormatCNPJ } from '../../../Components/Helper/FormatValues';
import { Company as companyInterface }  from "kikai-model/src/interface/company/Company"
import { useCompany } from '../../../Services/options/useCompany';
import { Container } from '@material-ui/core';
 
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
       <Box component="div" style={{padding: "0.5rem 0rem"}}>
        {children} 
       </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "10px",
  },
  tabs:{
   borderRadius: "10px 10px 0px 0px",
  }
}));

type State   ={

};

 const Address ={
  state: "",
  zip_code: "",
  city: "",
  district: "",
  address: "",
  complement: "",
  number: "",
 };
 
 const Generic = {
  id: "",
  name: "",
  alternative_name: "",
  code: "",
  active: false,
  type: {
      id: "",
      name: "",
  }
 };

 const Owner = {
  person: [],
  legalperson: [],
 }

 const discountParams={
  fixedDueDate: 0,
  chargeFixed: 0,
  chargeMin: 0,
  dailyFee: 0,
  chargeImported: [],
 }

 const definedByTotal ={
  overdueInvoice: false,
  fixedCharge: 0,
  dayParams: {
    businessDay: 0,
    freeDay: 0,
  },
  monthParams: {
    fixedDay: 0,
    nextMonth: 0,
  }
 }

 const initialValue = {
  corporate_name: "",
  trading_name: "",
  cnpj: "",
  email: "",
  telephone: "",
  address: Address,
  status: Generic,
  user: "",
  owner: Owner,
  bank: [],
  attachment: [],
  term: [], 
  chargeParams: discountParams,
  partnerParams: definedByTotal,
  observation: "",
 }

 type Action = {type: "updateAll", payload: {data: {}}} | 
               {type: "updateCompany"}|
               {type: "updateBank"}| 
               {type: "updateRate"}| 
               {type: "updatePerson"}|
               {type: "updateLegalPerson"}|
               {type: "updateAttach"}|
               {type: "updateObservation"};

 function reducer(state: State, action: Action){
   switch (action.type) {
   case "updateAll": return action.payload;
   case "updateAttach":
   default: return state
  }
 }

export const InformationContext = React.createContext<any>(null);

export default function CompanyDescription() {
  const classes = useStyles();
  const { id } = useParams();
  const [state, dispatch] = React.useReducer(reducer, initialValue)
  const { loading, description } = useCompany()
  const [companyData, setCompanyData] = React.useState<any>(null);
  const [observation, setObservation] = React.useState<string>("");
  const [banks, setBanks] = React.useState<any>(null)
  const [person, setPerson] = React.useState<any>(null)
  const [legalPerson, setLegalPerson] = React.useState<any>(null)
  const [rate, setRate] = React.useState<any>(null)
  const [attach, setAttach] = React.useState<any>([]);
  const [paramsCompany, setParamsCompany] = React.useState<any>({name:"", cnpj: "", status:{id: "", name:""}})
  const [value, setValue] = React.useState(0);
  const [statusStep, setStatusStep] = React.useState({
    companyData: "",
    bank:"",
    person:"",
    legalPerson: "",
    rate: "",
  })

  const initFetch = async () => {
    const req = await description(id)
    dispatch({type: 'updateAll', payload: {data: req.data}})
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => setValue(newValue);
  
  const updateData= async()=>{
   await description(id).then(r =>{
      const {data} = r.data;
      setParamsCompany({
        name: data.corporate_name,
        cnpj: data.cnpj,
        status: data.status,
      });
 
      setAttach(data.attachment);
      setObservation(data.observation);

      setCompanyData({
        address: data.address,
        corporate_name: data.corporate_name,
        trading_name: data.trading_name,
        cnpj: data.cnpj,
        email: data.email,
        telephone : data.telephone,
        _id: data._id,
      });
      setBanks(insertId(data.bank));
      setPerson(data.owner?.person);
      setLegalPerson(data.owner?.legalperson);
      setRate(data?.discountParams);
    })
  }

  React.useEffect(()=>{
    updateData();
  },[])

  return (
  <InformationContext.Provider value={{
      state,
      companyData, setCompanyData,
      banks, setBanks,
      person, setPerson,
      legalPerson, setLegalPerson,
      rate, setRate,
      attach, setAttach,
      observation, setObservation,
      paramsCompany,
      updateData, loading,
      statusStep, setStatusStep}}> 
  <Container maxWidth={"xl"}>
 <CurrentPageInfo primary="Cadastro de empresas" loading={loading} secondary={`${FormatCNPJ(paramsCompany.cnpj)} - ${paramsCompany.name} - ${paramsCompany.status.name}`}/>  
    <div className={classes.root}>
      <AppBar position="static" className={classes.tabs}>
        <Tabs value={value} onChange={handleChange} aria-label="tab description" centered>
          <Tab label="Informações" {...a11yProps(0)} />
          <Tab label="Anexos"      {...a11yProps(1)} />
          <Tab label="Observações" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TabInformation/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TabAttachments/>
      </TabPanel>
      <TabPanel value={value} index={2}>
       <TabObservation/>
      </TabPanel>
    </div>
   </Container>
  </InformationContext.Provider>  
  );
}

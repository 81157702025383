import React from 'react'
import { useParams } from 'react-router-dom';
import { Formik } from "formik";
import * as Yup from "yup"; 
import * as Icons from "@material-ui/icons";

import {
  Box,
  Button,
  Chip,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from '@material-ui/core/styles';
import DefaultModal, { DefaultModalActions, DefaultModalContent } from '../../../../../Components/DefaultModal';
import { PaymentsContext } from '../..';
import {useDropzone} from 'react-dropzone'
import { useSnackbar } from 'notistack';

import { useAnticipation } from '../../../../../Services/options/useAnticipation';
import { useInvoice } from '../../../../../Services/options/useInvoice';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "0rem 1rem",
    [theme.breakpoints.between("xs","sm")]: { width: 400 },
    [theme.breakpoints.between("sm","md")]: { width: 400 },
    [theme.breakpoints.between("md","lg")]: { width: 450 },
    [theme.breakpoints.between("lg","xl")]: { width: 500 },
    [theme.breakpoints.up("xl")]: { width: 500 },
  },
  load:{
    maxWidth: 10, 
    maxHeight: 10,
  },
  buttonSave:{
    fontFamily: 'Montserrat',
    letterSpacing: 1.2,
    fontWeight: "bold",
    fontSize: '0.75em',
    borderRadius: "1rem",
    padding: "0.5rem 1rem",
  },
  dropContainer:{
    minHeight: "100px",
    border: `1px solid  ${theme.palette.divider}`,
    borderRadius: "1rem",
    boxShadow: "1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    alignContent: "flex-start",
    alignItems  : "flex-start",
    marginBottom: "0.5rem",
    cursor: "pointer",
  },
  dropText:{
    color: theme.palette.primary.main,
    fontFamily: 'Montserrat',
    
    fontWeight: "bold",
    fontSize: '1.1em',
    textAlign: "center",
    padding: "0.5rem 0rem",
  },
  dropIcon:{
    width: theme.spacing(6),
    height: theme.spacing(6),
    color: theme.palette.secondary.main,
  },
  itemContainer:{
    padding: "1rem",
  },
  chipError:{
    textAlign: "center",
    fontSize: '0.7em',
    backgroundColor: "red",
    color: "#FFFFFF",
    fontFamily: 'Montserrat',
    fontWeight: "bold",
  }
}));

const validationSchema = Yup.object().shape({
  file: Yup.array().min(1, "Nenhum arquivo selecionado").required("Nenhum arquivo selecionado"),
});

const defaultValues ={
  file: [],
}

const ModalUpload = ({open, close}: any) => {
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar();
  const { updateData } = React.useContext(PaymentsContext);
  const { upload } = useInvoice();

  const submit = async (values: any, { setSubmitting }: any) => {
    const formData = new FormData();
    formData.append("file", values.file[0]); //o arquivo
    formData.append("filter", JSON.stringify({_id: id, }, null, 2));
    formData.append("document_type", JSON.stringify({"id": "6","name": "INVOICE","type": "Document"}, null, 2));
    formData.append("owner_file", JSON.stringify({_id: id, name: "_id"}, null, 2));
    formData.append("folder_name", "RECEIPT");

    await upload(formData).then(r=>{
      const { data } = r;
      if(!data.hasError){
       enqueueSnackbar("Arquivo enviado", { variant: "success" });
       updateData();
       close();
      }else{
       enqueueSnackbar("Erro", { variant: "error" });
      }
     }).catch(e=>{
      enqueueSnackbar("Erro", { variant: "error" });
     })
     setSubmitting(false)
     
  }

 return (
  <DefaultModal open={open} handleClose={close} title="Novo arquivo">
     <Formik
      initialValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {(props) => <Form {...props}/>}
    </Formik>
  </DefaultModal>  
 )
}

const Form = (props: any) => {
  const classes = useStyles();

  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = props;

  const onDrop = React.useCallback((acceptedFiles: any) => {
    setFieldValue('file', acceptedFiles)
  }, []);
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({    
    onDrop,
    maxFiles:1,
    accept: { }
  });

  const handleDelete =()=>{
    setFieldValue('file', [])
  }
  
  const files = values.file.map((file: any) => (
     <Chip
        key={file.path}
        icon={<Icons.Description />}
        label={file.path}
        color="primary"
        onDelete={handleDelete}
      />
  ));

 return (
  <form onSubmit={handleSubmit} noValidate>
  <DefaultModalContent dividers>
   <Grid container className={classes.root} spacing={1}> 
    <Grid item xs={12} >  
     <Box {...getRootProps({className: 'dropzone'})}>
      <Box className={classes.dropContainer} >
        <input {...getInputProps()} />
        <Box style={{textAlign: "center",}}> 
         <Typography className={classes.dropText}>Selecione o arquivo </Typography>
         <Icons.CloudUpload className={classes.dropIcon}/> 
        </Box>
        <Box className={classes.itemContainer} style={{textAlign: "center"}}>
         {(touched.file && errors.file ) && 
         <Chip icon={<Icons.ErrorOutline style={{color: "#FFFF"}}/>} 
          label={errors.file} 
          className={classes.chipError} 
          />} 
         {files} 
        </Box>
      </Box>
     </Box>
    </Grid>

    </Grid>
   </DefaultModalContent>
   <DefaultModalActions style={{marginRight: "0.5rem"}}>
    <Button
    className={classes.buttonSave}
    variant="contained"
    color="secondary"
    type="submit"
    disabled={!dirty || isSubmitting}>
    Confirmar
    </Button>
  </DefaultModalActions>
  </form>
 );
};
export default ModalUpload
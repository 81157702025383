import React from 'react'
import { Fab, Grid, Tooltip } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import * as Icons from "@material-ui/icons";
 
import Table from './Table';
import ActionCreate from './Actions/ActionCreate';
import { InformationContext } from '..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      padding: "1rem",
    },
    table:{
      minHeight: 350,
    },
  })
);
 
const Person = () => {
  const classes = useStyles();
  const { formik } = React.useContext(InformationContext);
  const person = formik.values.person;
  const [open, setOpen] = React.useState(false)
 
  return (
  <>
  <ActionCreate open={open} setOpen={()=>setOpen(false)}/> 
   <Grid container spacing={1} className={classes.root}>
   <Grid item xs={12} style={{ paddingBottom: 10, display: "flex" }}>
     <Tooltip placement="top" title="Novo" aria-label="Novo">
      <Fab size="small" color="primary" aria-label="Novo" 
       onClick={()=>setOpen(true)}>
       <Icons.Add/>
      </Fab>
     </Tooltip>
    </Grid>
    <Grid item xs={12} className={classes.table}>
     <Table data={person}/>
    </Grid>
   </Grid>
  </>  
  );
};


export default Person
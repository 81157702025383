import React from 'react'
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Container,
  createStyles,
  makeStyles,
  Paper,
  Button,
} from "@material-ui/core";
import Table from './Table';
import CurrentPageInfo from '../../Components/Helper/CurrentPageInfo';
import { LayoutContext } from '../../Components/Layout';
import { useCompany } from '../../Services/options/useCompany';
import { Filter } from './Filter';
import { formatArrayToObject } from '../../Components/Helper/FormatValues';


const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: "100%",
      padding: theme.spacing(2),
      borderRadius: "10px",
    },
    button:{
      fontFamily: 'Montserrat',
      letterSpacing: 1,
      fontWeight: "bold",
      fontSize: 14,
      borderRadius: "1rem",
      padding: "0.5rem 1rem",
    },
    table:{
      borderRadius: "0.6rem",
      [theme.breakpoints.up("xs")]: { minHeight: 450, },
      [theme.breakpoints.up("sm")]: { minHeight: 450, },
      [theme.breakpoints.up("md")]: { minHeight: 450, },
      [theme.breakpoints.up("lg")]: { minHeight: 450, },
      [theme.breakpoints.up("xl")]: { minHeight: 650, },
    },
  })
);
 
const Company = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { loading, error, searchList, list } = useCompany();
  const [data, setData] = React.useState([]);
  const [search, setSearch] = React.useState({data:[]})
  const {setShowLayout} = React.useContext(LayoutContext);

  React.useEffect(()=>{
    setShowLayout(true)
  },[])

  function sortList(data: any){
    return data.sort((a: any, b: any)=>{
      if(Number(a.status.id)>Number(b.status.id)) return 1;
      if(Number(a.status.id)<Number(b.status.id)) return -1;
      return 0})
  }
 
  const updateData = async () => {
    if(search.data.length > 0){ 
      //const aux = formatArrayToObject(search.data);
      searchList(search).then(r=>{
        const { data } = r.data;
        const list = sortList(data);
         setData(list.map((r:any)=>({
              id: r.cnpj,
              cnpj: r.cnpj,
              corporate_name: r.corporate_name,
              trading_name: r.trading_name,
              telephone : r.telephone,
              status: r.status}
          ))
        )
      }
     )
    }else{
       list().then(r=>{
        const { data } = r.data;
        const list = sortList(data);
         setData(list.map((r:any)=>({
              id: r.cnpj,
              cnpj: r.cnpj,
              corporate_name: r.corporate_name,
              trading_name: r.trading_name,
              telephone : r.telephone,
              status: r.status}
          ))
        )
      }
     )
    }
  };

  React.useEffect(()=>{
    updateData();
  },[search])

return (
  <Container maxWidth="xl">
   <Grid item xs={12} style={{display: "flex", justifyContent:"space-between", marginBottom: "1rem"}}>
    <CurrentPageInfo primary="Cadastro de empresas"/>
    <Button 
     variant="contained"
     color="primary" 
     style={{borderRadius: "1rem"}}
     onClick={()=>navigate("/company/register")}
     > 
      Nova Empresa 
     </Button>
   </Grid>  
   

    <Grid item xs={12}>
     <Paper className={classes.paper}>
      <Filter setSearch={setSearch}/> 
     </Paper> 
    </Grid>        

    <Grid item xs={12} style={{marginTop: "0.5rem"}}>
     <Paper className={classes.table}>
      <Table data={data} error={error} load={loading}/> 
     </Paper>
    </Grid>
   </Container>   
 )
}

export default Company
import React from "react";
import { Formik } from 'formik'; 
import * as Yup from 'yup';
import { useParams } from "react-router-dom";
import * as Icons from "@material-ui/icons";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Grid, Button,   Avatar, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { TextEditor } from '../../../../Components/Editor';
import { convertToHTML } from 'draft-convert';
import { AnticipationContext } from '..';
import { useAnticipation } from "../../../../Services/options/useAnticipation";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "600px",
    margin: "0.5rem auto",
    alignContent: "flex-start",
    [theme.breakpoints.up("xs")]: {width: 350, padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("sm")]: {width: 400, padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("md")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("lg")]: {width: 600, padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("xl")]: {width: 700, padding: theme.spacing(0, 1, 0, 1),},
  },
  header:{
   display: "flex",
   width: "100%",
   height: "200px"
  },
  avatar: {
    borderRadius: 0,
    width: theme.spacing(25),
    height: "100%",
  },
  labelLogo:{
    fontFamily: 'Montserrat',
    letterSpacing: 1,
    fontWeight: "bold",
    fontSize: 24,
    padding: "0.8rem 1.2rem",
    color: theme.palette.primary.main,
  },
  editor:{
   margin: "0.5rem 0rem",
  },
  actionContent: {
    //margin: "0.5rem 0rem",
    width: "100%",
    display: "inline-flex",
    alignContent:"flex-start",
    alignItems: "flex-start",
    justifyContent:"flex-end",
  },
  actionButton:{
    fontFamily: 'Montserrat',
    fontWeight: "bold",
    fontSize: 12,
    borderRadius: "1rem",
    padding: "0.5rem 2rem",
  },
}));
 
const validationSchema = Yup.object().shape({
  observation: Yup.string().required("Obrigatório").min(8, "Obrigatório"),
});

const TabObservation = () => {
  const { id } = useParams()
  const { observation, setObservation } = React.useContext(AnticipationContext)
  const { enqueueSnackbar } = useSnackbar();
  const { update } = useAnticipation();

  const submit = async (values: any, { setSubmitting }: any) => {
   await update(id, { observation: values.observation}).then(r=>{
      const {data} = r;
      if(!data.hasError){
        enqueueSnackbar("Informações salvas", { variant: "success" });
        setObservation(values.observation);
      }else{
        enqueueSnackbar("Erro", { variant: "error" });
      }
    }).catch(e=>{
    enqueueSnackbar("Erro", { variant: "error" });
    })
    setSubmitting(false);
  };
  
 return(
 <Formik
  initialValues={{observation}}
  validationSchema={validationSchema}
  enableReinitialize={true}
  onSubmit={submit}
  >
  {(props) => <Forms {...props} />}
 </Formik>
 )
}

 const Forms = (props: any) => {
  const classes = useStyles();
  const { values, errors, touched, handleSubmit, isSubmitting, setFieldValue, dirty } = props;
  return (
 <form onSubmit={handleSubmit} noValidate>
   <Grid container className={classes.root}>
    <Grid item xs={12} className={classes.header}>
     <Avatar alt="logo" src="/img/files/minhas-empresas.png" className={classes.avatar} />
     <Typography className={classes.labelLogo}>Faça uma observação</Typography>
    </Grid> 
    <Grid item xs={12} className={classes.editor}>
     <TextEditor
      label=""
      onChange={(event) => {
        setFieldValue("observation", convertToHTML(event.getCurrentContent()))
      }}
      value={values.observation}
      error={touched.observation && Boolean(errors.observation)}
      helperText={touched.observation && errors.observation}
      />
   </Grid>
  
    <Box className={classes.actionContent} >
     <Button
      className={classes.actionButton}
      variant="contained" 
      color="secondary" 
      type="submit"
      disabled={ isSubmitting || !dirty}
      >
      <Icons.Save style={{marginRight: "0.5rem"}}/> 
      Salvar
     </Button>
    </Box>
   
  </Grid>
 </form>
 );
};

export default TabObservation
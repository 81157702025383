import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../Services/api";

export function SelectApi({textFieldProps, ...props}: any) {
  const { error, helperText, disabled, getOptionDisabled, config, nullMessage, ...field } = props;
  const { onChange, id, value, multiple, limitTags, getOptionSelected, getOptionLabel} = field;
  
  const [filter, setFilter] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
 
  const loading = open && options.length === 0;

   React.useEffect(() => {
    let active = true;
    if (!loading) {return undefined}

    (async () => {
      const data = await config()
      if (data.length === 0 || data === null) return setOptions([]) 
      if (active && data !== null) setOptions(data)
    })();


    return () => {active = false};
  }, [loading, config]);//filter

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
    onChange={onChange}
    value={value}
    id={id}
    open={open}
    disabled={disabled}
    getOptionDisabled={getOptionDisabled}
    multiple={multiple}
    limitTags={limitTags}
    onOpen={() => setOpen(true)}
    onClose={() => setOpen(false)}
    inputValue={filter}
    onInputChange={(event, newInputValue) => setFilter(newInputValue)}
    getOptionSelected={getOptionSelected}
    getOptionLabel={getOptionLabel}
    options={options}
    loading={loading}
    loadingText='Carregando...'
    noOptionsText="Sem opções"
    clearText="Limpar"
    openText="Abrir"
    closeText="Fechar"
    autoHighlight
    renderInput={(params) => (
      <TextField
        {...params}
        {...textFieldProps}
        helperText={helperText}
        error={error}
        InputProps={{
          ...params.InputProps,
          autoComplete: 'off',
          endAdornment: (
            <React.Fragment>
              {loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
 
      />
    )}
  />
);
}

SelectApi.defaultProps = {
  nullMessage: "Sem opções",
  textFieldProps:{
    fullWidth: true,
    size: "small",
    margin: "normal",
    variant: "outlined",
  }
 
}
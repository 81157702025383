import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Grid, Button, TextField } from '@material-ui/core';

import FormClient from './DefinedByInvoice';
import FormPartner from './DefinedByTotal';
import { InformationContext } from '../..';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useCompany } from '../../../../../Services/options/useCompany';
import { NumberFormatPercentage } from '../../../../../Components/Helper/FormatValues';
import ChargeImported from './ChargeImported';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    padding: '1rem',
    justifyContent: 'space-between',
  },
  actionContent: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    float: 'right',
    padding: 0,
    margin: '1rem 0rem',
  },
  buttonSave: {
    fontFamily: 'Montserrat',
    letterSpacing: 1.2,
    fontWeight: 'bold',
    fontSize: 12,
    borderRadius: '1rem',
    padding: '0.5rem 2rem',
  },
  rootTab: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    minHeight: 224,
    border: `1px solid  ${theme.palette.divider}`,
    borderRadius: '1rem',
    boxShadow:
      '1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  itemTab: {
    flexGrow: 1,
  },
}));

const defaultdefinedByInvoice = {
  fixedDueDate: null,
  chargeFixed: null,
  chargeMin: null,
  dailyFee: null,
};

const defaultdefinedByTotal = {
  overdueInvoice: false,
  fixedCharge: null,
  dayParams: null,
  monthParams: null,
};

const defaultChargeImported = {
  chargeMin: null,
  interval: [],
};

function formatBuffer(values: any) {
  const isChargeImported = Boolean(values?.chargeImported?.interval?.length > 0);

  const checkDefinedByTotal = () => {
 
    if (values.definedByTotal.monthOrDay === 'day') {
      return {
        definedByTotal: {
          ...values.definedByTotal,
          fixedCharge: !isChargeImported ? values.definedByTotal?.fixedCharge : null,
          monthParams: null,
          monthOrDay: undefined,
        },
      };
    } else {
      return {
        definedByTotal: {
          ...values.definedByTotal,
          fixedCharge: !isChargeImported ? values.definedByTotal?.fixedCharge : null,
          dayParams: null,
          monthOrDay: undefined,
        },
      };
    }
  };

  const checkDefinedByInvoice = () => {
    if (!isChargeImported) {
      return {
        definedByInvoice: {
          ...values.definedByInvoice,
        },
      };
    } else { 
      return {
        definedByInvoice: {
          ...defaultdefinedByInvoice,
         fixedDueDate: values.definedByInvoice.fixedDueDate,
        },
      };
    }
  };

  const checkChargeImported = () => {
    if (isChargeImported) {
      return {
        chargeImported: {
          chargeMin: values.chargeImported.chargeMin,
          interval: values.chargeImported.interval,
        },
      };
    } else {
      return {
        chargeImported: {
          ...defaultChargeImported,
        },
      };
    }
  };

  return {
    ...checkDefinedByTotal(),
    ...checkDefinedByInvoice(),
    ...checkChargeImported(),
    partnerPercentage: values.partnerPercentage,
    fixedDayAnticipation: values.fixedDayAnticipation,
  };
}

const validationSchema = Yup.object().shape({
  fixedDayAnticipation: Yup.number()
    .default(null)
    .required('Obrigatório')
    .nullable(),

  //   definedByTotal: Yup.object()
  //   .when("choose", {
  //       is: "partner",
  //       then: Yup.object({
  //         fixedCharge: Yup.number().min(0,"Minímo 0%").required("Obrigatório").nullable(),
  //         overdueInvoice: Yup.boolean().default(false),

  //         dayParams: Yup.object()
  //         .when("monthOrDay", {
  //             is: "day",
  //             then: Yup.object({
  //              businessDay: Yup.number().default(null).required("Obrigatório").nullable(),
  //              freeDay    : Yup.number().default(null).required("Obrigatório").nullable(),
  //             }).nullable().required({businessDay: "Obrigatório", freeDay: "Obrigatório"}),
  //             otherwise: Yup.object({
  //               businessDay: Yup.mixed().default(null).optional(),
  //               freeDay    : Yup.mixed().default(null).optional(),}).nullable(),
  //             }),

  //           monthParams: Yup.object()
  //           .when("monthOrDay", {
  //            is: "month",
  //            then: Yup.object({
  //              fixedDay  : Yup.number().default(null).required("Obrigatório").nullable(),
  //              nextMonth : Yup.number().default(null).required("Obrigatório").nullable(),
  //             }).nullable().required({fixedDay: "Obrigatório", nextMonth: "Obrigatório"}),
  //            otherwise: Yup.object({
  //              fixedDay  : Yup.mixed().default(null).optional(),
  //              nextMonth : Yup.mixed().default(null).optional(),}).nullable(),
  //           }),

  //       }),
  //       otherwise: Yup.object({}),
  //     }
  //   ),

  //   definedByInvoice: Yup.object()
  //   .when("choose", {
  //       is: "client",
  //       then: Yup.object({
  //         chargeMin: Yup.number().default(null).required("Obrigatório").nullable(),

  //         fixedDueDate: Yup.number().nullable().optional(),
  //         // when("manualOrFile", {
  //         //  is: "manual",
  //         //  then: Yup.number().default(null).required("Obrigatório").nullable(),
  //         //  otherwise: Yup.number().nullable().optional(),
  //         // }),

  //         chargeFixed: Yup.number().
  //         when("manualOrFile", {
  //          is: "manual",
  //          then: Yup.number().default(null).required("Obrigatório").nullable(),
  //          otherwise: Yup.number().nullable().optional(),
  //         }),

  //         dailyFee: Yup.number().
  //         when("manualOrFile", {
  //          is: "manual",
  //          then: Yup.number().default(null).required("Obrigatório").nullable(),
  //          otherwise: Yup.number().nullable().optional(),
  //         }),

  //         chargeImported: Yup.array().
  //         when("manualOrFile", {
  //          is: "file",
  //          then: Yup.array().min(1, "Selecione um arquivo").required("Selecione um arquivo"),
  //          otherwise: Yup.array().optional(),
  //         }),

  //       }),
  //       otherwise: Yup.object({}),
  //     }
  //   )
});

const StepSix = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { rate, setRate, setStatusStep } = React.useContext(InformationContext);
  const { enqueueSnackbar } = useSnackbar();
  const { update } = useCompany();

  const initialValues = {
    chargeImported: rate?.chargeImported,
    partnerPercentage: rate?.partnerPercentage,
    fixedDayAnticipation: rate.fixedDayAnticipation,
    definedByTotal: {
      ...rate?.definedByTotal,
      monthOrDay: rate?.definedByTotal?.monthParams === null ? 'day' : 'month',
    },
    definedByInvoice: { ...rate?.definedByInvoice },
  };

  //console.log('init',JSON.stringify(rate, null, 2))

  const submit = async (values: any) => {
    const buffer = formatBuffer(values);
    //console.log("send",JSON.stringify(buffer, null, 2))
    await update(id, { discountParams: buffer })
      .then((r) => {
        const { data } = r;
        if (!data.hasError) {
          enqueueSnackbar('Informações salvas', { variant: 'info' });
          setStatusStep((prev: any) => ({
            ...prev,
            rate: 'Informações salvas',
          }));
          setRate(buffer);
          formik.resetForm();
        } else {
          enqueueSnackbar('Erro', { variant: 'error' });
        }
      })
      .catch((e) => {
        enqueueSnackbar('Erro', { variant: 'error' });
      });
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submit,
  });

  React.useEffect(() => {
    if (formik.dirty) {
      setStatusStep((prev: any) => ({ ...prev, rate: 'Em edição' }));
    }
  }, [formik.dirty]);

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid
        item
        xs={12}
        style={{ margin: '0.5rem 0.5rem', display: 'flex', gap: '0.5rem' }}
      >
        <TextField
          margin="none"
          size="small"
          id="partnerPercentage"
          name="partnerPercentage"
          label="Percentual do parceiro"
          inputProps={{ autoComplete: 'off' }}
          InputProps={{
            inputComponent: NumberFormatPercentage,
          }}
          onChange={formik.handleChange}
          value={formik.values.partnerPercentage}
          error={
            formik.touched.partnerPercentage &&
            Boolean(formik.errors.partnerPercentage)
          }
          helperText={
            formik.touched.partnerPercentage && formik.errors.partnerPercentage
          }
        />

        <TextField
          margin="none"
          size="small"
          id="fixedDayAnticipation"
          name="fixedDayAnticipation"
          label="Prazo de pagamento"
          inputProps={{ autoComplete: 'off' }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            formik.setFieldValue(
              'fixedDayAnticipation',
              Number(event.target.value) ?? null
            )
          }
          value={formik.values.fixedDayAnticipation}
          error={
            formik.touched.fixedDayAnticipation &&
            Boolean(formik.errors.fixedDayAnticipation)
          }
          helperText={
            formik.touched.fixedDayAnticipation &&
            formik.errors.fixedDayAnticipation
          }
        />
      </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
          <ChargeImported formik={formik} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <FormPartner formik={formik} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <FormClient formik={formik} />
        </Grid>

      <Grid item xs={12}>
        <Box className={classes.actionContent}>
          <Button
            className={classes.buttonSave}
            onClick={() => formik.handleSubmit()}
            variant="contained"
            color="secondary"
            disabled={formik.isSubmitting || !formik.dirty}
          >
            Salvar
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default StepSix;

import React from 'react'
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from "yup";
import { makeStyles, createStyles, Theme} from "@material-ui/core/styles";
import { Box, Button, CircularProgress, DialogContentText, Typography } from '@material-ui/core';
import DefaultModal, { DefaultModalActions, DefaultModalContent } from '../../../../../Components/DefaultModal'
import { useSnackbar } from 'notistack';
import { AnticipationContext } from '../..';
import { TextEditor } from '../../../../../Components/Editor';
import { convertToHTML } from 'draft-convert';
import { useAnticipation } from '../../../../../Services/options/useAnticipation';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    [theme.breakpoints.up("xs")]: { minWidth: 400 },
    [theme.breakpoints.up("sm")]: { minWidth: 450 },
    [theme.breakpoints.up("md")]: { minWidth: 500 },
    [theme.breakpoints.up("lg")]: { minWidth: 500 },
    [theme.breakpoints.up("xl")]: { minWidth: 550 },
  },
  notice:{
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.primary,
    [theme.breakpoints.up("lg")]: { fontSize: 17 },
  },
  load:{
    maxWidth: 10, 
    maxHeight: 10,
  },
  editor:{
  height: "100%",
  },
  button:{
    fontFamily: 'Montserrat',
    letterSpacing: 1.2,
    fontWeight: "bold",
    fontSize: 12,
    borderRadius: "1rem",
    padding: "0.4rem 1rem",
  },
})
);

const validationSchema = Yup.object().shape({
  observation: Yup.string().required("Obrigatório").min(8, "Obrigatório"),
 });

const ModalReject = ({open, close}: any) => {
  const { id }  = useParams();
  const { updateData, observation } = React.useContext(AnticipationContext);
  const { reject } = useAnticipation()
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (values: any, { setSubmitting }: any) => {
   const buffer = {
     _id: id,
     observation: values.observation,
     updatedBy: {},
   }
   await reject(buffer).then(r=>
    {const { data } = r;
    if (!data.hasError){
      updateData()
      enqueueSnackbar("Reprovado", { variant: "success" });
      close()
    }else{
      enqueueSnackbar("Erro", { variant: "error" });
      close()}
    }).catch(()=>{
      enqueueSnackbar("Erro", { variant: "error" });
      close()
    })
  }

 return (
  <DefaultModal open={open} handleClose={close} title="Reprovar">
  <Formik
   initialValues={{observation}}
   validationSchema={validationSchema}
   onSubmit={submit}
   >
   {(props) => <Forms {...props} />}
  </Formik>
  </DefaultModal>
 )
}

const Forms = (props: any) => {
  const classes = useStyles();
  const { values, errors, touched, handleSubmit, isSubmitting, setFieldValue } = props;
  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers className={classes.root}>
        <DialogContentText>
        <Typography component='span' className={classes.notice} gutterBottom>
         Deseja realmente reprovar?
        </Typography>
        </DialogContentText>
       <Box className={classes.editor}>
        <TextEditor
         // toolbarButtonSize="small"
          label="Observação"
          onChange={(event: any) => {setFieldValue("observation", convertToHTML(event.getCurrentContent()))}}
          value={values.observation}
          error={touched.observation && Boolean(errors.observation)}
          helperText={touched.observation && errors.observation}
        />
       </Box> 
      </DefaultModalContent>
      <DefaultModalActions >
      <Button
       className={classes.button}
       variant="contained"
       color="secondary"
       size="small"
       type="submit"
       disabled={isSubmitting}
       startIcon={isSubmitting && <CircularProgress className={classes.load}/>}
       >
       Confirmar
      </Button>
      </DefaultModalActions>
    </form>
  );
};
 
export default ModalReject
import React from 'react'
import { Grid } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { InformationContext } from '../..';
import Table from './Table';
import ActionCreate from './Actions/ActionCreate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      padding: "1rem",
    },
    table:{
      minHeight: 350,
    },
  })
);

const StepFour = () => {
  const classes = useStyles();
  const { person } = React.useContext(InformationContext);
  const [open, setOpen] = React.useState(false)
 
  return (
    <>
    <ActionCreate open={open} setOpen={()=>setOpen(false)}/> 
     <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12} className={classes.table}>
       <Table data={person}  />
      </Grid>
     </Grid>
    </>  
    );
  };
  


export default StepFour
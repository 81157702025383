import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { makeStyles, createStyles, Theme} from "@material-ui/core/styles";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from '../../../../../Components/DefaultModal'

import { Button, CircularProgress, DialogContentText, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useInvoice } from "../../../../../Services/options/useInvoice";
import { CustomDatePicker } from '../../../../../Components/CustomDatePicker'; 
import { UserContext } from "../../../../../Context/UserContext";
import { AnticipationContext } from "../..";
import { useAnticipation } from "../../../../../Services/options/useAnticipation";
import { formatDate } from "../../../../../Components/Helper/FormatValues";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("xs")]: { maxWidth: 380 },
      [theme.breakpoints.up("sm")]: { maxWidth: 450 },
      [theme.breakpoints.up("md")]: { maxWidth: 500 },
      [theme.breakpoints.up("lg")]: { maxWidth: 500 },
      [theme.breakpoints.up("xl")]: { maxWidth: 550 },
    },
    contentText: {
      padding: "0.5rem 4rem 3rem 4rem",
    },
    text: {
      fontSize: 16,
      fontWeight: "bold",
      display: "block",
      color: theme.palette.text.primary,
      [theme.breakpoints.up("lg")]: { fontSize: 18 },
      marginBottom: "0.5rem",
    },
    subText: {
      fontSize: 15,
      fontWeight: "bold",
      display: "block",
      color: theme.palette.text.secondary,
      marginBottom: "1.5rem",
    },
    load: {
      maxWidth: 10,
      maxHeight: 10,
    },
    button: {
      fontFamily: "Montserrat",
      letterSpacing: 1.2,
      fontWeight: "bold",
      fontSize: 12,
      borderRadius: "1rem",
      padding: "0.4rem 1rem",
    },
  })
);

const initialValues = {
  anticipation_date: null,
};

const validationSchema = Yup.object({
  anticipation_date: Yup.date().default(null).required("Obrigatório").nullable(),
});

const ModalAnticipationDate = ({ open, close }: any) => {
  const classes = useStyles();
  const { id } = useParams();
  const { updateData } = React.useContext(AnticipationContext);
  const { userInfo } = React.useContext(UserContext);
  const { changAnticipationDate } = useAnticipation();
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (values: any) => {
    const buffer = {
      anticipation_date: formatDate(values.anticipation_date),
      updatedBy: {email: userInfo().email},
    };
    await changAnticipationDate(id, buffer).then((r) => {
      const { data } = r;
      if (!data.hasError) {
        enqueueSnackbar("Data de antecipação alterada", { variant: "success" });
        updateData();
        close();
      } else {
        enqueueSnackbar("Erro ao alterar data de antecipação", { variant: "error" });
        close();
      }
    }).catch(e=>{
      enqueueSnackbar("Erro ao alterar data de antecipação", { variant: "error" });
      close();
    });
  };

  const { setFieldValue, handleSubmit, isSubmitting, values, touched, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: submit,
    });

  return (
    <DefaultModal open={open} handleClose={close} title="Data de antecipação">
      <form noValidate onSubmit={handleSubmit}>
        <DefaultModalContent dividers className={classes.root}>
          <DialogContentText className={classes.contentText}>
            <Typography component="span" className={classes.text} gutterBottom>
              Deseja alterar a data de antecipação?
            </Typography>
            <Typography component="span" className={classes.subText} gutterBottom>
              informe a data de antecipação:
            </Typography>
            <CustomDatePicker
              variant="standard"
              inputVariant="standard"
              id="anticipation_date"
              name="anticipation_date"
              label="Data de antecipação"
              margin="none"
              onChange={(value: any) => {
                setFieldValue("anticipation_date", value || null);
              }}
              value={values.anticipation_date}
              error={touched.anticipation_date && Boolean(errors.anticipation_date)}
              helperText={touched.anticipation_date && errors.anticipation_date}
            />
          </DialogContentText>
        </DefaultModalContent>
        <DefaultModalActions>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            size="small"
            type="submit"
            disabled={isSubmitting}
            startIcon={isSubmitting && <CircularProgress className={classes.load} />}
          >
            Confirmar
          </Button>
        </DefaultModalActions>
      </form>
    </DefaultModal>
  );
};

export default ModalAnticipationDate;
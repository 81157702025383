import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import Theme from "./Style/Theme";
import Layout from "./Components/Layout";
import Auth from "./Pages/Auth";
import { UserStorage } from "./Context/UserContext";
import ProtectedRoute from "./Components/Helper/ProtectedRouter";

import Payments     from "./Pages/Payments";
import Receipts     from "./Pages/Receipts";
import Anticipation from "./Pages/Anticipation";

import Company from "./Pages/Company";
import CompanyDescription from "./Pages/Company/Description";
import AnticipationDescription from "./Pages/Anticipation/Description";
import PaymentsDescription from "./Pages/Payments/Description";
import ReceiptsDescription from "./Pages/Receipts/Description";
import RegisterCompany from "./Pages/Company/Register";

function App() {
 return (
  <ThemeProvider theme={Theme}>
   <BrowserRouter>
    <UserStorage>
     <Layout>

      <Routes>
       <ProtectedRoute exact path="/company"                   element={<Company/>}/>
       <ProtectedRoute exact path="/company/register"          element={<RegisterCompany/>}/>
       <ProtectedRoute exact path="/company/description/:id/*" element={<CompanyDescription/>}/>

       <ProtectedRoute exact path="/anticipation"                   element={<Anticipation/>}/>
       <ProtectedRoute exact path="/anticipation/description/:id/*" element={<AnticipationDescription/>}/>
      
       <ProtectedRoute exact path="/payments"                   element={<Payments/>}/>
       <ProtectedRoute exact path="/payments/description/:id/*" element={<PaymentsDescription/>}/>

       <ProtectedRoute exact path="/receipts"                   element={<Receipts/>}/>
       <ProtectedRoute exact path="/receipts/description/:id/*" element={<ReceiptsDescription/>}/>
      
       <Route path="/auth/*" element={<Auth/>}/>
       <Route path="/*"      element={<Navigate to="/auth/login"/>} />  
      </Routes>

     </Layout> 
    </UserStorage>
   </BrowserRouter>
  </ThemeProvider>  
 )
}

export default App

import React from 'react'
import { makeStyles, createStyles, Theme} from "@material-ui/core/styles";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from '../../../../../Components/DefaultModal'
import { Button, CircularProgress, DialogContentText, Typography } from '@material-ui/core';
import { AnticipationContext } from '../..';
import { useAnticipation } from '../../../../../Services/options/useAnticipation';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    [theme.breakpoints.up("xs")]: { width: 380 },
    [theme.breakpoints.up("sm")]: { width: 450 },
    [theme.breakpoints.up("md")]: { width: 500 },
    [theme.breakpoints.up("lg")]: { width: 500 },
    [theme.breakpoints.up("xl")]: { width: 550 },
  },
  contentText:{
    padding: "2rem",
    [theme.breakpoints.up("lg")]: { padding: "3rem" },
  },
  notice:{
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.primary,
    [theme.breakpoints.up("lg")]: { fontSize: 18 },
  },
})
);

const ModalBuyer = ({open, close}: any) => {
  const classes = useStyles();
  const { data } = React.useContext(AnticipationContext);
  const handleAction = async () =>{
 
  }

  return (
    <DefaultModal open={open} handleClose={close} title="Informações do comprador">
     <DefaultModalContent dividers className={classes.root}>
      <DialogContentText className={classes.contentText}>
 
      </DialogContentText>
     </DefaultModalContent>
    </DefaultModal>
  )
}
 
export default ModalBuyer
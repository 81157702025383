import React from "react";
import PropTypes from 'prop-types';
import {TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export function SelectCustom({textFieldProps, ...props}: any) {
    const {multiple, limitTags, error, helperText, disabled, options, getOptionDisabled, nullabId, className, style, label, ...field } = props;
    const { onChange, id, name, value } = field
  return (
   <Autocomplete
    className={className}
    onChange={onChange}
    id={id}
    //name={name}
    value={value}
    disabled={disabled}
    options={options}
    multiple={multiple}
    limitTags={limitTags}
    
    getOptionDisabled={(option) => option === options[nullabId]}
    loadingText='Carregando...'
    noOptionsText="Sem opções"
    clearText="Limpar"
    openText="Abrir"
    closeText="Fechar"
    autoHighlight
    //uncontrolled="true"
    getOptionSelected={(option, value) => option.id === value.id}
    getOptionLabel={(option) => option.name ? option.name: ''}
    renderInput={(params) =>  
    <TextField
        {...params}
        {...textFieldProps}
        helperText={helperText}
        error={error}
        style={style}
      />} 
    />
  );
}

SelectCustom.defaultProps = {
  nullMessage: "Sem opções",
  textFieldProps:{
    fullWidth: true,
    size: "small",
    margin: "normal",
    variant: "outlined",
    label: "",
  }
}

SelectCustom.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
};

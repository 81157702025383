import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as Icons from '@material-ui/icons';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DefaultModal, {
  DefaultModalActions,
  DefaultModalContent,
} from '../../../../../Components/DefaultModal';
import {
  Box,
  Button,
  CircularProgress,
  DialogContentText,
  TextField,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useAnticipation } from '../../../../../Services/options/useAnticipation';
import { jsonToExcel } from '../../../../../Components/Helper/SaveFileExcel';
import moment from 'moment';
import { 
  formatDate, 
  NumberFormatPercentage, 
} from '../../../../../Components/Helper/FormatValues';
import { UserContext } from '../../../../../Context/UserContext';
import { ShowTwoDetailList } from '../../../../../Components/ShowDetailList';
import { SelectCustom } from '../../../../../Components/SelectsCustom';
import { CustomDatePicker } from '../../../../../Components/CustomDatePicker';

interface bufferProps {
  _id: [], 
  due_date?: string, // Enviar a data nesse formato
  anticipation_date?: string, // Enviar a data nesse formato
  discount?: number, // Campo é um number
  financial_operator?: string,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 400,
      minHeight: 150,
      [theme.breakpoints.up('xs')]: { maxWidth: 480 },
      [theme.breakpoints.up('sm')]: { maxWidth: 550 },
      [theme.breakpoints.up('md')]: { maxWidth: 600 },
      [theme.breakpoints.up('lg')]: { maxWidth: 600 },
      [theme.breakpoints.up('xl')]: { maxWidth: 650 },
    },
    contentText: {
      maxWidth: 500,
      padding: '2rem',
      [theme.breakpoints.up('lg')]: { padding: '2rem' },
    },
    notice: {
      fontSize: 16,
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      [theme.breakpoints.up('lg')]: { fontSize: 18 },
    },
    load: {
      maxWidth: 10,
      maxHeight: 10,
    },
    button: {
      fontFamily: 'Montserrat',
      letterSpacing: 1.2,
      fontWeight: 'bold',
      fontSize: 12,
      borderRadius: '1rem',
      padding: '0.4rem 1rem',
    },
    containerList: {
      padding: '1rem',
      maxHeight: '400px',
      overflowY: 'auto',
    },
  })
);

const initialValues = {
  discount: null,
  financial_operator: null,
  due_date: null,
  anticipation_date: null,
};

const validationSchema = Yup.object({
  //financial_operator: Yup.object().required('Obrigatório').nullable(),
});

const ModalEditCheckeds = ({ open, close, selects, updateData }: any) => {
  const classes = useStyles();
  const { userInfo } = React.useContext(UserContext);
  const { recalculation, loading } = useAnticipation();
  const [listApprove, setListApprove] = React.useState<any>([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleSave = () => {
    jsonToExcel(
      listApprove,
      `Antecipações recalculadas - ${moment().format('DD-MM-YYYY HH-mm-ss')}`
    );
  };

  const handleClose = () => {
    if (listApprove?.length > 0) updateData();
    close();
  };

  const listItens = listApprove.map((value: any) => ({
    icon: <Icons.Domain />,
    primary: {
      text: 'Emissor: ',
      data: value.Emissor,
    },
    secondary: {
      text: 'Número: ',
      data: value['Número'],
    },
  }));

  const submit = async (values: any) => {
    const ids = selects
    .map((item: any) => item.id)

    const buffer: bufferProps = {
      _id: ids, 
    }

    if(values?.due_date) buffer.due_date = formatDate(values.due_date)
    if(values?.anticipation_date) buffer.anticipation_date = formatDate(values.anticipation_date);
    if(values?.discount) buffer.discount = values.discount;
    if(values.financial_operator?.id) buffer.financial_operator = values.financial_operator?.id;
   
    await recalculation(buffer).then(r=> {
      const { data } = r;
      if (!data.hasError){
        updateData()
        enqueueSnackbar("Antecipações recalculadas", { variant: "success" });
        close()
      }else{
        enqueueSnackbar("Erro ao recalcular antecipação", { variant: "error" });
        close()}
      }).catch(e=>{
        enqueueSnackbar("Erro ao recalcular antecipação", { variant: "error" });
        close()
      })     
  };

  const {
    setFieldValue,
    handleSubmit,
    isSubmitting,
    values,
    touched,
    errors,
    dirty,
    handleChange,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: submit,
  });

  return (
    <DefaultModal
      open={open}
      handleClose={handleClose}
      title="Recalcular antecipações"
    >
      <form noValidate onSubmit={handleSubmit}>
        <DefaultModalContent dividers className={classes.root}>
          {listItens.length === 0 ? (
            <DialogContentText className={classes.contentText}>
              <Typography
                component="span"
                className={classes.notice}
                gutterBottom
              >
                Deseja realmente recalcular as antecipações selecionadas?
              </Typography>

              <Box style={{display: "flex", flexDirection: "column", gap:"2rem", marginTop: "1rem",padding: "0px 1rem"}}>
                <TextField
                  margin="none"
                  size="small"
                  id="discount"
                  name="discount"
                  label="Taxa de desconto"
                  inputProps={{ autoComplete: 'off' }}
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  onChange={handleChange}
                  value={values.discount}
                  error={touched.discount && Boolean(errors.discount)}
                  helperText={touched.discount && errors.discount}
                />

                <CustomDatePicker
                  fullWidth
                  variant="standard"
                  inputVariant="standard"
                  id="due_date"
                  name="due_date"
                  label="Data de Vencimento"
                  margin="none"
                  onChange={(value: any) => {
                    setFieldValue('due_date', value || null);
                  }}
                  value={values.due_date}
                />
                <CustomDatePicker
                  fullWidth
                  variant="standard"
                  inputVariant="standard"
                  id="anticipation_date"
                  name="anticipation_date"
                  label="Data de Atecipação"
                  margin="none"
                  onChange={(value: any) => {
                    setFieldValue('anticipation_date', value || null);
                  }}
                  value={values.anticipation_date}
                />
                <SelectCustom
                  id="financial_operator"
                  name="financial_operator"
                  onChange={(e, value) => {
                    setFieldValue('financial_operator', value || null);
                  }}
                  value={values.financial_operator}
                  options={[
                    { id: "49275773000170", name: "FUNDO DE INVESTIMENTO EM DIREITOS CREDITÓRIOS CONTBANK" },
                    {
                      id: '37053876000106',
                      name: 'KIKAI FOMENTO MERCANTIL LTDA - 37.053.876/0001-06',
                    },
                    {
                      id: '43978697000182',
                      name: 'KIKAI SOCIEDADE DE CRÉDITO DIRETO S.A - 43.978.697/0001-82',
                    },
                    {
                      id: '32852263000115',
                      name: 'GASOLA SERVICOS DE INTERNET EIRELI - 32.852.263/0001-15',
                    },
                  ]}
                  textFieldProps={{
                    fullWidth: true,
                    size: 'small',
                    margin: 'none',
                    variant: 'standard',
                    label: 'Operador financeiro',
                  }}
                />
                <Typography
                  style={{ color: 'red', fontSize: 12, fontWeight: 'bold' }}
                >
                  {touched.financial_operator &&
                    Boolean(errors.financial_operator) &&
                    errors.financial_operator}
                </Typography>
              </Box>
            </DialogContentText>
          ) : (
            <Box className={classes.containerList}>
              <ShowTwoDetailList
                title="Antecipações recalculadas"
                list={listItens}
              />
            </Box>
          )}
        </DefaultModalContent>
        <DefaultModalActions>
          {/* <Button
            size="small"
            variant="contained"
            color="primary"
            aria-label="open"
            disabled={Boolean(listApprove?.length <= 0)}
            className={classes.button}
            style={{
              padding: '0.4rem 1.5rem',
              marginRight: '1rem',
              borderRadius: '1rem',
            }}
            startIcon={<Icons.Save />}
            onClick={handleSave}
          >
            Baixar
          </Button> */}
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            size="small"
            type="submit"
            disabled={loading || listItens?.length > 0 || !dirty}
            startIcon={
              (loading || isSubmitting) && (
                <CircularProgress className={classes.load} />
              )
            }
          >
            Confirmar
          </Button>
        </DefaultModalActions>
      </form>
    </DefaultModal>
  );
};

export default ModalEditCheckeds;

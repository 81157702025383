import React from "react";
import PropTypes from "prop-types";
import MUIRichTextEditor from 'mui-rte'
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import { Typography, Box } from "@material-ui/core"; 
import { createTheme, MuiThemeProvider, makeStyles, Theme} from "@material-ui/core/styles";
 
const useStyles = makeStyles((theme: Theme) => ({
  root:{
    height: "auto",
    width: "auto",
    display: "block",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "0.5rem",
    padding: "0px",
    margin: "0px",
   '&:hover, &:focus, &:active': {
    borderColor:"#CCC",
  },
  },
}));

export const defaultEditorTheme ={
  overrides: {
    MUIRichTextEditor: {
      root: {
        margin: "0px",
        padding: "0px",
      },
      toolbar: {
       margin: "-8px 0px 0px 0px", 
       backgroundColor: "#ebebeb",
       borderRadius: "5px 5px 0px 0px",
      },
      editorContainer: {
       padding: "0px",
      },
      editor: {
        padding: '5px 20px',
        height: '200px',
        maxHeight: '300px',
        overflow: 'auto',
      },
      placeHolder: {
        padding: "1rem 1rem",
        width: '100%',
        height: 'calc(100% - 4rem)',
        cursor: "text",
     },
     anchorLink: {
      color: '#1D91DA',
      textDecoration: 'underline',
   },
    },
  },
}

const myTheme = createTheme()
Object.assign(myTheme, defaultEditorTheme)

export function TextEditor ({
  label,
  onChange,
  value,
  id,
  readOnly,
  inlineToolbar,
  toolbar,
  error,
  helperText,
  elevation,
  stylePaper,
  controls,
  customTheme,
  toolbarButtonSize,
  ref,
  ...args
}:any) { 
  const classes = useStyles();
  const [content, setContent] = React.useState('')

  function refreshText(){
    const contentHTML = convertFromHTML(value)
    const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
    setContent(JSON.stringify(convertToRaw(state)))
  }

  React.useEffect(()=>{
   value && refreshText()
  },[])

 return (
 <>
  <Box className={classes.root} style={stylePaper}>
   <MuiThemeProvider theme={myTheme}>   
    <MUIRichTextEditor
    id={id}
    label={label}
    onChange={onChange}
    defaultValue={content}
    inlineToolbar={inlineToolbar}
    readOnly={readOnly}
    toolbar={toolbar}
    controls={controls}
    toolbarButtonSize={toolbarButtonSize}
    ref={ref}
    {...args}
   />
  </MuiThemeProvider>  
  </Box> 
 {error && helperText &&  
 <Box style={{margin:"0.3rem 0px 0px 0.3rem"}}>
  <Typography component='span' variant='subtitle2' color="error"> {helperText} </Typography>
 </Box>}
 </>
 );
}

TextEditor.defaultProps = {
  onChange: () => {},
  stylePaper:{},
  elevation: 1,
  helperText: undefined,
  customTheme: undefined,
  error: false,
  value: "",
  toolbarButtonSize: "medium",
  label: "Descrição",
  readOnly: false,
  inlineToolbar: false,
  toolbar: true,
  controls: [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "bulletList",
    "numberList",
    "link",
    "undo",
    "redo",
    "clear",
  ],
};

TextEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}; 

const ViewTheme = createTheme()
Object.assign(ViewTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
       // margin: "0px auto",
      },
      toolbar: {
      },
      editorContainer: {
      },
      editor: {
        margin: 0,
        paddingRight:"0.5rem",
        paddingLeft: "25px",
        minHeight: "200px",
        maxHeight: "350px",
        overflow: "auto"
      },
    },
  }
})

export function ViewTextEditor({value, isTheme,customTheme, ...other}:any){
  const [content, setContent] = React.useState('')
  const currentTheme = customTheme ? customTheme : ViewTheme;

  function refreshText(){
    const contentHTML = convertFromHTML(value)
    const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
    setContent(JSON.stringify(convertToRaw(state)))
  }
  
  React.useEffect(()=>{
    if(value) refreshText()
  },[value])

  return (
          isTheme ? 
          <MuiThemeProvider theme={currentTheme}>
           <MUIRichTextEditor toolbar={false} inlineToolbar={false} readOnly={true} defaultValue={content} maxLength={50} {...other}/>
          </MuiThemeProvider>:
          <MUIRichTextEditor toolbar={false} inlineToolbar={false} readOnly={true} defaultValue={content} maxLength={50}  {...other}/>
         )
}
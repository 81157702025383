import React from 'react'

const defaultValue = {
  companyData: {
    _id: "",
    corporate_name: "",
    trading_name: "",
    activity: "",
    cnpj: "",
    email: "",
    telephone: "",
    cell_phone: "",
    address:{
      state: "",
      zip_code: "",
      city: "",
      neighbourhood: "",
      address: "",
      complement: "",
      number: ""
    }
  },
  banks: [],
  owner:{
    person: [],
    legalperson: [],
  },
  chargeParams: {
    fixedDueDate: null,
    chargeFixed: null,
    chargeMin: null,
    dailyFee: null,
    chargeImported: []
},
  partnerParams: {
    overdueInvoice: false,
    fixedCharge: null,
    dayParams: null,
    monthParams: null
}
}

export function insertId(value: any){
  return value.map((value: any, key: number) =>{
    return {...value, id: Date.now() + key}
   }
  )
}

export function formatCompanyData(data: any){
  return { 
    companyData:{
      _id: data._id,
      corporate_name: data?.corporate_name ? data?.corporate_name: "",
      trading_name: data?.trading_name ? data?.trading_name: "",
      cnpj: data?.cnpj ? data?.cnpj: "",
      email: data?.email? data.email: "",
      cell_phone: data?.cell_phone? data?.cell_phone: "", 
      telephone : data?.telephone? data?.telephone: "",
      constitutional_date: data?.constitutional_date ? data?.constitutional_date : "",
      activity: data?.activity ? data?.activity: "",
      address:{   
       state: data.address?.state ? data.address?.state : "",
       zip_code: data.address?.zip_code ? data.address?.zip_code : "",
       city: data.address?.city ? data.address?.city : "",
       neighbourhood: data.address?.neighbourhood ? data.address?.neighbourhood : "",
       address: data.address?.address ? data.address?.address : "",
       complement: data.address?.complement ? data.address?.complement : "",
       number: data.address?.number ? data.address?.number : "",
      }
    },
    banks: data.bank ? insertId(data.bank) : [],
    owner: data.owner,
    
  }
}
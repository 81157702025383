import React from 'react'
import {
  Grid,
  Container,
  createStyles,
  makeStyles,
  Paper,
} from "@material-ui/core";
import Table from './Table';
import CurrentPageInfo from '../../Components/Helper/CurrentPageInfo'
import { useAnticipation } from '../../Services/options/useAnticipation';
import { Filter } from './Filter';
import {Anticipation as IAnticipation } from "kikai-model/src/interface/anticipation/Anticipation"
import { formatArrayToObject } from '../../Components/Helper/FormatValues';

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: "100%",
      padding: theme.spacing(2),
      borderRadius: "10px",
    },
    table:{
      borderRadius: "0.6rem",
      [theme.breakpoints.up("xs")]: { minHeight: 450, },
      [theme.breakpoints.up("sm")]: { minHeight: 450, },
      [theme.breakpoints.up("md")]: { minHeight: 450, },
      [theme.breakpoints.up("lg")]: { minHeight: 450, },
      [theme.breakpoints.up("xl")]: { minHeight: 650, },
    },
  })
);
 
const Anticipation = () => {
  const classes = useStyles();
  const { loading, error, list, searchList } = useAnticipation();
  const [search, setSearch] = React.useState({data:[]})
  const [ data, setData ] = React.useState<any[]>([]);

  const sortList =(data: IAnticipation[])=>{
    const sortDate = data.sort((a: IAnticipation, b: IAnticipation)=>{
      const compareDate = new Date(a.createdAt) > new Date(b.createdAt);
      if( compareDate) return -1;
      return 0
    })

   const sortStatus = sortDate.sort((a: IAnticipation, b: IAnticipation)=>{
    if( a.situation.id === "1") return -1;
    if( b.situation.id === "1") return 1;
    if( a.situation.id === "3") return 1;
    return 0
  })
   return sortStatus
  }

 
  const itemList = (list: IAnticipation[]) =>{
   return list.map((item: any)=>({
      id: item._id,
      financial_operator: item?.financial_operator,
      anticipation_date: item.anticipation_date,
      createdAt: item.createdAt,
      number: item.document_id,
      value: item.total,
      issuer: item.issuer.corporate_name,
      buyer: item.buyer.corporate_name,
      status: item.situation,
   }))
  }

  const updateData = async () => {
    if(search.data.length > 0){
    // const aux = formatArrayToObject(search.data)
      await searchList(search).then(r=>{
        const { data } = r.data;
        const list = sortList(data)
        setData(itemList(list))
      }
     )
    } else {
    await list().then(r=>{
     const { data } = r.data;
     const list = sortList(data)
     setData(itemList(list))
   }
  )
 }
 };

 React.useEffect(()=>{
  updateData();
 },[search])

 return (
 <Container maxWidth="xl">
  <CurrentPageInfo primary="Antecipação"/>
  <Grid item xs={12}>
     <Paper className={classes.paper}>
      <Filter setSearch={setSearch}/> 
     </Paper> 
    </Grid>        

    <Grid item xs={12} style={{marginTop: "0.5rem"}}>
    <Paper className={classes.table}>
     <Table data={data} error={error} load={loading} updateData={updateData}/> 
    </Paper>
    </Grid>
  </Container>   
 )
}

export default Anticipation

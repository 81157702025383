import React from 'react'
import { Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { NumberFormatPercentage } from '../../../../../Components/Helper/FormatValues';
import { InformationContext } from '../../';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  main: {
    padding: "1rem",
    minHeight: "100%",
    width: "100%",
    display: "grid",
    gap: "1rem",
    alignContent: "flex-start",
    border: `1px solid  ${theme.palette.divider}`,
    borderRadius: "1rem",
    boxShadow: "1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
  },
  title:{
    fontWeight: "bolder",
    fontSize: 20,
    color: theme.palette.secondary.main,
  }
})
);
 
const DefinedByInvoice = () =>{
  const classes = useStyles();
  const { formik } = React.useContext(InformationContext)
  const {
    values,
    touched,
    errors,
    handleChange,
    setFieldValue, 
  } = formik;

  const checkChargeImported = Boolean(values?.discountParams?.chargeImported?.interval?.length > 0)
 return(
  <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className={classes.main}>
      <Grid item xs={12}>
       <Typography className={classes.title}>Definido por fatura</Typography>
      </Grid>
     <Grid item xs={6} >
      <TextField
       fullWidth
       id="discountParams.definedByInvoice.fixedDueDate"
       name="discountParams.definedByInvoice.fixedDueDate"
       label="Dia fixo de vencimento"
       inputProps={{ autoComplete: 'off' }} 
       onChange={(event: React.ChangeEvent<HTMLInputElement>)=>
        setFieldValue("discountParams.definedByInvoice.fixedDueDate", Number(event.target.value) )}
       value={values.discountParams?.definedByInvoice?.fixedDueDate}
       error={touched.discountParams?.definedByInvoice?.fixedDueDate && Boolean(errors.discountParams?.definedByInvoice?.fixedDueDate)}
       helperText={touched.discountParams?.definedByInvoice?.fixedDueDate && errors.discountParams?.definedByInvoice?.fixedDueDate}
      />
     </Grid>

     <Grid item xs={6}>
      <TextField
       disabled={checkChargeImported}
       fullWidth
       size="small"
       id="discountParams.definedByInvoice.dailyFee"
       name="discountParams.definedByInvoice.dailyFee"
       label="Taxa dia"
       inputProps={{ autoComplete: 'off' }} 
       InputProps={{
        inputComponent: NumberFormatPercentage,
       }}
       onChange={handleChange}
       value={values.discountParams?.definedByInvoice?.dailyFee}
       error={touched.discountParams?.definedByInvoice?.dailyFee && Boolean(errors.discountParams?.definedByInvoice?.dailyFee)}
       helperText={touched.discountParams?.definedByInvoice?.dailyFee && errors.discountParams?.definedByInvoice?.dailyFee}
      />
     </Grid>

     <Grid item xs={6}>
      <TextField
       disabled={checkChargeImported}
       fullWidth
       size="small"
       id="discountParams.definedByInvoice.chargeFixed"
       name="discountParams.definedByInvoice.chargeFixed"
       label="Taxa fixa"
       inputProps={{ autoComplete: 'off' }} 
       InputProps={{
        inputComponent: NumberFormatPercentage,
       }}
       onChange={handleChange}
       value={values.discountParams?.definedByInvoice?.chargeFixed}
       error={touched.discountParams?.definedByInvoice?.chargeFixed && Boolean(errors.discountParams?.definedByInvoice?.chargeFixed)}
       helperText={touched.discountParams?.definedByInvoice?.chargeFixed && errors.discountParams?.definedByInvoice?.chargeFixed}
      />
     </Grid>

     <Grid item xs={6}>
      <TextField
       disabled={checkChargeImported}
       fullWidth
       size="small"
       id="discountParams.definedByInvoice.chargeMin"
       name="discountParams.definedByInvoice.chargeMin"
       label="Taxa mínima"
       inputProps={{ autoComplete: 'off' }} 
       InputProps={{
        inputComponent: NumberFormatPercentage,
       }}
       onChange={handleChange}
       value={values.cdiscountParams?.definedByInvoice?.chargeMin}
       error={touched.discountParams?.definedByInvoice?.chargeMin && Boolean(errors.discountParams?.definedByInvoice?.chargeMin)}
       helperText={touched.discountParams?.definedByInvoice?.chargeMin && errors.discountParams?.definedByInvoice?.chargeMin}
      />
     </Grid>
    </Grid>
 )
}

export default DefinedByInvoice
import React from "react";
import {
  Typography,
  Button,
  DialogContentText,
} from "@material-ui/core";
import DefaultModal, {DefaultModalContent, DefaultModalActions} from "../../../../../../Components/DefaultModal";

const ActionDelete = ({open, close}: any) => {
  return (
    <DefaultModal open={open} handleClose={close} title="Apagar sócio">
      <DefaultModalContent dividers>
      <DialogContentText  style={{padding:"2rem"}}>
        <Typography component="h1">
          Deseja apagar o sócio?
        </Typography>
      </DialogContentText>
      </DefaultModalContent>

      <DefaultModalActions>
        <Button
          variant="contained"
          color="primary"
          onClick={close}
        > Não
        </Button>
        <Button
          variant="contained"
          color="primary"
        > Sim
        </Button>
      </DefaultModalActions>
    </DefaultModal>
  );
}

export default ActionDelete
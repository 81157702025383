import React from "react";
import CustomTable from "../../../../../Components/Table";
import ShowBank from "./Actions/ShowBank";

interface PropsTable {
  data   : any;
  load?  : boolean;
  error? : any;
}
 
export default function Table({data, error, load }:PropsTable) {
  const [select, setSelect] = React.useState(null)
  const [open, setOpen] = React.useState(false);
 
  const handleOpen = (id:any)=>{
    setSelect(id)
    setOpen(true)
  }

  const handleClose = ()=>{
    setSelect(null)
    setOpen(false)
  }

  const options ={
    onRowClick: (rowData:any, rowState:any) => handleOpen(rowState.rowIndex)
  }
  return (
  <> 
  {select !== null && 
   <ShowBank    select={select} open={open} close={handleClose} />}
   <CustomTable data={data} columns={columns} opt={options} error={error} load={load} elevation={1}/>
  </> 
  );
}

const columns = [
  { name: "id", options: { display: "false" } },
  { name: "bank_name",
    label: "Banco",
    options: { filter: true, sort: true, width: 100, },
  },
  { name: "agency",
    label: "Agência",
    options: { filter: true, sort: true, },
  },
  { name: "account_number",
  label: "Conta",
  options: { filter: true, sort: true, },},
];


import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CurrentPageInfo from '../../../Components/Helper/CurrentPageInfo';
import TabInformation from './TabInformation';
import TabObservation from './TabObservation';
import { useParams } from 'react-router-dom';
import TabAttachments from './TabAttachments';
import { useAnticipation } from '../../../Services/options/useAnticipation';
import { FormatCNPJ, formatDate } from '../../../Components/Helper/FormatValues';
import moment from 'moment';
import { Container } from '@material-ui/core';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="div" p={3}>
         {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "10px",
  },
  tabs:{
   borderRadius: "10px 10px 0px 0px",
  }
}));

export const AnticipationContext = React.createContext<any>(null);

export default function AnticipationDescription() {
  const classes = useStyles();
  const { id }  = useParams();
  const [ data, setData ] = React.useState<any>(null);
  const [ invoiceList, setInvoiceList] = React.useState([])
  const [ observation, setObservation] = React.useState<any>();
  const [ attach, setAttach] = React.useState<any>([])
  const { description, invoice} = useAnticipation();
  const [ value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => setValue(newValue);

  const title = `${FormatCNPJ(data?.issuer?.cnpj)} - ${data?.issuer?.corporate_name} - ${data?.type?.name}`;
  
  function status(value: any){
    if(value?.liquidation_date === undefined) return "";
    if(value?.liquidation_date === null){
      if(moment(formatDate(value?.expected_date)).isSameOrAfter(formatDate(new Date())))
        return "A vencer"
         else 
        return "Vencido"
     }else 
      return "Liquidado"
  }

  const updateData = async () =>{
    await description(id).then(r=>{
      const data = r.data.data[0];
      setData(data)
      setObservation(data.observation)
      setAttach(data.attachment)
    });

    await invoice(id).then(r=>{
      const { data } = r.data;
      if (!r.data.hasError){
        const list = data.map((value: any)=>({
          id: value._id,
          nature: value.nature,
          total: value.total,
          status: status(value)
        }))
        setInvoiceList(list)
      }
    })
  }

  React.useEffect(()=>{
    updateData()
  },[])
  
  return (
  <>
   <AnticipationContext.Provider value={{
     updateData, data,
     observation, setObservation,
     attach, setAttach,
     invoiceList, 
  }}>
  <Container maxWidth={"xl"}>
   <CurrentPageInfo primary="Antecipação" secondary={title} loading={data === null}/>
    <div className={classes.root}>
      <AppBar position="static" className={classes.tabs}>
        <Tabs value={value} onChange={handleChange} aria-label="tab description" centered >
          <Tab label="Informações" {...a11yProps(0)} />
          <Tab label="Anexos"      {...a11yProps(1)} />
          <Tab label="Observações" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TabInformation/>
      </TabPanel>
      <TabPanel value={value} index={1}>
       <TabAttachments/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TabObservation/>
      </TabPanel>
    </div>
   </Container>
  </AnticipationContext.Provider>
 </>    
  );
}

import React from "react";
import * as Icons from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import DefaultModal, {
  DefaultModalActions,
  DefaultModalContent,
} from "../../../../../Components/DefaultModal";
import {
  Box,
  Button,
  CircularProgress,
  DialogContentText,
  Typography,
} from "@material-ui/core"; 
import { useSnackbar } from "notistack";
import { CustomDatePicker } from "../../../../../Components/CustomDatePicker";
import { useInvoice } from "../../../../../Services/options/useInvoice"; 
import { jsonToExcel } from "../../../../../Components/Helper/SaveFileExcel";
import moment from "moment";
import { FormatCNPJ, formatDate, FormatRealNumber, showDate } from "../../../../../Components/Helper/FormatValues";
import { UserContext } from "../../../../../Context/UserContext";
import { ShowDetailList } from "../../../../../Components/ShowDetailList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 500,
      minHeight: 200,
      [theme.breakpoints.up("xs")]: { maxWidth: 480 },
      [theme.breakpoints.up("sm")]: { maxWidth: 550 },
      [theme.breakpoints.up("md")]: { maxWidth: 600 },
      [theme.breakpoints.up("lg")]: { maxWidth: 600 },
      [theme.breakpoints.up("xl")]: { maxWidth: 650 },
    },
    contentText: {
      maxWidth: 500,
      padding: "0.5rem 2rem 3rem 2rem",
    },
    text: {
      fontSize: 16,
      fontWeight: "bold",
      color: theme.palette.text.primary,
      [theme.breakpoints.up("lg")]: { fontSize: 17 },
      marginBottom: "0.5rem",
    },
    subText: {
      fontSize: 14,
      fontWeight: "bold",
      color: theme.palette.text.secondary,
      marginBottom: "1.5rem",
    },
    load: {
      maxWidth: 10,
      maxHeight: 10,
    },
    button: {
      fontFamily: "Montserrat",
      letterSpacing: 1.2,
      fontWeight: "bold",
      fontSize: 12,
      borderRadius: "1rem",
      padding: "0.4rem 1rem",
    },
    containerList: {
      padding: "0.5rem 2rem 2rem 2rem",
      maxHeight: "400px",
      overflowY: "auto",
    }
  })
);

const initialValues = {
  liquidation_date: null,
};

const validationSchema = Yup.object({
  liquidation_date: Yup.date().default(null).required("Obrigatório").nullable(),
});

const ModalPaymentsAcceptCheckeds = ({open, close, selects, updateData}: any) => {
  const classes = useStyles();
  const { userInfo } = React.useContext(UserContext);
  const { changeLiquidationDate } = useInvoice(); 
  const [submitting, setSubmitting] = React.useState(false)
  const [listApprove, setListApprove] = React.useState<any>([])
  const { enqueueSnackbar } = useSnackbar();

  const handleSave=()=>{
    jsonToExcel(listApprove, `Pagamentos efetuados - ${moment().format('DD-MM-YYYY HH-mm-ss')}`);
   }
 
   const handleClose = ()=>{
     if(listApprove.length > 0) updateData()
     close()
   }

   const listItens = listApprove.map((value: any)=>({
    icon: <Icons.Domain />,
    text: "Razão social: ",
    data: value["Razão social"],
}))

  const submit = async (values: any) => {
    const proms = selects
                 .filter((item: any)=> item.status !== 'Efetuado')
                 .map((item: any)=> changeLiquidationDate({
                   invoiceId: item.id, 
                   liquidation_date: formatDate(values.liquidation_date), 
                   updatedBy: {email: userInfo().email}}))
                   
    await Promise.all(proms).then((response: any)=> {
      const data = response.map((value: any) => {
        const {data} = value;
        if(!data.hasError){
          return {
            id: data.data._id, 
            "Operador financeiro": FormatCNPJ(data.data.financial_operator),
            "Data de solicitação": showDate(data.data.createdAt),
            "Data de vencimento": showDate(data.data.expected_date),
            "Data de pagamento": showDate(data.data.liquidation_date), 
            "Status": "Efetuado",
            "Tipo": data.data.type.name,
            "Razão social": data.data.people.corporate_name,
            "CNPJ": FormatCNPJ(data.data.people.cnpj),
            "Valor": FormatRealNumber(data.data.total),
          }
        }
      })  
      setSubmitting(true)
      setListApprove(data);
      if(data.length > 0)
      enqueueSnackbar("Pagamentos efetuados", { variant: "success" }); else
      enqueueSnackbar("Nenhum pagamento foi efetuado", { variant: "info" });
     }).catch((error)=>{
      console.log('error ',error) 
     });

  } 

  const { setFieldValue, handleSubmit, isSubmitting, values, touched, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: submit,
    });

  return (
    <DefaultModal open={open} handleClose={handleClose} title="Confirmar pagamentos">
      <form noValidate onSubmit={handleSubmit}>
        <DefaultModalContent dividers className={classes.root}>
          {listItens.length === 0 ?
           <DialogContentText className={classes.contentText}>
            <Typography className={classes.text} gutterBottom>
              Deseja confirmar o efetuamento dos pagamentos selecionados?
            </Typography>
            <Typography className={classes.subText} gutterBottom>
              informe a data de pagamento:
            </Typography>
            <CustomDatePicker
              variant="standard"
              inputVariant="standard"
              id="liquidation_date"
              name="liquidation_date"
              label="Data de pagamento"
              margin="none"
              onChange={(value: any) => {
                setFieldValue("liquidation_date", value || null);
              }}
              value={values.liquidation_date}
              error={touched.liquidation_date && Boolean(errors.liquidation_date)}
              helperText={touched.liquidation_date && errors.liquidation_date}
            />
           </DialogContentText>:
                  <Box className={classes.containerList}>
                  <ShowDetailList title="Pagamentos efetuados" list={listItens}/>
                 </Box>}
        </DefaultModalContent>
        <DefaultModalActions>
        <Button
        size="small"
        variant="contained"
        color="primary"
        aria-label="open"
        disabled={Boolean(listApprove.length <= 0)}
        className={classes.button}
        style={{ padding: "0.4rem 1.5rem", marginRight: "1rem", borderRadius: "1rem" }}
        startIcon={<Icons.Save  />}
        onClick={handleSave}
        >
        Baixar
       </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            size="small"
            type="submit"
            disabled={isSubmitting || submitting}
            startIcon={isSubmitting && <CircularProgress className={classes.load} />}
          >
            Confirmar
          </Button>
        </DefaultModalActions>
      </form>
    </DefaultModal>
  );
};

export default ModalPaymentsAcceptCheckeds;

import React from 'react'
import * as Icons from "@material-ui/icons";
import { List, ListItem, Typography,  ListItemIcon, Grid, Box} from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DefaultModal, { DefaultModalContent } from '../../../../../../Components/DefaultModal';
import ActionDelete from './ActionDelete';
import ActionEdit from './ActionEdit';
import { InformationContext } from '../../..';
import { formatCEP, formatCPF, FormatPhone } from '../../../../../../Components/Helper/FormatValues';
import { ShowDetailList } from '../../../../../../Components/ShowDetailList';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root:{
   minHeight: "14rem",
  },
  main: {
    [theme.breakpoints.up("xs")]: { minWidth: 380 },
    [theme.breakpoints.up("sm")]: { minWidth: 380 },
    [theme.breakpoints.up("md")]: { minWidth: 450 },  
    [theme.breakpoints.up("lg")]: { minWidth: 550 },
    [theme.breakpoints.up("xl")]: { minWidth: 650 },
  },
  speedDial:{
    transform: 'scale(0.825)',
  },
})
);

export const ActionShow = ({id, open, close}: any) => {
  const classes = useStyles();
  const [openDial, setOpenDial] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [select, setSelect] = React.useState(null);
  const { person } = React.useContext(InformationContext);
  const selectData = person[id]

  const listItens = [
    {
      icon: <Icons.AccountCircle />,
      text: "Nome: ",
      data: selectData?.name,
    },
    {
      icon: <Icons.AccountBox />,
      text: "CPF: ",
      data: selectData?.cpf && formatCPF(selectData?.cpf),
    },
    {
      icon: <Icons.Email />,
      text: "E-mail: ",
      data: selectData?.email,
    },
    {
      icon: <Icons.Phone />,
      text: "Telefone: ",
      data: selectData?.telephone && FormatPhone(selectData?.telephone),
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "Cidade: ",
      data: selectData?.city,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "Bairro: ",
      data: selectData?.district,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "Logradouro: ",
      data: selectData?.address,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "Complemento: ",
      data: selectData?.complement,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "Número: ",
      data: selectData?.number,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "UF: ",
      data: selectData?.state,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "CEP: ",
      data: selectData?.zip_code && formatCEP(selectData?.zip_code),
    },
  ]

  const handleCloseView =()=>{
    setOpenView(false)
    setSelect(null)
  }

  const getSelectContent = React.useCallback((select: any) => { 
    switch (select) {
     case 0: return <ActionEdit   open={openView} close={handleCloseView}/>
     case 1: return <ActionDelete open={openView} close={handleCloseView}/>
     default: return null;
    }
  }, [select]);
  return (
 <>
  {getSelectContent(select)}
  <DefaultModal open={open} handleClose={close} title="Sócio - Pessoa Física" maxWidth="md">
   <DefaultModalContent dividers className={classes.root}>
    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start"className={classes.main}>
     <Grid item xs={12} style={{width:"100%"}}>
      <ShowDetailList list={listItens}/>
     </Grid>  
    </Grid>  
  </DefaultModalContent>
  </DefaultModal>
 </>
 )
 }

export default ActionShow
import React from 'react'
import * as Yup from 'yup';
import * as Icons from "@material-ui/icons";
import { useFormik } from 'formik'; 
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Container, Grid, Typography } from '@material-ui/core'
import CurrentPageInfo from '../../../Components/Helper/CurrentPageInfo'
import Company from './Company';
import LegalPerson from './LegalPerson';
import Person from './Person';
import Bank from './Bank';
import { useCompany } from '../../../Services/options/useCompany';
import Discount from './Discount';
import { filterNumbers } from '../../../Components/Helper/FormatValues';
import { UserContext } from '../../../Context/UserContext';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: "1rem auto",
    maxWidth: "95%",
    //height: "650px",
    display: "grid",
    padding: "1rem"
  },
  main: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "10px",
  },
  accordion:{
    marginBottom: "10px", 
    borderRadius: "15px",
   },
   header: {
     backgroundColor: theme.palette.primary.main,
     borderRadius: "15px 15px 0px 0px",
   },
   titleContent:{
     display: "flex",
     alignContent: "center",
     alignItems: "center",
   },
   avatar:{
     width: theme.spacing(3),
     height: theme.spacing(3),
     color: "white",
     backgroundColor: theme.palette.secondary.main,
   },
   icon:{
     width: theme.spacing(2),
     height: theme.spacing(2),
     color: "white",
   },
   heading: {
     fontSize: 13,
     fontWeight: "bold",
     fontFamily: 'Montserrat',
     color: "white",
     paddingLeft: "1rem",
   },
   accordionDetails:{
     margin: "0px auto",
     alignContent: "center",
     alignItems:"center",
     justifyContent: "center"
   },
   actionContent: {
     width: "100%",
     display: "inline-flex",
     alignItems: "center",
     justifyContent:"flex-end",
     margin: "0.5rem 0rem",
   },
   actionButton:{
     fontFamily: 'Montserrat',
     letterSpacing: 1,
     fontWeight: "bold",
     fontSize: 12,
     borderRadius: "1rem",
     padding: "0.5rem 2rem",
   },
 }),
);
 
const validationSchema = Yup.object({
  corporate_name: Yup.string().required("Obrigatório").nullable(),
  trading_name: Yup.string().required('Obrigatório').nullable(),
  cnpj: Yup.string().required("Obrigatório").nullable(),
  //cnpj: Yup.string().required("Obrigatório").matches(/[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}/, "CNPJ Inválido").nullable(),
  email: Yup.string().email('E-mail inválido').required("Obrigatório").nullable(),
  telephone: Yup.string().required('Obrigatório').nullable(),

   address: Yup.object().shape({
    zip_code  : Yup.string().required('Obrigatório').nullable(),//Yup.string().nullable().matches(/^[\d]{2}[\d]{3}-[\d]{3}/, "CEP Inválido"), //
    state     : Yup.string().required('Obrigatório').nullable(),
    city      : Yup.string().required('Obrigatório').nullable(),
    district  : Yup.string().required('Obrigatório').nullable(),
    address   : Yup.string().required('Obrigatório').nullable(),
    complement: Yup.string().required('Obrigatório').nullable(),
    number    : Yup.string().required('Obrigatório').nullable(),
   }).nullable(),

  bank: Yup.array().min(1, "Cadastre ao menos um banco").required("Obrigatório"), 

  discountParams: Yup.object({
    fixedDayAnticipation: Yup.number().default(null).required("Obrigatório").nullable(),
  }),
});

const Address ={
  state: "",
  zip_code: "",
  city: "",
  district: "",
  address: "",
  complement: "",
  number: "",
 };
 
 const Owner = {
  person: [],
  legalperson: [],
 }

 const defaultdefinedByInvoice = {
  fixedDueDate: null,
  chargeFixed: null,
  chargeMin: null,
  dailyFee: null,
};

const defaultdefinedByTotal = {
  overdueInvoice: false,
  fixedCharge: null,
  dayParams: null,
  monthParams: null,
};

const defaultChargeImported = {
  chargeMin: null,
  interval: [],
};

 const initialValues = {
  corporate_name: "",
  trading_name: "",
  cnpj: "",
  email: "",
  telephone: "",
  address: Address,
  status: null,
  person: [],
  legalPerson: [],
  bank: [],

  attachment: [],

  discountParams: {
    chargeImported: {
      chargeMin: null,
      interval: [],
     },
    definedByInvoice: {
    fixedDueDate: null,
    chargeFixed: null,
    chargeMin: null,
    dailyFee: null,
    },

  definedByTotal: {
    monthOrDay: "day",
    overdueInvoice: false,
    fixedCharge: null,
    dayParams: null,
    monthParams: null,
  },
  partnerPercentage: null,
  fixedDayAnticipation: null,
},
 }

export const InformationContext = React.createContext<any>(null);

const RegisterCompany = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { userInfo } = React.useContext(UserContext);
  const { create } = useCompany()
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = React.useState<any>([true, false, false, false, false]);
 
  const handleChange = (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded((prev: any) => {
      prev[panel] = isExpanded;
      return [...prev];
  });
  };

  function filterId(data: any){
    return data.map((value: any)=>({
      ...value, id: undefined
    }))
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values: any, { setSubmitting }) => {
      const isChargeImported = values.discountParams?.chargeImported?.interval?.length > 0;
      const buffer = {
        corporate_name: values.corporate_name,
        trading_name: values.trading_name,
        cnpj: filterNumbers(values.cnpj),
        email: values.email,
        telephone: filterNumbers(values.telephone),
        address:{
          ...values.address,
          zip_code: filterNumbers(values.address.zip_code),
        },
        owner:{
          person: filterId(values.person),
          legalperson: filterId(values.legalPerson),
        },
        bank: filterId(values.bank),
        attachment: [],
        status: null,
        discountParams: {
          definedByTotal:   !isChargeImported ? {...values.discountParams.definedByTotal, monthOrDay: undefined} : {...values.discountParams.definedByTotal, fixedCharge: null},
          definedByInvoice: !isChargeImported ? values.discountParams.definedByInvoice : {...defaultdefinedByInvoice, fixedDueDate: values.discountParams.definedByInvoice.fixedDueDate },
          chargeImported:       values.discountParams.chargeImported,
          partnerPercentage:    values.discountParams.partnerPercentage,
          fixedDayAnticipation: values.discountParams.fixedDayAnticipation,
        },
       createdBy: {
          email: userInfo().email,
        },
        user: userInfo().id, 
      }
      //console.log('send',JSON.stringify(buffer, null, 2))
      await create(buffer).then(r=>{
        const {data} = r.data;
        enqueueSnackbar("Empresa cadastrada", { variant: "success" });
        navigate(`/company/description/${data?.cnpj}`)
       }).catch(e=>{
        //console.log(e)
        enqueueSnackbar("Erro ao cadastrar empresa", { variant: "error" });
       })
    },
  });

  const listAccord = [
    {name: "Dados da empresa",
     icon: <Icons.Business className={classes.icon}/>,
     item: <Company/>,
     unmountOnExit: true,
    },
    {name: "Sócio - Pessoa Juridica",
     icon: <Icons.PeopleOutline className={classes.icon}/>,
     item: <LegalPerson/>,
     unmountOnExit: true,
    },
    {name: "Sócio - Pessoa Física",
     icon: <Icons.SupervisorAccount className={classes.icon}/>,
     item: <Person/>,
     unmountOnExit: true,
    },
    {name: `Bancos ${(formik.errors.bank && formik.touched.bank) ? `- ${formik.errors.bank}`: ""}`,
    icon: <Icons.AccountBalance className={classes.icon}/>,
    item: <Bank/>,
    unmountOnExit: true,
    },
    {name: "Taxas",
    icon: <Icons.AttachMoney className={classes.icon}/>,
    item: <Discount/>,
    unmountOnExit: true,
    },
  ]; 

  return (
  <InformationContext.Provider value={{formik}}> 
    <Container maxWidth={"xl"}>
      <Grid item xs={12}>
       <CurrentPageInfo primary="Cadastro de empresas" secondary="Nova empresa"/>
      </Grid>
      <Grid container className={classes.main}>
      <Grid item xs={12} className={classes.root}>
      {listAccord.map((value:any, index: number)=>
        (<Accordion TransitionProps={{ unmountOnExit: value.unmountOnExit }} key={value.name} className={classes.accordion} expanded={expanded[index]} onChange={handleChange(index)}>
          <AccordionSummary className={classes.header} expandIcon={<Icons.ExpandMore color="secondary"/>}>
           <Box className={classes.titleContent}>
            <Avatar className={classes.avatar}>{value.icon}</Avatar>
            <Typography className={classes.heading}>{value.name}</Typography>
           </Box>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
           {value.item}
          </AccordionDetails>
        </Accordion>)) 
      }
      <Box className={classes.actionContent}>
       <Button
        className={classes.actionButton}
        variant="contained"
        color="secondary" 
        onClick={()=>formik.submitForm()}
        >
        Confirmar
       </Button>
      </Box>
      </Grid>
     </Grid>
    </Container>
  </InformationContext.Provider>  
  )
}

export default RegisterCompany
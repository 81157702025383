import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import * as Icons from "@material-ui/icons";
import { Box, Button, CircularProgress, Fab, ListItem, ListItemAvatar, ListItemText, Paper, Tooltip } from '@material-ui/core';
import api from '../../Services/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "0rem 1rem 1rem 1rem",
      borderRadius: "10px",
      border: `1px solid  ${theme.palette.divider}`,
      boxShadow: "1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    },
    download:{
     fontFamily: 'Montserrat',
     fontWeight: "bold",
     fontSize: '0.85em',
     borderRadius: "1rem",
     padding: "0.2rem 1rem",
    },
    title:{
      fontFamily: 'Montserrat',
      fontWeight: "bold",
      fontSize: 14,
      color: theme.palette.primary.main,
    },
    message:{
      fontFamily: 'Montserrat',
      fontWeight: "bold",
      fontSize: 16,
      color:theme.palette.primary.main,
    },
    avatar: {
      borderRadius: 0,
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  }),
);

const ItemIcon = ({mimetype, className}: any) =>{
  switch (mimetype) {
   case "application/pdf":
    return (<Avatar alt="pdf" src="/img/files/icon_pdf.png" className={className}/>)
   case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    return (<Avatar alt="xml" src="/img/files/icon_xml.png" className={className}/>)
    case "text/xml":
      return (<Avatar alt="xml" src="/img/files/icon_xml.png" className={className}/>)
   case "image/png":
     return (<Avatar alt="png" src="/img/files/icon_img.png" className={className}/>)  
    default: return (<Avatar alt="file" src="/img/files/icon_file.png" className={className}/>)
  }
}

async function handleOpenAttachment(path: string){
  await api({
  method: "get",
  url: `attachment/${path}`,
  headers: { "Content-Type": "application/json" },
}).then(r=>{
    const { data } = r;
    window.open(data.url);
  });
}

export const ItemCard =({item, onDelete}: any)=>{
  const classes = useStyles();
  const { _id, document_type, path, mimetype } = item;
  return(
    <Box className={classes.root}>
     <ListItem alignItems="flex-start">
      <ListItemAvatar style={{padding: "0.5rem 1rem 0rem 0rem"}}> 
       <ItemIcon mimetype={mimetype} className={classes.avatar} />
      </ListItemAvatar>
       <ListItemText
       primary={
       <Box style={{justifyContent: "space-between", display:"flex"}}>
        <Typography variant="body1"  className={classes.title}>{document_type?.name}</Typography>
        <Tooltip placement="top" title="Apagar" aria-label="Apagar">     
         <Fab size="small" style={{transform: 'scale(0.8)', minWidth:"2.6rem"}} onClick={()=>onDelete(_id)}>
          <Icons.Delete/>
         </Fab>
        </Tooltip>
       </Box>}
       secondary={
        <Box style={{minHeight:"30px",maxHeight:"120px", width:"100%", marginTop: "1.5rem"}}>
          <Button className={classes.download}
                  size="small"
                  variant="contained"
                  color="secondary" 
                  onClick={()=>handleOpenAttachment(path)} 
                  startIcon={<Icons.GetApp/>}
                  > 
                   Baixar 
                  </Button>
        </Box>}
      />
    </ListItem>
    </Box>
  )
}

export const NoItem =({loading}: any)=>{
  const classes = useStyles();
  return(
  <Paper elevation={0} className={classes.root}>
  <ListItem>
   <ListItemAvatar style={{padding: "0.5rem"}}> 
    <Avatar alt="file" src="/img/files/icon_no-data.png" className={classes.avatar}/>
   </ListItemAvatar>
   <ListItemText
    primary={       
    <Typography
      component="span"
      variant="subtitle1"
      style={{ display: "flex", textAlign:"center", justifyContent:"center"}}
      color="textPrimary"
    >
    {loading ? <CircularProgress/>: <Typography className={classes.message}>Sem arquivos !</Typography>}
    </Typography>}/>
   </ListItem>
   </Paper>
   )
}

import React from 'react'
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import MaskedInput from 'react-text-mask';  
import moment from 'moment';


export function formatArrayToObject(arr: any) {
  var obj: any = {};
  for (var i = 0; i < arr.length; ++i){
      obj = Object.assign(obj, arr[i]);
  }
  return obj;
}

export function truncarText(texto: any, limite: any){
  if(texto.length>limite){ 
      limite--;
     let last = texto.substr(limite-1,1);
      while(last !==' ' && limite > 0){
          limite--;
          last = texto.substr(limite-1,1);
      }
      last = texto.substr(limite-2,1);
      if(last === ',' || last === ';'  || last === ':'){
           texto = texto.substr(0,limite-2) + '...';
      } else if(last === '.' || last === '?' || last === '!'){
           texto = texto.substr(0,limite-1);
      } else {
           texto = texto.substr(0,limite-1) + '...';
      }
  }
  return texto;
} 

export function MaskCel(props: any) {
  const { inputRef, mask, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[0-9]/, /\d/,')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      // showMask
    />
  );
}

export function MaskCPF(props: any) {
  const { inputRef, mask, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/,'.', /\d/, /\d/, /\d/,'.', /\d/, /\d/, /\d/,'-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      // showMask
    />
  );
}

export function MaskCNPJ(props: any) { 
  const { inputRef, mask, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}//“00”.”000″.”000″/”0000-00
      mask={[/\d/, /\d/,'.', /\d/, /\d/, /\d/,'.', /\d/, /\d/, /\d/,'/', /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      // showMask
    />
  );
}

export function MaskCEP(props: any) {
  const { inputRef, mask, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}//00000-000
      mask={[/\d/,/\d/,/\d/,/\d/,/\d/,'-', /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      // showMask
    />
  );
}

export function MaskRG(props: any) {
  const { inputRef, mask, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/,'.', /\d/, /\d/, /\d/,'.', /\d/, /\d/, /\d/,'-', /\d/, ]}
      placeholderChar={'\u2000'}
      //showMask
    />
  );
}

export function filterNumbers(value: any){ 
  return value?.replace(/[^0-9]/g,'');
}  

filterNumbers.defaultProps = {
  value: "",
};

export function MaskPhone(props: any) {
    const { inputRef, mask, ...other } = props;
    return (
      <MaskedInput
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[0-9]/, /\d/,')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        {...other}
      />
    );
  }
 
  MaskPhone.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };

export function NumberFormatCustom(props: any) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        //thousandSeparator={true}
        //allowedDecimalSeparators={['.',',']}
        decimalSeparator=","
        thousandSeparator="."
        
        decimalScale={2}
        isNumericString
        prefix="R$ "
      />
    );
  }

  export function NumberFormatPercentage(props: any) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: (Number(values.value) || null) || 0,
            },
          });
        }}
        thousandSeparator
        isNumericString
        suffix="%"
      />
    );
  }

  export function FormatRealNumber(value: any){
    if(!value || value < 0) return "";
    
    const nf = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return nf.format(value);
  }

  export function showDate(value: any, message: string = '-'){
   return value ? moment.utc(value).format('DD/MM/YYYY'):message;
  }

  showDate.defaultProps ={
    //value: new Date(),
    message: "-", 
  }

  export function formatDate(value: any) {
    if (!value) return ''
    
    return moment.utc(value).format("YYYY-MM-DD")
  }

  export function FormatCNPJ(value: string){
    if (!value) return ''

    return value?.replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
  }

  FormatCNPJ.defaultProps = {
    value: "",
  }

  export function formatCPF(value: string){
    if (!value) return ''

    const aux = value?.replace(/[^\d]/g, "");
    return aux.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  formatCPF.defaultProps = {
    value: "",
  }

  export function formatCEP(value: string){
    if (!value) return ''

    const aux = value?.replace(/[^\d]/g, "");
    return aux.replace(/(\d{5})(\d{3})/, "$1-$2");
  }

  formatCEP.defaultProps ={
    value: "",
  }

  export function FormatPhone(value: any){
    if (!value) return ''
    return value?.replace(/\D/g, '').replace(/(\d{2})(\d)(\d{4})(\d{4})$/, '($1) $2 $3-$4');
  }

  FormatPhone.defaultProps = {
   value: "",
  }

 export function phoneMask(v: string) {
   if (!v) return ''

    let r = v?.replace(/\D/g, "");
    r = r.replace(/^0/, "");
  
    if (r.length > 11) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 7) {
      r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else if (v.trim() !== "") {
      r = r.replace(/^(\d*)/, "($1");
    }
    return r;
  }
  
  phoneMask.defaultProps = {
    v: "",
  }

import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Grid, Chip, List, Tooltip, Fab, Typography, Divider } from "@material-ui/core";
import * as Icons from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    minHeight: "150px",
    margin: "0.5rem auto",
  },
  main: {
    padding: "0.5rem 1rem",
    border: `1px solid  ${theme.palette.divider}`,
    borderRadius: "10px",
    boxShadow:
      "1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    alignContent: "flex-start",
    alignItems: "flex-start",
  },
  list: {
    minHeight: "150px",
    maxHeight: "155px",
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
  },
  item:{
    display: "flex", justifyContent: "space-between",
    padding: "2px 4px",
    border: `1px solid  ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor: '#CCC',
      boxShadow: 'none',
    },
  }
}));

const ItemCard = ({item}: any) => {
  const classes = useStyles();
  const {day, charge} = item;
  return(
   <Grid item xs={12} className={classes.item}>
    <Grid item xs={6}>
    {day}
    </Grid>
    <Grid item xs={6}>
     {` ${charge}%`}
    </Grid>
   </Grid>
  );
};

const NewTable = (props: any) => {
  const classes = useStyles();
  const { list, rate, onDelete } = props;
  return (
    <Box className={classes.root}>
      <Grid item xs={12} style={{display: rate ?"flex": "none", justifyContent: "space-between", marginBottom: "0.2rem"}}>
        <Chip label={`Taxa mínima: ${rate}%`} color="primary"/>

       <Tooltip placement="top" title="Remover" aria-label="Remover" style={{transform: 'scale(1)'}}>   
        <Fab size="small" color="primary" aria-label="Remover" onClick={onDelete} style={{transform: 'scale(1)'}}>
         <Icons.DeleteOutline fontSize="small"/>
        </Fab>
       </Tooltip>
      </Grid>
      {list && list.length > 0 && (
     <Grid item xs={12} className={classes.main}>
    
      <Grid item xs={12} style={{display: "flex", justifyContent: "space-between", marginBottom: "0.5rem"}}>
      
       <Grid item xs={6}>
        <Typography color="primary" style={{fontWeight: "bolder"}}>Dia</Typography>
       </Grid>
       <Grid item xs={6}>
        <Typography color="primary" style={{fontWeight: "bolder"}}>Taxa</Typography>
       </Grid>
      </Grid>
    
        <List dense className={classes.list}>
            {list.map((value: any, index: number) => {
              return (
                <ItemCard key={index} item={value} />
              );
            })}
        </List>
       
      </Grid>
      ) }
    </Box>
  );
};

export default NewTable;

import React from 'react';
import api from '../Services/api';

export default function useAxios(){
  const [buffer, setBuffer]   = React.useState(null);
  const [error, setError]     = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const request = React.useCallback(async (options) => {
    setError(false)
    setLoading(true)
  const response = await api(options).then((r)=>{
     setBuffer(r.data)
     return r
   }).catch((e)=>{
     setError(e.message)
     return e
    });
    setLoading(false)
    return response
  }, []);

  return { buffer, loading, error, request };
};
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { AccountCircle, ExitToApp } from "@material-ui/icons";

import {
  Badge,
  IconButton,
  ListItem,
 
  withStyles,
  Divider,
  ListItemText,
  Typography,
  List,
  Avatar,
  Fab,
  ListItemIcon
} from "@material-ui/core";
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { UserContext } from "../../Context/UserContext";
 

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    PaperProps={{
      style: {
        maxHeight: `calc(100vh - 5rem)`,
        minWidth: "20rem",
        maxWidth: "23rem",
        marginTop: "0.25rem",
      },
    }}
    {...props}
  />
));

export function MenuUser() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>  setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const { userLogout, userInfo } = React.useContext(UserContext);
 
  return (
    <div>
      <IconButton
        aria-label="MenuUser"
        id="long-button-MenuUser"
        aria-controls="long-MenuUser"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
   
        style={{color: open ? "#78be21":"#FFFF"}}
        >
        <Badge overlap="rectangular" color="secondary">
         <AccountCircle />
        </Badge>
      </IconButton>

      <StyledMenu
        id="long-MenuUser"
        MenuListProps={{"aria-labelledby": "MenuUser"}}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
      <List dense>
       {/* <ListItem
         button
         alignItems="flex-start"
         style={{borderRadius: 15}}
         >
          <ListItemIcon> 
           <Avatar > 
            <Typography variant="h6" component="span">
             {userInfo && userInfo?.name?.toUpperCase()[0]}
            </Typography> 
           </Avatar>
          </ListItemIcon>
         <ListItemText
          style={{alignItems: "start", textAlign:"start", margin: 0, padding: 0}}
          primary={<Typography style={{fontWeight: "bold"}}>{userInfo?.name}</Typography>}
          secondary={
           <Typography
           component="span"
           variant="body2"
           style={{ display: "inline", width: 100 }}
           color="textPrimary">
            Veja seu perfil
          </Typography>}/>
         
         </ListItem>
         <Divider variant="inset" component="li" style={{marginTop:"0.5rem", marginBottom: "0.5rem"}}/> */}
         <ListItem
          onClick={()=>{
            handleClose()
            userLogout()
          }}
          button
          alignItems="center"
          style={{borderRadius: 15}}
          >
          <ListItemIcon>  
           <Fab size="small" disabled> 
            <ExitToApp/> 
           </Fab>
          </ListItemIcon>
          <ListItemText
           primary={
           <Typography style={{fontWeight: "bold"}}>
            Sair
           </Typography>}/>
          </ListItem>
       </List>
      </StyledMenu>
    </div>
  );
}

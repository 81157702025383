import React from "react";
import { formatCPF } from "../../../../../Components/Helper/FormatValues";
import CustomTable from "../../../../../Components/Table";
import ActionShow from "./Actions/ActionShow";

interface PropsTable {
  data   : any;
  load?  : boolean;
  error? : any;
}
 
export default function Table({data, error, load}:PropsTable) {
  const [rowId, setRowId] = React.useState(null)
  const [open, setOpen] = React.useState(false);

  const handleOpen = (id:any)=>{
    setRowId(id)
    setOpen(true)
  }

  const handleClose = ()=>{
    setRowId(null)
    setOpen(false)
  }

  const options ={
    onRowClick: (rowData:any, rowState:any) => handleOpen(rowState.rowIndex)
  }
  return (
  <> 
  {rowId !== null && 
   <ActionShow id={rowId} open={open} close={handleClose}/>}
   <CustomTable data={data} columns={columns} opt={options} error={error} load={load} elevation={1}/>
  </> 
  );
}

const columns = [
  { name: "id", options: { display: "false" } },
  { name: "name",
    label: "Nome",
    options: { filter: true, sort: true, width: 100, },
  },
  { name: "cpf",
    label: "cpf",
    options: { filter: true, sort: true, 
    customBodyRender: (value:any) => formatCPF(value)
  },},
  { name: "email",
    label: "E-mail",
    options: { filter: true, sort: true, },
},
 
];


import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid, TextField } from '@material-ui/core';
import { MaskCEP, MaskCNPJ, MaskPhone } from '../../../../Components/Helper/FormatValues';
import { InformationContext } from '..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      width: "100%",
      padding: "1rem",
      marginTop: "0.5rem",
    },
    contentAction:{
     display: "inline-flex",
     alignItems: "center",
     float:"right",
     padding:0,
     marginTop: "1rem"
    },
    buttonSave:{
      fontFamily: 'Montserrat',
      letterSpacing: 1.2,
      fontWeight: "bold",
      fontSize: 12,
      borderRadius: "1rem",
      padding: "0.5rem 2rem",
    },
  })
);

const Company = () => {
  const classes = useStyles();
  const { formik } = React.useContext(InformationContext)
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
  } = formik;
  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
    <Grid container className={classes.main} spacing={3}>
     <Grid item xs={6}>
      <TextField
       fullWidth
       autoFocus
       margin="none"
       size="small"
       multiline
       id="corporate_name"
       name="corporate_name"
       label="Razão Social"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.corporate_name}
       error={touched.corporate_name && Boolean(errors.corporate_name)}
       helperText={touched.corporate_name && errors.corporate_name}
      />
     </Grid>
 
     <Grid item xs={6}>
      <TextField
       fullWidth
       multiline
       margin="none"
       size="small"
       id="trading_name"
       name="trading_name"
       label="Nome Fantasia"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.trading_name}
       error={touched.trading_name && Boolean(errors.trading_name)}
       helperText={touched.trading_name && errors.trading_name}
      />
     </Grid>
 
     <Grid item xs={6}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="cnpj"
       name="cnpj"
       label="CNPJ" //MaskCNPJ
       inputProps={{ autoComplete: "off" }}
       InputProps={{ inputComponent: MaskCNPJ }}
       onChange={handleChange}
       value={values.cnpj}
       error={touched.cnpj && Boolean(errors.cnpj)}
       helperText={touched.cnpj && errors.cnpj}
      />
     </Grid>
 
     <Grid item xs={6}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="email"
       name="email"
       label="E-mail"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.email}
       error={touched.email && Boolean(errors.email)}
       helperText={touched.email && errors.email}
      />
     </Grid>
 
     <Grid item xs={4}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="telephone"
       name="telephone"
       label="Telefone"
       inputProps={{ autoComplete: "off" }}
       InputProps={{ inputComponent: MaskPhone,
 
      }}
       onChange={handleChange}
       value={values.telephone}
       error={touched.telephone && Boolean(errors.telephone)}
       helperText={touched.telephone && errors.telephone}
      />
     </Grid>
 
     <Grid item xs={4}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="address.zip_code"
       name="address.zip_code"
       label="CEP"
       inputProps={{ autoComplete: "off" }}
       InputProps={{ inputComponent: MaskCEP }}
       onChange={handleChange}
       value={values.address?.zip_code}
       error={touched.address?.zip_code && Boolean(errors.address?.zip_code)}
       helperText={touched.address?.zip_code && errors.address?.zip_code}
      />
     </Grid>
 
     <Grid item xs={4}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="address.state"
       name="address.state"
       label="UF"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.address?.state}
       error={touched.address?.state && Boolean(errors.address?.state)}
       helperText={touched.address?.state && errors.address?.state}
      />
     </Grid>
 
     <Grid item xs={6}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="address.city"
       name="address.city"
       label="Cidade"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.address?.city}
       error={touched.address?.city && Boolean(errors.address?.city)}
       helperText={touched.address?.city && errors.address?.city}
      />
     </Grid>

     <Grid item xs={6}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="address.district"
       name="address.district"
       label="Distrito"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.address?.district}
       error={touched.address?.district && Boolean(errors.address?.district)}
       helperText={touched.address?.district && errors.address?.district}
      />
     </Grid> 
 
     <Grid item xs={5}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="address.address"
       name="address.address"
       label="Logradouro"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.address?.address}
       error={touched.address?.address && Boolean(errors.address?.address)}
       helperText={touched.address?.address && errors.address?.address}
      />
     </Grid>
 
     <Grid item xs={2}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="address.number"
       name="address.number"
       label="Número"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.address?.number}
       error={touched.address?.number && Boolean(errors.address?.number)}
       helperText={touched.address?.number && errors.address?.number}
      />
     </Grid>
 
     <Grid item xs={5}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="address.complement"
       name="address.complement"
       label="Complemento"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.address?.complement}
       error={touched.address?.complement && Boolean(errors.address?.complement)}
       helperText={touched.address?.complement && errors.address?.complement}
      />
     </Grid>
    </Grid>
   </form>
   )
}

export default Company
import React, { SetStateAction, ReactNode, Dispatch } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme, } from '@material-ui/core/styles';
import DefaultModal, { DefaultModalActions, DefaultModalContent } from '../../../../../../Components/DefaultModal'

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    display: "flex",
    padding: theme.spacing(0, 1, 0, 1),
    [theme.breakpoints.up("xs")]: {width: 350, },
    [theme.breakpoints.up("sm")]: {width: 380, },
    [theme.breakpoints.up("md")]: {width: 400, },
    [theme.breakpoints.up("lg")]: {width: 400, },
    [theme.breakpoints.up("xl")]: {width: 450, },
  },
})
);

const validationSchema = Yup.object().shape({
  bank_name: Yup.string().required('Obrigatório'),
  agency: Yup.string().required('Obrigatório'),
  account_number: Yup.string().required('Obrigatório'),
});

const ActionDelete  = ({open, close}:any) => {
  const submit = async (values: any, { setSubmitting }: any) => {
    const data = {
      id: Date.now(),
      ...values
    }
    //setValueBank((prev: any)=>[...prev,data])
    close()
  }

  return (
   <DefaultModal open={open} handleClose={close} title="Atualizar beneficiário">
    <Formik
      initialValues={{}}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {(props) => <Form {...props} />}
    </Formik>
   </DefaultModal>
  )
}

export default ActionDelete

const Form = (props: any) => {
  const classes = useStyles();
  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    handleChange,
    handleSubmit,
  } = props;
 
 return (
 <form onSubmit={handleSubmit} noValidate>
  <DefaultModalContent dividers>
   <Grid container className={classes.root} spacing={3}> 
   <Grid item xs={12}>
     <TextField
      fullWidth
      autoFocus
      margin="none"
      id="name"
      name="name"
      label="Nome"
      inputProps={{ autoComplete: "off" }}
      onChange={handleChange}
      value={values.name}
      error={touched.name && Boolean(errors.name)}
      helperText={touched.name && errors.name}
      />
    </Grid>

    <Grid item xs={12}>
     <TextField
      fullWidth
      margin="none"
      id="cpf"
      name="cpf"
      label="CPF"
      inputProps={{ autoComplete: "off" }}
      onChange={handleChange}
      value={values.cpf}
      error={touched.cpf && Boolean(errors.cpf)}
      helperText={touched.cpf && errors.cpf}
      />
    </Grid>

    </Grid>
   </DefaultModalContent>
   <DefaultModalActions style={{marginRight: "0.5rem"}}>
    <Button
    style={{ width: 110 }}
    variant="contained"
    color="primary"
    type="submit"
    disabled={!dirty}>
    Confirmar
    </Button>
  </DefaultModalActions>
  </form>
 );
};
import React from 'react';
import { Formik } from "formik";
import * as Icons from "@material-ui/icons";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Button, Grid, TextField } from '@material-ui/core';
import { SelectApi } from '../../Components/SelectApi';
import { useGeneric } from '../../Services/options/useGeneric';
import { CustomDatePicker } from '../../Components/CustomDatePicker';
import { formatDate } from '../../Components/Helper/FormatValues';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
     width: "100%",
    },
    actionContent:{
      display: "flex", 
      justifyContent:"flex-start",
      [theme.breakpoints.up("lg")]: { justifyContent:"flex-end" },
    },
    button:{
      fontFamily: 'Montserrat',
      letterSpacing: 1.2,
      fontWeight: "bold",
      fontSize: 12,
      borderRadius: "1rem",
      [theme.breakpoints.up("xs")]: { padding: "0.4rem 1rem",  fontSize: 10 },
      [theme.breakpoints.up("xl")]: { padding: "0.5rem 2rem",  fontSize: 12 },
    },
  })
);

const DefaultValue = {
  issuerName:  "",
  document_id: "",
  situationId: null,
  anticipation_date: null,
}

export const Filter =({setSearch}: any)=>{

 const submit = (values: any) => {
  const {issuerName, document_id, situationId, anticipation_date} = values;
  const buffer = [];
  if(issuerName  !== "")         buffer.push({issuerName});
  //if(document_id !== "")       buffer.push({document_id});
  if(document_id  !== "")        buffer.push({document_id: document_id.replace(/[^0-9]/g, ' ')});
  if(situationId !== null)       buffer.push({situationId: situationId.id}); 
  if(anticipation_date !== null) buffer.push({anticipation_date: formatDate(anticipation_date)}); 
  setSearch({data: buffer})
 }

return(
 <Formik
  initialValues={DefaultValue}
  onSubmit={submit}
 >
  {(props) => <Form {...props} />}
 </Formik>
)
}

const Form = (props: any) =>{
  const classes = useStyles();
  const { listByType } = useGeneric();
  const [listTypes, setListTypes] = React.useState<any>()
  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = props;

  const requestApi = React.useCallback(async()=>{
    if(listTypes) return listTypes;
    const response = await listByType("5");//5 anticipation
    const { data } = response.data;
    if(!data.hasError){
      const list = data.filter((value:any)=> {
       const id = Number(value.id);
       return( id === 1 || id === 3 || id === 4)
      });
      setListTypes(list)
      return list
     }else{
      return []
     }
  },[listTypes])

 return(
  <form onSubmit={handleSubmit} noValidate>
   <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={2} className={classes.root}>
    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
     <TextField
       fullWidth
       margin="none"
       size="small"
       id="issuerName"
       name="issuerName"
       label="Emissor"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       value={values.issuerName}
     />
    </Grid>

    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
     <TextField
       fullWidth
       //multiline
       //maxRows={5}
       margin="none"
       size="small"
       id="document_id"
       name="document_id"
       label="Número (Separe os números por ; )"
       inputProps={{ autoComplete: "off" }} 
       onChange={handleChange}
       value={values.document_id}
     />
    </Grid>

    <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
    <CustomDatePicker
       fullWidth
       variant="standard"
       inputVariant="standard"
       id="anticipation_date"
       name="anticipation_date"
       label="Data de antecipação"
       margin="none"
       onChange={(value: any) =>{
       setFieldValue("anticipation_date",  value || null)}}
       value={values.anticipation_date}
      />
      </Grid>

    <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
     <SelectApi
      id="situationId"
      name="situationId"
      onChange={(event: any, value:any) => setFieldValue("situationId", value || null)}
      config={requestApi}
      getOptionSelected={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option: any) => option.name}
      value={values.situationId}
      textFieldProps={{
       fullWidth: true,
       size: "small",
       margin: "none",
       label: "Status",}}
     />
    </Grid>

    <Grid item xs={12} sm={6} md={4} lg={2} xl={2} className={classes.actionContent}>
     <Button className={classes.button} 
      //disabled={!dirty}
      variant="contained" 
      color="primary"
      type="submit"> 
      Pesquisar
      <Icons.Search style={{marginLeft: "1.2rem"}}/>
     </Button>
    </Grid>
 
   </Grid>
  </form>
 )
}
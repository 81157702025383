import React from 'react'
import { Formik } from "formik";
import * as Yup from "yup";
import * as Icons from "@material-ui/icons";

import {
  Box,
  Button,
  Chip,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from '@material-ui/core/styles';
 
import { useSnackbar } from 'notistack';
import DefaultModal, { DefaultModalActions, DefaultModalContent } from '../../../../../Components/DefaultModal';
import { InformationContext } from '../..';
import { SelectApi } from '../../../../../Components/SelectApi';
import { Autocomplete } from '@material-ui/lab';
import { useDropzone } from 'react-dropzone'
import { useCompany } from '../../../../../Services/options/useCompany';
import { useGeneric } from '../../../../../Services/options/useGeneric';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "0rem 1rem",
    [theme.breakpoints.between("xs","sm")]: { width: 400 },
    [theme.breakpoints.between("sm","md")]: { width: 400 },
    [theme.breakpoints.between("md","lg")]: { width: 450 },
    [theme.breakpoints.between("lg","xl")]: { width: 500 },
    [theme.breakpoints.up("xl")]: { width: 550 },
  },
  load:{
    maxWidth: 10, 
    maxHeight: 10,
  },
  buttonSave:{
    fontFamily: 'Montserrat',
    letterSpacing: 1.2,
    fontWeight: "bold",
    fontSize: '0.75em',
    borderRadius: "1rem",
    padding: "0.5rem 1rem",
  },
  dropContainer:{
    minHeight: "100px",
    border: `1px solid  ${theme.palette.divider}`,
    borderRadius: "1rem",
    boxShadow: "1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    alignContent: "flex-start",
    alignItems  : "flex-start",
    marginBottom: "0.5rem",
    cursor: "pointer",
  },
  dropText:{
    color: theme.palette.primary.main,
    fontFamily: 'Montserrat',
    
    fontWeight: "bold",
    fontSize: '1.1em',
    textAlign: "center",
    padding: "0.5rem 0rem",
  },
  dropIcon:{
    width: theme.spacing(6),
    height: theme.spacing(6),
    color: theme.palette.secondary.main,
  },
  itemContainer:{
    padding: "1rem",
  },
  chipError:{
    textAlign: "center",
    fontSize: '0.7em',
    backgroundColor: "red",
    color: "#FFFFFF",
    fontFamily: 'Montserrat',
    fontWeight: "bold",
  }
}));

const validationSchema = Yup.object().shape({
  documentType: Yup.object().nullable().required("Obrigatório"),
  file: Yup.array().min(1, "Nenhum arquivo selecionado").required("Nenhum arquivo selecionado"),

  ownerFilePerson: Yup.object()
   .when("documentType.id", {
    is: "8",
    then: Yup.object().nullable().required("Obrigatório"),
    otherwise: Yup.object().nullable().optional(),
  }),

  ownerFileLegalPerson: Yup.object()
  .when("documentType.id", {
   is: "7",
   then: Yup.object().nullable().required("Obrigatório"),
   otherwise: Yup.object().nullable().optional(),
 }),
});

const defaultValues ={
  documentType: null,
  ownerFilePerson: null,
  ownerFileLegalPerson: null, 
  file: [],
}

const ModalUpload = ({open, close}: any) => {
  const { upload } = useCompany()
  const { person, legalPerson, companyData, updateData } = React.useContext(InformationContext)
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (values: any, { setSubmitting }: any) => {
    const dataCompany = JSON.stringify({_id: companyData._id, }, null, 2)
    const doc = JSON.stringify({
     id: values.documentType.id,
     name: values.documentType.name,
     type: values.documentType.type.name,
    }, null, 2);

    const formData = new FormData();
    formData.append("file", values.file[0]); //o arquivo
    formData.append("filter", dataCompany);
    formData.append("document_type", doc);
    formData.append("folder_name", "COMPANY");

    const documentType = Number(values.documentType.id);
  
    if(documentType >= 1 && documentType <= 4){
      formData.append("owner_file", JSON.stringify({_id: companyData._id, name: "company"}, null, 2));
    }else if(documentType === 8){
      formData.append("owner_file", JSON.stringify({_id: values.ownerFilePerson?._id, name: "owner.person"}, null, 2));
    }else if(documentType === 7){
      formData.append("owner_file", JSON.stringify({_id: values.ownerFileLegalPerson?._id, name: "owner.legalPerson"}, null, 2));
    }
    
   await upload(formData).then(r=>{
     const { data } = r;
     if(!data.hasError){
      enqueueSnackbar("Arquivo enviado", { variant: "success" });
      updateData()
      close()
     }else{
      enqueueSnackbar("Erro", { variant: "error" });
     }
    })
    setSubmitting(false)
  }

 return (
  <DefaultModal open={open} handleClose={close} title="Novo arquivo">
     <Formik
      initialValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {(props) => <Form {...props} person={person} legalPerson={legalPerson} />}
    </Formik>
  </DefaultModal>  
 )
}

const Form = (props: any) => {
  const { listByType } = useGeneric();
  const [listTypes, setListTypes] = React.useState<any>()
  const classes = useStyles();
  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    handleChange,
    setFieldValue,
    handleSubmit,
    person,
    legalPerson,
  } = props;

  const onDrop = React.useCallback((acceptedFiles: any) => {
    setFieldValue('file', acceptedFiles)
  }, []);
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({    
    onDrop,
    maxFiles:1,
    accept: { }
  });

  const handleDelete =()=>{
    setFieldValue('file', [])
  }
  
  const files = values.file.map((file: any, i: any) => (
     <Chip
        key={file.path}
        icon={<Icons.Description />}
        label={file.path}
        color="primary"
        onDelete={handleDelete}
      />
  ));

  const requestApi = React.useCallback(async()=>{
    if(listTypes) return listTypes;
    const response = await listByType("4");// 4 typo de documento
    const { data } = response;
    if(!data.hasError){
      const list = data.data.filter((value:any)=> { 
        const type = Number(value.id);
        return (//tipos p/ empresa
          (type >= 1 && type <= 4) || type === 7 || type === 8
        ) 
      }
    );
      setListTypes(list)
      return list
     }else{
      return []
     }
  },[listTypes])
 
 return (
  <form onSubmit={handleSubmit} noValidate>
  <DefaultModalContent dividers>
   <Grid container className={classes.root} spacing={1}> 
    <Grid item xs={12} >  
     <Box {...getRootProps({className: 'dropzone'})}>
      <Box className={classes.dropContainer} >
        <input {...getInputProps()} />
        <Box style={{textAlign: "center",}}> 
         <Typography className={classes.dropText} >Selecione o arquivo </Typography>
         <Icons.CloudUpload className={classes.dropIcon}/> 
        </Box>
        <Box className={classes.itemContainer} style={{textAlign: "center"}}>
         {(touched.file && errors.file ) && 
         <Chip icon={<Icons.ErrorOutline style={{color: "#FFFF"}}/>} 
          label={errors.file} 
          className={classes.chipError} 
          />} 
         {files} 
        </Box>
      </Box>
     </Box>
    </Grid>

    <Grid item xs={12}>
     <SelectApi
      id="documentType"
      name="documentType"
      onChange={(event: any, value:any) => setFieldValue("documentType", value || null)}
      config={requestApi}
      getOptionSelected={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option: any) => option.name}
      value={values.documentType}
      textFieldProps={{
       fullWidth: true,
       size: "small",
       margin: "normal",
       label: "Tipo de documento",}}
       error={touched.documentType && Boolean(errors.documentType)}
       helperText={touched.documentType && errors.documentType}
    />
    </Grid>

    <Grid item xs={12}>
     <Autocomplete
      style={{ display: values.documentType?.id !== "8" ?"none":"flex"}}
      //disabled={values.documentType?.id !== "5"}
      id="ownerFilePerson"
      value={values.ownerFilePerson}
      onChange={(event: any, value:any) => setFieldValue("ownerFilePerson", value || null)}
      options={person? person: []}
      getOptionSelected={(option, value) => option._id === value._id }
      getOptionLabel={(option) => option.name}
      noOptionsText="Sem opções"
      renderInput={(params) => 
      <TextField {...params} 
      label="Sócio - Pessoa Física" 
      //variant="outlined" 
      size="small"
      margin="normal"
      error={touched.ownerFilePerson && Boolean(errors.ownerFilePerson)}
      helperText={touched.ownerFilePerson && errors.ownerFilePerson}
      />}
     /> 
    </Grid>

    <Grid item xs={12}>
    <Autocomplete
      style={{ display: values.documentType?.id !== "7" ?"none":"flex"}}
      //disabled={values.documentType?.id !== "7"}
      id="ownerFileLegalPerson"
      value={values.ownerFileLegalPerson}
      onChange={(event: any, value:any) => setFieldValue("ownerFileLegalPerson", value || null)}
      options={legalPerson? legalPerson: []}
      getOptionSelected={(option, value) => option._id === value._id }
      getOptionLabel={(option) => option.company_name}
      noOptionsText="Sem opções"
      renderInput={(params) => 
      <TextField {...params} 
      label="Sócio - Pessoa Jurídica" 
      //variant="outlined" 
      size="small"
      margin="normal"
      error={touched.ownerFileLegalPerson && Boolean(errors.ownerFileLegalPerson)}
      helperText={touched.ownerFileLegalPerson && errors.ownerFileLegalPerson}
      />}
     /> 
 
    </Grid>

    </Grid>
   </DefaultModalContent>
   <DefaultModalActions style={{marginRight: "0.5rem"}}>
    <Button
    className={classes.buttonSave}
    variant="contained"
    color="secondary"
    type="submit"
    disabled={!dirty || isSubmitting}>
    Confirmar
    </Button>
  </DefaultModalActions>
  </form>
 );
};
export default ModalUpload
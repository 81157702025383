import React from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles, createStyles, Theme} from "@material-ui/core/styles";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from '../../../../../Components/DefaultModal'
import { Button, CircularProgress, DialogContentText, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useAnticipation } from '../../../../../Services/options/useAnticipation';
import { UserContext } from '../../../../../Context/UserContext';
import { SelectCustom } from '../../../../../Components/SelectsCustom';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    [theme.breakpoints.up("xs")]: { width: 380 },
    [theme.breakpoints.up("sm")]: { width: 450 },
    [theme.breakpoints.up("md")]: { width: 500 },
    [theme.breakpoints.up("lg")]: { width: 500 },
    [theme.breakpoints.up("xl")]: { width: 550 },
  },
  contentText:{
    padding: "2rem",
    display: "flex",
    flexDirection: 'column',
    [theme.breakpoints.up("lg")]: { padding: "3rem" },
  },
  notice:{
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.primary,
    [theme.breakpoints.up("lg")]: { fontSize: 18 },
  },
  currentOperator:{
    fontSize: 16, 
    color: theme.palette.text.primary, 
  },
  load:{
    maxWidth: 10, 
    maxHeight: 10,
  },
  button:{
    fontFamily: 'Montserrat',
    letterSpacing: 1.2,
    fontWeight: "bold",
    fontSize: 12,
    borderRadius: "1rem",
    padding: "0.4rem 1rem",
  },
})
);

const financialOperatorList = [
  { id: "49275773000170", name: "FUNDO DE INVESTIMENTO EM DIREITOS CREDITÓRIOS CONTBANK" },
  { id: "37053876000106", name: "KIKAI FOMENTO MERCANTIL LTDA - 37.053.876/0001-06" },
  { id: "43978697000182", name: "KIKAI SOCIEDADE DE CRÉDITO DIRETO S.A - 43.978.697/0001-82" },
  { id: "32852263000115", name: "GASOLA SERVICOS DE INTERNET EIRELI - 32.852.263/0001-15" },
]

const initialValues = {
  financial_operator: null,
};

const validationSchema = Yup.object({
  financial_operator: Yup.object().nullable(),
});

const ModalAccept = ({open, close, id, financial_operator, updateData}: any) => {
  const classes = useStyles();
  const { userInfo } = React.useContext(UserContext);
  const { approve , loading } = useAnticipation();
  const { enqueueSnackbar } = useSnackbar();
  const currentOperator = financialOperatorList.filter(value=> value.id === financial_operator).map(value=> value.name);

  const submit = async (values: any) => {
   const buffer = {
     _id: id,
     updatedBy: { email: userInfo().email },
     financial_operator: financial_operator ? financial_operator : values?.financial_operator?.id,
   }
   await approve(buffer).then(r=> {
    const { data } = r;
    if (!data.hasError){
      updateData()
      enqueueSnackbar("Aprovado", { variant: "success" });
      close()
    }else{
      enqueueSnackbar("Erro", { variant: "error" });
      close()}
    }).catch(e=>{
      enqueueSnackbar("Erro", { variant: "error" });
      close()
    }) 
  
  }

  const { setFieldValue, handleSubmit, isSubmitting, values, touched, errors } =
  useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: submit,
  });

  return (
    <DefaultModal open={open} handleClose={close} title="Aprovar">
     <form noValidate onSubmit={handleSubmit}>
     <DefaultModalContent dividers className={classes.root}>
      <DialogContentText className={classes.contentText}>
        <Typography component="span" className={classes.notice} gutterBottom>
          Deseja realmente aprovar?
        </Typography>
         {!financial_operator ? <>
          <SelectCustom
            id="financial_operator"
            name="financial_operator"
            onChange={(e, value) => {
              setFieldValue("financial_operator", value || null);
            }}
            value={values.financial_operator}
            options={financialOperatorList}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "standard",
              label: "Operador financeiro",
            }}
          />
          <Typography style={{color: "red", fontSize: 12, fontWeight: "bold"}}>
           {(touched.financial_operator && Boolean(errors.financial_operator)) && errors.financial_operator }
          </Typography>
         </>:
         <>
         <Typography component="span" className={classes.currentOperator} gutterBottom>
         {`Operador financeiro: ${currentOperator}`}
          
         </Typography>
         </>}
      </DialogContentText>
     </DefaultModalContent>
     <DefaultModalActions>
      <Button
       className={classes.button}
       variant="contained"
       color="secondary"
       size="small"
       type="submit"
       disabled={loading || isSubmitting}
       startIcon={loading && <CircularProgress className={classes.load}/>}
       >
       Confirmar
      </Button>
     </DefaultModalActions>
     </form>
    </DefaultModal>
  )
}
 
export default ModalAccept
import React from 'react'
import { makeStyles, createStyles, Theme} from "@material-ui/core/styles";
import DefaultModal, { DefaultModalContent } from '../../../../../Components/DefaultModal'
import { DialogContentText, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    [theme.breakpoints.up("xs")]: { maxWidth: 380 },
    [theme.breakpoints.up("sm")]: { maxWidth: 450 },
    [theme.breakpoints.up("md")]: { maxWidth: 500 },
    [theme.breakpoints.up("lg")]: { maxWidth: 500 },
    [theme.breakpoints.up("xl")]: { maxWidth: 550 },
  },
  contentText:{
    padding: "0.5rem 2rem 3.5rem 2rem",
  },
  notice:{
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.primary,
    [theme.breakpoints.up("xl")]: { fontSize: 18 },
  },
})
);

const ModalInvalidInvoice = ({open, close}: any) => {
  const classes = useStyles();

  return (
    <DefaultModal open={open} handleClose={close} title="Falha na autenticidade do registro">
     <DefaultModalContent dividers className={classes.root}>
      <DialogContentText className={classes.contentText}>
        <Typography className={classes.notice} gutterBottom>
         Foi encontrada uma divergência no pagamento, por favor entre em contato com a equipe de desenvolvimento.
        </Typography>
      </DialogContentText>
     </DefaultModalContent>
    </DefaultModal>
  )
}
 
export default ModalInvalidInvoice
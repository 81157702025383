import React from 'react';
import { Box, Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import {
  FormatCNPJ,
  showDate,
  FormatRealNumber,
} from '../../Components/Helper/FormatValues';
import CustomTable from '../../Components/Table';
import ModalAccept from './Description/TabInformation/Modal/Accept';
import ModalAcceptCheckeds from './Description/TabInformation/Modal/AcceptCheckeds';
import { jsonToExcel } from '../../Components/Helper/SaveFileExcel';
import moment from 'moment';
import ModalChangeExpectedDate from './Description/TabInformation/Modal/Change';
import ModalChangeCheckeds from './Description/TabInformation/Modal/ChangeCheckeds';

interface PropsTable {
  data: any;
  load: boolean;
  error: any;
  updateData: any;
}

export default function ReadTable({
  data,
  error,
  load,
  updateData,
}: PropsTable) {
  const [openAccept, setOpenAccept] = React.useState({
    id: '',
    type: null,
    open: false,
  });
  const options = {
    selectableRows: 'multiple',
    selectableRowsHideCheckboxes: false,
    selectableRowsHeader: true,
    selectToolbarPlacement: 'replace',
    elevation: 0,
    customToolbarSelect: (
      selectedRows: any,
      displayData: any,
      setSelectedRows: any
    ) => (
      <CustomToolbarSelect
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
        updateData={updateData}
      />
    ),
  };

  const handleOpen = (id: string, type: any, status: boolean) => {
    if (status) return;
    setOpenAccept({ id, type, open: true });
  };

  const handleClose = () => {
    setOpenAccept({ id: '', type: null, open: false });
  };

  const actions = ({ tableMeta }: any) => {
    const rowId = tableMeta.rowData[0];
    const rowStatus = Boolean(tableMeta.rowData[7] === 'Efetuado');
    return (
      <Grid
        container
        spacing={1}
        direction="row"
        wrap="nowrap"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Tooltip
            placement="top"
            title="Alterar data de vencimento"
            aria-label="view"
          >
            <span>
              <IconButton
                color="primary"
                aria-label="open"
                style={{ padding: 0 }}
                onClick={() => handleOpen(rowId, 1, false)}
              >
                <Icons.EventBusy />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Tooltip
            placement="top"
            title="Confirmar recebimento"
            aria-label="view"
          >
            <span>
              <IconButton
                color="secondary"
                aria-label="open"
                style={
                  rowStatus ? { padding: 0 } : { padding: 0, color: '#B6B6B6' }
                }
                onClick={() => handleOpen(rowId, 0, rowStatus)}
              >
                <Icons.CheckCircle />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Tooltip placement="top" title="Visualizar" aria-label="view">
            <IconButton
              size="small"
              color="primary"
              aria-label="open"
              style={{ padding: 0 }}
              onClick={() =>
                window.open(`/receipts/description/${rowId}`, '_blank')
              }
            >
              <Icons.Description />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const columns = [
    { name: 'id', label: 'Id', options: { display: 'false' } },
    {
      name: 'action',
      label: 'Ação',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
          actions({ tableMeta }),
      },
    },
    { name: 'document_id', label: 'Número', options: { display: 'true' } },
    { name: 'financial_operator', label: 'Operador financeiro', options: { filter: true, sort: true, 
      customBodyRender: (value:any) => FormatCNPJ(value) },
   },
    {
      name: 'createdAt',
      label: 'Data de solicitação',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any) => showDate(value, '-'),
      },
    },
    {
      name: 'expected_date',
      label: 'Data de vencimento',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any) => showDate(value, '-'),
      },
    },
    {
      name: 'liquidation_date',
      label: 'Data efetiva de recebimento',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any) => showDate(value, '-'),
      },
    },

    { name: 'status', label: 'Status', options: { filter: true, sort: true } },

    { name: 'type', label: 'Tipo', options: { filter: true, sort: true } },

    {
      name: 'corporate_name',
      label: 'Razão social',
      options: { filter: true, sort: true },
    },

    {
      name: 'cnpj',
      label: 'CNPJ',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any) => FormatCNPJ(value),
      },
    },

    {
      name: 'value',
      label: 'Valor',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any) => FormatRealNumber(value),
      },
    },
  ];

  const getSelectContent = React.useCallback(
    (option: any) => {
      switch (option) {
        case 0:
          return (
            <ModalAccept
              open={openAccept.open}
              close={handleClose}
              id={openAccept.id}
              updateData={updateData}
            />
          );
          case 1:
          return (
            <ModalChangeExpectedDate
              open={openAccept.open}
              close={handleClose}
              id={openAccept.id}
              updateData={updateData}
            />
          );

        default:
          return null;
      }
    },
    [openAccept]
  );

  return (
    <>
    {getSelectContent(openAccept.type)}
 
      <CustomTable
        data={data}
        columns={columns}
        opt={options}
        error={error}
        load={load}
        elevation={0}
      />
    </>
  );
}

const CustomToolbarSelect = ({
  selectedRows,
  displayData,
  updateData,
}: any) => {
  const [modalAccept, setModalAccept] = React.useState({
    open: false,
    type: null,
    ids: [],
  });

  const handleAcceptCheckeds = (type: any) => {
    const aux = selectedRows.data.map(({ index }: any) => ({
      id: displayData[index].data[0],
      status: displayData[index].data[7],
    }));
    setModalAccept({ open: true, type: type, ids: aux });
  };

  const handleCloseModalCheckeds = () => {
    setModalAccept({ open: false, type: null, ids: [] });
  };

  const handleFormat = () => {
    const aux = selectedRows.data.map(({ index }: any) => ({
      id: displayData[index].data[0],
      'Número': displayData[index].data[2],
      'Operador financeiro': displayData[index].data[3],
      'Data de solicitação': displayData[index].data[4],
      'Data de vencimento': displayData[index].data[5],
      'Data de recebimento': displayData[index].data[6],
      Status: displayData[index].data[7],
      Tipo: displayData[index].data[8],
      'Razão social': displayData[index].data[9],
      CNPJ: displayData[index].data[10],
      Valor: displayData[index].data[11],
    }));
 
    jsonToExcel(aux, `Recebimentos - ${moment().format('DD-MM-YYYY HH-mm-ss')}`);
  };

  const getSelectContent = React.useCallback(
    (option: any) => {
      switch (option) {
        case 0:
          return (
            <ModalAcceptCheckeds
            open={modalAccept.open}
            close={handleCloseModalCheckeds}
            selects={modalAccept.ids}
            updateData={updateData}
          />
          );
          case 1:
          return (
            <ModalChangeCheckeds
            open={modalAccept.open}
            close={handleCloseModalCheckeds}
            selects={modalAccept.ids}
            updateData={updateData}
          />
          );

        default:
          return null;
      }
    },
    [modalAccept]
  );

  return (
    <Box 
      style={{
        gap: '2rem',
        display: 'flex',
        padding: "0rem 1rem",
        justifyContent: "start",
        width: "88%", 
      }}
    >
      {getSelectContent(modalAccept.type)}
      <Box>
        <Tooltip
          placement="top"
          title="Confirmar recebimento"
          aria-label="view"
        >
          <span>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              aria-label="open"
              style={{ padding: '0.5rem 1rem', borderRadius: '1rem' }}
              startIcon={<Icons.CheckCircle />}
              onClick={()=>handleAcceptCheckeds(0)}
            >
              Confirmar recebimento
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip
          placement="top"
          title="Alterar data de recebimento"
          aria-label="view"
        >
          <span>
            <Button
              size="small"
              variant="contained"
              color="primary"
              aria-label="open"
              style={{ padding: '0.5rem 1rem', borderRadius: '1rem' }}
              startIcon={<Icons.EventBusy />}
              onClick={()=>handleAcceptCheckeds(1)}
            >
              Alterar data de recebimento
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip placement="top" title="Visualizar" aria-label="view">
          <span>
            <Button
              size="small"
              variant="contained"
              color="primary"
              aria-label="open"
              style={{ padding: '0.5rem 1rem', borderRadius: '1rem' }}
              startIcon={<Icons.Save />}
              onClick={handleFormat}
            >
              Baixar
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};

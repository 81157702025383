import React, {ReactNode, SetStateAction} from 'react';
import { Link, useLocation } from "react-router-dom";
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Badge, Box, Icon, Typography } from '@material-ui/core';
import { menuHome } from './menus';
import { MenuUser } from './MenuUser';
import { Notifications } from "@material-ui/icons";
import { SnackbarProvider } from 'notistack';
 
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      padding: theme.spacing(0, 2, 0, 2),
      height: 56
     // ...theme.mixins.toolbar,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,

    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      paddingTop: theme.spacing(3),
    },
    selectedPage: {
      boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
      borderRadius: "5px",
      borderLeft: `5px solid ${theme.palette.secondary.main}`,
    },
    page: {
      borderLeft: "5px solid #0000",
    },
    environmentType: {
      fontSize: "18px",
      fontWeight: "bold",
      textAlign: "center",
      fontFamily: 'Montserrat',
    },
  }),
);

interface ComponentProps {
  children: ReactNode;
}

interface PropsMenu {
  name : string;
  to   : string;
  icon : JSX.Element;
}

interface LayoutContextInterface {
  listMenu: PropsMenu[];
  setListMenu: React.Dispatch<SetStateAction<PropsMenu[]>>;
  showLayout: boolean;
  setShowLayout: React.Dispatch<SetStateAction<boolean>>; 
}

export const LayoutContextDefaultValue: LayoutContextInterface = {
  listMenu: [],
  setListMenu: () => null,
  showLayout: true,
  setShowLayout:() => null,
}

export const LayoutContext = React.createContext<LayoutContextInterface>(LayoutContextDefaultValue);

export default function Layout({children}: ComponentProps) {
  const classes = useStyles();
  const [listMenu, setListMenu] = React.useState<PropsMenu[]>(menuHome);
  const [showLayout, setShowLayout] = React.useState<boolean>(true);

  const location = useLocation();
  const actualLocation = `/${location.pathname.split("/")[1]}`;
  const isSelected = (route: string) => actualLocation === route;
 
  return (
    <LayoutContext.Provider 
     value={{listMenu, setListMenu,
             showLayout, setShowLayout,
             }}>
    <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} TransitionComponent={Collapse} maxSnack={3}>         
    {showLayout ?           
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar} variant="dense" >
        <Box style={{display: "flex", width: "100%", alignContent: "center", alignItems: "center", justifyContent: "space-between"}}>
         <Box style={{marginRight: '2rem'}}>
          <img src='/img/logo/logo_kikai.png' alt='' style={{width: 100, height: "80%"}} />
          
         </Box>
         {/* <Typography className={classes.environmentType} component="span" >{process.env.REACT_APP_BACKOFFICE_API === "https://api.backoffice-dev.kikaipay.com.br/api/v1/" ? "Ambiente de teste":"Ambiente de produção"}</Typography> */}
         <Box style={{display:"flex", alignContent: "center", alignItems: "center"}}>
         {/* <Badge overlap="rectangular" badgeContent={4} color="secondary" style={{marginRight: "1.5rem"}}>
          <Notifications />
         </Badge> */}
           <MenuUser/>
         </Box>

        </Box>  
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer} 
        style={listMenu.length <= 0 ?{width: 0}:{width: 240}}
        variant="persistent"
        open={listMenu.length > 0}
        classes={{ paper: classes.drawerPaper}}
      >
        <Toolbar/>
        <div className={classes.drawerContainer}>
          <List>
          {listMenu.map((menuValue: PropsMenu) => (
          <Link id={menuValue.to} key={menuValue.to} to={menuValue.to}  >
           <ListItem style={{ paddingBottom:"1rem", paddingTop:'1rem'}} button
            className={
            isSelected(menuValue.to)
                    ? classes.selectedPage
                    : classes.page}>
           <ListItemIcon >
           <Icon color={isSelected(menuValue.to) ?"primary":"inherit"} >
             {menuValue.icon}
            </Icon>
            </ListItemIcon>
            <ListItemText primary={
              <Typography variant="h6">{menuValue.name}</Typography>}/>
            </ListItem>
            </Link>
         ))}
          </List>
          
        </div>
        <Typography className={classes.environmentType} style={{position: 'absolute', bottom: '0', marginLeft: "16px", marginBottom: "16px"}} color="secondary" component="span" >{process.env.REACT_APP_BACKOFFICE_API === "https://api.backoffice-dev.kikaipay.com.br/api/v1/" ? "Ambiente de teste":"Ambiente de produção"}</Typography>
      </Drawer>
      <main className={classes.content}>
      <Toolbar className={classes.toolbar} variant="dense" />
       {children} 
      </main>
    </div>:
    <>
    {children}
    </>}
    </SnackbarProvider>  
    </LayoutContext.Provider>
  );
}

import React from "react";
import * as Icons from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import DefaultModal, {
  DefaultModalActions,
  DefaultModalContent,
} from "../../../../../Components/DefaultModal";

import {
  Box,
  Button,
  CircularProgress,
  DialogContentText,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useInvoice } from "../../../../../Services/options/useInvoice";
import { CustomDatePicker } from "../../../../../Components/CustomDatePicker";
import { UserContext } from "../../../../../Context/UserContext";
import moment from "moment";
import { jsonToExcel } from "../../../../../Components/Helper/SaveFileExcel";
import {
  FormatCNPJ,
  formatDate,
  FormatRealNumber,
  showDate,
} from "../../../../../Components/Helper/FormatValues";
import { ShowTwoDetailList } from "../../../../../Components/ShowDetailList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 400,
      minHeight: 200,
      [theme.breakpoints.up("xs")]: { maxWidth: 480 },
      [theme.breakpoints.up("sm")]: { maxWidth: 550 },
      [theme.breakpoints.up("md")]: { maxWidth: 600 },
      [theme.breakpoints.up("lg")]: { maxWidth: 600 },
      [theme.breakpoints.up("xl")]: { maxWidth: 650 },
    },
    contentText: {
      maxWidth: 500,
      padding: "0.5rem 2rem 3rem 2rem",
    },
    text: {
      fontSize: 16,
      fontWeight: "bold",
      color: theme.palette.text.primary,
      [theme.breakpoints.up("lg")]: { fontSize: 18 },
      marginBottom: "0.5rem",
    },
    subText: {
      fontSize: 15,
      fontWeight: "bold",
      color: theme.palette.text.secondary,
      marginBottom: "1.5rem",
    },
    load: {
      maxWidth: 10,
      maxHeight: 10,
    },
    button: {
      fontFamily: "Montserrat",
      letterSpacing: 1.2,
      fontWeight: "bold",
      fontSize: 12,
      borderRadius: "1rem",
      padding: "0.4rem 1rem",
    },
    containerList: {
      padding: "0.5rem 2rem 2rem 2rem",
      maxHeight: "400px",
      overflowY: "auto",
    },
  })
);

const initialValues = {
  expected_date: null,
};

const validationSchema = Yup.object({
  expected_date: Yup.date().default(null).required("Obrigatório").nullable(),
});

const ModalChangeCheckeds = ({ open, close, selects, updateData }: any) => {
  const classes = useStyles();
  const { userInfo } = React.useContext(UserContext);
  const { changExpetectedDate } = useInvoice();
  const [submitting, setSubmitting] = React.useState(false);
  const [listApprove, setListApprove] = React.useState<any>([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleSave = () => {
    jsonToExcel(
      listApprove,
      `Recebimentos efetuados - ${moment().format("DD-MM-YYYY HH-mm-ss")}`
    );
  };

  const handleClose = () => {
    if (listApprove.length > 0) updateData();
    close();
  };

  const listItens =  listApprove.map((value: any) => ({
    icon: <Icons.Domain />,
    primary:{
      text: "Razão social: ",
      data: value["Razão social"],
    },
    secondary:{
      text: "Número: ",
      data: value["Número"],
    }
  }));
 
  const submit = async (values: any) => {
    const proms = selects
        .map((item: any) =>
        changExpetectedDate({
          _id: item.id,
          expected_date: formatDate(values.expected_date),
          updatedBy: {email: userInfo().email},
        })
      );

    await Promise.all(proms)
      .then((response: any) => {
        const data = response.map((value: any) => {
          const { data } = value;
          if (!data.hasError) {
            return {
              id: data.data._id,
              "Número": data.data?.document_id,
              "Operador financeiro": data.data?.financial_operator,
              "Data de solicitação": showDate(data.data?.createdAt),
              "Data de vencimento": showDate(data.data?.expected_date),
              "Data de recebimento": showDate(data.data?.liquidation_date), 
              Tipo: data.data?.type?.name,
              "Razão social": data.data?.people.corporate_name,
              CNPJ: FormatCNPJ(data.data?.people.cnpj),
              Valor: FormatRealNumber(data.data?.total),
            };
          }
        });
        setSubmitting(true);
        setListApprove(data);
        if(data.length > 0)
        enqueueSnackbar("Data de vencimento alterada", { variant: "success" }); else
        enqueueSnackbar("Nenhuma data de vencimento foi alterada", { variant: "info" });
      })
      .catch((error) => {
        console.log("error ", error);
      });
  };

  const { setFieldValue, handleSubmit, isSubmitting, values, touched, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: submit,
    });

  return (
    <DefaultModal open={open} handleClose={handleClose} title="Alterar datas de vencimento">
      <form noValidate onSubmit={handleSubmit}>
        <DefaultModalContent dividers className={classes.root}>
          {listItens.length === 0 ? (
            <DialogContentText className={classes.contentText}>
              <Typography className={classes.text} gutterBottom>
               Deseja alterar a data de vencimento dos itens selecionados?
              </Typography>
              <Typography className={classes.subText} gutterBottom>
               informe a data de de vencimento:
              </Typography>
              <CustomDatePicker
               variant="standard"
               inputVariant="standard"
               id="expected_date"
               name="expected_date"
               label="Data efetiva de recebimento"
               margin="none"
               onChange={(value: any) => {
                setFieldValue("expected_date", value || null);
              }}
              value={values.expected_date}
              error={touched.expected_date && Boolean(errors.expected_date)}
              helperText={touched.expected_date && errors.expected_date}
            />
            </DialogContentText>
          ) : (
            <Box className={classes.containerList}>
              <ShowTwoDetailList title="Vencimentos alterados" list={listItens} />
            </Box>
          )}
        </DefaultModalContent>
        <DefaultModalActions>
          <Button
            size="small"
            variant="contained"
            color="primary"
            aria-label="open"
            disabled={Boolean(listApprove.length <= 0)}
            className={classes.button}
            style={{ padding: "0.4rem 1.5rem", marginRight: "1rem", borderRadius: "1rem" }}
            startIcon={<Icons.Save />}
            onClick={handleSave}
          >
            Baixar
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            size="small"
            type="submit"
            disabled={isSubmitting || submitting}
            startIcon={
              isSubmitting && <CircularProgress className={classes.load} />
            }
          >
            Confirmar
          </Button>
        </DefaultModalActions>
      </form>
    </DefaultModal>
  );
};

export default ModalChangeCheckeds;

import React from 'react'
import { useParams} from "react-router-dom";
import { Box, Grid, TextField, Button, } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { Formik } from "formik";
import * as Yup from "yup";

import { InformationContext } from '../..';
import { MaskCEP, MaskCNPJ, MaskPhone, filterNumbers } from '../../../../../Components/Helper/FormatValues';
import { useSnackbar } from 'notistack';
import FormLoadingComponent from '../../../../../Components/FormLoading';
import { useCompany } from '../../../../../Services/options/useCompany';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      width: "100%",
      padding: "1rem",
      marginTop: "0.5rem",
    },
    contentAction:{
     display: "inline-flex",
     alignItems: "center",
     float:"right",
     padding:0,
     marginTop: "1rem"
    },
    buttonSave:{
      fontFamily: 'Montserrat',
      letterSpacing: 1.2,
      fontWeight: "bold",
      fontSize: 12,
      borderRadius: "1rem",
      padding: "0.5rem 2rem",
    },
  })
);

const validationSchema = Yup.object().shape({
  corporate_name: Yup.string().required("Obrigatório").nullable(),
  trading_name: Yup.string().required('Obrigatório').nullable(),
  cnpj: Yup.string().required("Obrigatório").nullable(),
  //cnpj: Yup.string().required("Obrigatório").matches(/[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}/, "CNPJ Inválido").nullable(),
  email: Yup.string().email('E-mail inválido').nullable(),
  //  telephone: Yup.string().required('Obrigatório').nullable(),
  //  constitutional_date: Yup.date().required("Obrigatório").typeError("Data inválida").nullable(),
  //  activity: Yup.string().required('Obrigatório').nullable(),

    address: Yup.object().shape({
    //zip_code: Yup.string().nullable().matches(/^[\d]{2}[\d]{3}-[\d]{3}/, "CEP Inválido"), //
  //   state: Yup.string().required('Obrigatório').nullable(),
  //    city: Yup.string().required('Obrigatório').nullable(),
  //    neighbourhood: Yup.string().required('Obrigatório').nullable(),
  //    address: Yup.string().required('Obrigatório').nullable(),
  //   number: Yup.string().required('Obrigatório').nullable(),
  //    complement: Yup.string().required('Obrigatório').nullable(),
     }).nullable(),
});

const StepOne = () => {
  const { id } = useParams();
  const { companyData, setCompanyData, setStatusStep} = React.useContext(InformationContext);
  const { update } = useCompany();
  const { enqueueSnackbar } = useSnackbar();
 
  const submit = async (values: any, { setSubmitting }: any) => {

    const buffer = {
      ...values,
      cnpj: filterNumbers(values.cnpj),
      telephone: filterNumbers(values.telephone),
      address:{
        ...values.address,
        zip_code: filterNumbers(values.address.zip_code),
      }
    }
 
    await update(id, buffer).then(r=>{
     const {data} = r; 
     if(!data.hasError){
      setCompanyData(buffer)
      enqueueSnackbar("Informações salvas", { variant: "info" });
      setStatusStep((prev:any)=>({...prev, companyData: "Informações salvas"}))
     }else{
      enqueueSnackbar("Erro", { variant: "error" });
     }
    }).catch(r=>{
      enqueueSnackbar("Erro", { variant: "error" });
    })
    setSubmitting(false)
  }

  return (
   <>
   {companyData !== null ?
    <Formik
     initialValues={companyData}
     validationSchema={validationSchema}
     validateOnBlur={false}
     //validateOnChange={true}
     enableReinitialize={true}
     onSubmit={submit}
     >
       {(props) => <Form {...props}/>}
     </Formik> : <FormLoadingComponent/>}
   </>
  )
 }

export default StepOne

const Form =(props: any)=>{
  const { setStatusStep} = React.useContext(InformationContext);
  const classes = useStyles();
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue, 
    resetForm,
    handleBlur,
    dirty,
  } = props;

  React.useEffect(()=>{
  if(dirty) setStatusStep((prev:any)=>({...prev, companyData: "Em edição"}))
  },[dirty])

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
    <Grid container className={classes.main} spacing={3}>
     <Grid item xs={6}>
      <TextField
       fullWidth
       autoFocus
       margin="none"
       size="small"
       multiline
       id="corporate_name"
       name="corporate_name"
       label="Razão Social"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       onBlur={handleBlur}
       value={values.corporate_name}
       error={touched.corporate_name && Boolean(errors.corporate_name)}
       helperText={touched.corporate_name && errors.corporate_name}
      />
     </Grid>
 
     <Grid item xs={6}>
      <TextField
       fullWidth
       multiline
       margin="none"
       size="small"
       id="trading_name"
       name="trading_name"
       label="Nome Fantasia"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       onBlur={handleBlur}
       value={values.trading_name}
       error={touched.trading_name && Boolean(errors.trading_name)}
       helperText={touched.trading_name && errors.trading_name}
      />
     </Grid>
 
     <Grid item xs={6}>
      <TextField
       fullWidth
       disabled={true}
       margin="none"
       size="small"
       id="cnpj"
       name="cnpj"
       label="CNPJ" //MaskCNPJ
       inputProps={{ autoComplete: "off" }}
       InputProps={{ inputComponent: MaskCNPJ }}
       onChange={handleChange}
       onBlur={handleBlur}
       value={values.cnpj}
       error={touched.cnpj && Boolean(errors.cnpj)}
       helperText={touched.cnpj && errors.cnpj}
      />
     </Grid>
 
     <Grid item xs={6}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="email"
       name="email"
       label="E-mail"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       onBlur={handleBlur}
       value={values.email}
       error={touched.email && Boolean(errors.email)}
       helperText={touched.email && errors.email}
      />
     </Grid>
 
     <Grid item xs={4}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="telephone"
       name="telephone"
       label="Telefone"
       inputProps={{ autoComplete: "off" }}
       InputProps={{ inputComponent: MaskPhone,
 
      }}
       onChange={handleChange}
       onBlur={handleBlur}
       value={values.telephone}
       error={touched.telephone && Boolean(errors.telephone)}
       helperText={touched.telephone && errors.telephone}
      />
     </Grid>
 
     <Grid item xs={4}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="address.zip_code"
       name="address.zip_code"
       label="CEP"
       inputProps={{ autoComplete: "off" }}
       InputProps={{ inputComponent: MaskCEP }}
       onChange={handleChange}
       onBlur={handleBlur}
       value={values.address?.zip_code}
       error={touched.address?.zip_code && Boolean(errors.address?.zip_code)}
       helperText={touched.address?.zip_code && errors.address?.zip_code}
      />
     </Grid>
 
     <Grid item xs={4}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="address.state"
       name="address.state"
       label="UF"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       onBlur={handleBlur}
       value={values.address?.state}
       error={touched.address?.state && Boolean(errors.address?.state)}
       helperText={touched.address?.state && errors.address?.state}
      />
     </Grid>
 
     <Grid item xs={6}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="address.city"
       name="address.city"
       label="Cidade"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       onBlur={handleBlur}
       value={values.address?.city}
       error={touched.address?.city && Boolean(errors.address?.city)}
       helperText={touched.address?.city && errors.address?.city}
      />
     </Grid>
 
     {/* <Grid item xs={6}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="address.neighbourhood"
       name="address.neighbourhood"
       label="Bairro"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       onBlur={handleBlur}
       value={values.address?.neighbourhood}
       error={touched.address?.neighbourhood && Boolean(errors.address?.neighbourhood)}
       helperText={touched.address?.neighbourhood && errors.address?.neighbourhood}
      />
     </Grid> */}

     <Grid item xs={6}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="address.district"
       name="address.district"
       label="Distrito"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       onBlur={handleBlur}
       value={values.address?.district}
       error={touched.address?.district && Boolean(errors.address?.district)}
       helperText={touched.address?.district && errors.address?.district}
      />
     </Grid> 
 
     <Grid item xs={5}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="address.address"
       name="address.address"
       label="Logradouro"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       onBlur={handleBlur}
       value={values.address?.address}
       error={touched.address?.address && Boolean(errors.address?.address)}
       helperText={touched.address?.address && errors.address?.address}
      />
     </Grid>
 
     <Grid item xs={2}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="address.number"
       name="address.number"
       label="Número"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       onBlur={handleBlur}
       value={values.address?.number}
       error={touched.address?.number && Boolean(errors.address?.number)}
       helperText={touched.address?.number && errors.address?.number}
      />
     </Grid>
 
     <Grid item xs={5}>
      <TextField
       fullWidth
       margin="none"
       size="small"
       id="address.complement"
       name="address.complement"
       label="Complemento"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       onBlur={handleBlur}
       value={values.address?.complement}
       error={touched.address?.complement && Boolean(errors.address?.complement)}
       helperText={touched.address?.complement && errors.address?.complement}
      />
     </Grid>


 
     {/* <Grid item xs={3}>
      <CustomDatePicker
       fullWidth
       inputVariant="standard"
       margin="none"
       size="small"
       id="constitutional_date"
       name="constitutional_date"
       label="Data de Constituição"
       inputProps={{ autoComplete: 'off' }} 
       onChange={(value: any) =>{
        setFieldValue("constitutional_date",  value || null )}}
       value={values.constitutional_date}
       onBlur={handleBlur}
       error={touched.constitutional_datecomplement && Boolean(errors.constitutional_date)}
       helperText={touched.constitutional_date && errors.constitutional_date}
      />
     </Grid> */}
 
     {/* <Grid item xs={5}>
      <TextField
       fullWidth
       multiline
       minRows={1}
       maxRows={5}
       margin="none"
       size="small"
       id="activity"
       name="activity"
       label="Atividade(s) desenvolvida(s)"
       inputProps={{ autoComplete: 'off' }} 
       onChange={handleChange}
       onBlur={handleBlur}
       value={values.activity}
       error={touched.activity && Boolean(errors.activity)}
       helperText={touched.activity && errors.activity}
      />
     </Grid> */}

     <Grid item xs={12}>
      <Box className={classes.contentAction}>
       {/* <Button
        style={{marginRight: "1rem", padding: "0.5rem 1rem"}}
        variant="contained" 
        color="primary"
        type="reset"
       >
        Resetar
       </Button> */}

       <Button
        className={classes.buttonSave}
        color="secondary"
        variant="contained" 
        type="submit" 
        disabled={isSubmitting || !dirty}
       >
        Salvar
       </Button>
      </Box>
     </Grid>
 
    </Grid>
   </form>
   )
 }
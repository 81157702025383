import React from "react";
import moment from "moment";
import { TextField } from "@material-ui/core";
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br'

export function CustomDatePicker(props: any) {
  const [open, setOpen] = React.useState(false)
  const refusePattern = () => {
    return /[^0-9]+/gi;
  };
 
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"pt-br"}> 
      <KeyboardDatePicker
        autoOk
        TextFieldComponent={TextField}
        //disableToolbar
        variant="inline"
        inputVariant="outlined"
        size="small"
        inputProps={{ autoComplete: 'off' }} 
        InputLabelProps={{
          shrink: true,
        //  required: true
        }}
        mask="__/__/____"
        maskChar="_"
   
        format="DD/MM/YYYY"
        placeholder="dd/mm/aaaa"
        maxDateMessage= 'A data não deve ser posterior à data máxima'
        minDateMessage= 'A data não deve ser anterior à data mínima'
        invalidDateMessage=""
        refuse={refusePattern()}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
       // onClick={() => setOpen(true)}
        open={open}
        {...props}
      />
      </MuiPickersUtilsProvider>
  );
}

 

import useAxios from "../../Hooks/useAxios";

export function useInvoice(){
   const { request, loading, error, buffer } = useAxios();
  
   const list = async () => {
     return await request({
      method: "get",
      url: `invoice/list`,
      headers: { "Content-Type": "application/json" },
    })
   };

   const listByNature = async (id: string) => {
    return await request({
      method: "get",
      url: `invoice/nature/${id}`,
      headers: { "Content-Type": "application/json" },
     })
   };

   const searchList = async (body: object) => {
    return await request({
      method: "post",
      url: "invoice/search",
      headers: { "Content-Type": "application/json" },
      data: body
    })
   };

   const validate = async (body: any) => {
    return await request({
      method: "post",
      url: "financial-transaction/check-invoice",
      headers: { "Content-Type": "application/json" },
      data: body
    })
   };

   const changeLiquidationDate = async (body: any) => {
    return await request({
      method: "post",
      url: "invoice/liquidation",
      headers: { "Content-Type": "application/json" },
      data: body
    })
   };

   const changExpetectedDate = async (body: any) => {
    return await request({
      method: "post",
      url: "invoice/update/expected-date",
      headers: { "Content-Type": "application/json" },
      data: body
    })
   };

   const observationUpdate = async (body: any) => {
    return await request({
      method: "post",
      url: "invoice/update/observation",
      headers: { "Content-Type": "application/json" },
      data: body
    })
   };

   const upload = async (body: any) => {
    return await request({
      method: "post",
      url: "invoice/upload",
      headers: { "Content-Type": "multipart/form-data"},
      data: body
    })
   };

   const removeFile = async (body: any) => {
    return await request({
      method: "post",
      url: "invoice/upload/remove",
      headers: { "Content-Type": "application/json" },
      data: body
    })
   };

  return{ list, listByNature, validate, changeLiquidationDate, observationUpdate, upload, removeFile, searchList, changExpetectedDate,
          loading, error, buffer } 
}
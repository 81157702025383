import React, { SetStateAction, ReactNode, Dispatch } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme, } from '@material-ui/core/styles';
import DefaultModal, { DefaultModalActions, DefaultModalContent } from '../../../../../Components/DefaultModal'
import { filterNumbers, MaskCEP, MaskCNPJ, MaskCPF, MaskPhone } from '../../../../../Components/Helper/FormatValues';
import { useSnackbar } from 'notistack';
import { InformationContext } from '../..';
 
const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    display: "flex",
    padding: theme.spacing(0, 1, 0, 1),
    [theme.breakpoints.up("xs")]: {width: 350, },
    [theme.breakpoints.up("sm")]: {width: 380, },
    [theme.breakpoints.up("md")]: {width: 500, },
    [theme.breakpoints.up("lg")]: {width: 600, },
    [theme.breakpoints.up("xl")]: {width: 750, },
  },
})
);

const validationSchema = Yup.object().shape({
  name : Yup.string().required('Nome obrigatório'),
  cpf: Yup.string().required("Obrigatório").nullable(),
  email: Yup.string().email('E-mail inválido').required('Obrigatório'),
  telephone: Yup.string().required('Obrigatório'),
  zip_code: Yup.string().required("Obrigatório").matches(/^[\d]{2}[\d]{3}-[\d]{3}/, "CEP Inválido"), //
  state: Yup.string().required('Obrigatório'),
  city: Yup.string().required('Obrigatório'),
  district: Yup.string().required('Obrigatório'),
  address: Yup.string().required('Obrigatório'),
  number: Yup.string().required('Obrigatório'),
  complement: Yup.string().required('Obrigatório'),
});

const ActionEdit = ({select, open, close}:any) => {
  const { formik } = React.useContext(InformationContext);
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (values: any) => {
     const buffer = {
      ...values,
      cpf: filterNumbers(values.cpf),
      zip_code: filterNumbers(values.zip_code),
      telephone: filterNumbers(values.telephone),
    }
     const data = formik.values.person.map((value: any) => {
      return value.id === select.id ? buffer:value;
    })
    formik.setFieldValue("person", data);
    enqueueSnackbar("Sócio atualizado", { variant: "info" });
    close();
  }

  return (
   <DefaultModal open={open} handleClose={close} title="Atualizar sócio">
    <Formik
      initialValues={select}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {(props) => <Form {...props} />}
    </Formik>
   </DefaultModal>
  )
}

export default ActionEdit

const Form = (props: any) => {
  const classes = useStyles();
  const {
    values,
    touched,
    errors,
    dirty,
    handleChange,
    handleSubmit,
  } = props;
 
  return (
    <form onSubmit={handleSubmit} noValidate>
     <DefaultModalContent dividers>
      <Grid container className={classes.root} spacing={3}> 
       <Grid item xs={6}>
        <TextField
         fullWidth
         autoFocus
         margin="none"
         id="name"
         name="name"
         label="Nome"
         inputProps={{ autoComplete: "off" }}
         onChange={handleChange}
         value={values.name}
         error={touched.name && Boolean(errors.name)}
         helperText={touched.name && errors.name}
         />
       </Grid>
   
       <Grid item xs={6}>
        <TextField
         fullWidth
         id="cpf"
         name="cpf"
         label="CPF"
         inputProps={{ autoComplete: "off" }}
         InputProps={{ inputComponent: MaskCPF }}
         onChange={handleChange}
         value={values.cpf}
         error={touched.cpf && Boolean(errors.cpf)}
         helperText={touched.cpf && errors.cpf}
         />
       </Grid>
   
       <Grid item xs={6}>
         <TextField
          fullWidth
          margin="none"
          size="small"
          id="email"
          name="email"
          label="E-mail"
          inputProps={{ autoComplete: 'off' }} 
          onChange={handleChange}
          value={values.email}
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
         />
        </Grid>
   
        <Grid item xs={6}>
         <TextField
          fullWidth
          margin="none"
          size="small"
          id="telephone"
          name="telephone"
          label="Telefone"
          inputProps={{ autoComplete: "off" }}
          InputProps={{ inputComponent: MaskPhone }}
          onChange={handleChange}
          value={values.telephone}
          error={touched.telephone && Boolean(errors.telephone)}
          helperText={touched.telephone && errors.telephone}
         />
        </Grid>
   
        <Grid item xs={6}>
         <TextField
          fullWidth
          margin="none"
          size="small"
          id="city"
          name="city"
          label="Cidade"
          inputProps={{ autoComplete: 'off' }} 
          onChange={handleChange}
          value={values.city}
          error={touched.city && Boolean(errors.city)}
          helperText={touched.city && errors.city}
         />
        </Grid>
   
        <Grid item xs={6}>
         <TextField
          fullWidth
          margin="none"
          size="small"
          id="district"
          name="district"
          label="Bairro"
          inputProps={{ autoComplete: 'off' }} 
          onChange={handleChange}
          value={values.district}
          error={touched.district && Boolean(errors.district)}
          helperText={touched.district && errors.district}
         />
        </Grid>
   
        <Grid item xs={6}>
         <TextField
          fullWidth
          margin="none"
          size="small"
          id="address"
          name="address"
          label="Logradouro"
          inputProps={{ autoComplete: 'off' }} 
          onChange={handleChange}
          value={values.address}
          error={touched.address && Boolean(errors.address)}
          helperText={touched.address && errors.address}
         />
        </Grid>
   
        <Grid item xs={6}>
         <TextField
          fullWidth
          margin="none"
          size="small"
          id="complement"
          name="complement"
          label="Complemento"
          inputProps={{ autoComplete: 'off' }} 
          onChange={handleChange}
          value={values.complement}
          error={touched.complement && Boolean(errors.complement)}
          helperText={touched.complement && errors.complement}
         />
        </Grid>
   
        <Grid item xs={4}>
         <TextField
          fullWidth
          margin="none"
          size="small"
          id="number"
          name="number"
          label="Número"
          inputProps={{ autoComplete: 'off' }} 
          onChange={handleChange}
          value={values.number}
          error={touched.number && Boolean(errors.number)}
          helperText={touched.number && errors.number}
         />
        </Grid>
   
        <Grid item xs={4}>
         <TextField
          fullWidth
          margin="none"
          size="small"
          id="state"
          name="state"
          label="UF"
          inputProps={{ autoComplete: 'off' }} 
          onChange={handleChange}
          value={values.state}
          error={touched.state && Boolean(errors.state)}
          helperText={touched.state && errors.state}
         />
        </Grid>
   
        <Grid item xs={4}>
         <TextField
          fullWidth
          margin="none"
          size="small"
          id="zip_code"
          name="zip_code"
          label="CEP"
          inputProps={{ autoComplete: "off" }}
          InputProps={{ inputComponent: MaskCEP }}
          onChange={handleChange}
          value={values.zip_code}
          error={touched.zip_code && Boolean(errors.zip_code)}
          helperText={touched.zip_code && errors.zip_code}
         />
        </Grid>
       
       </Grid>
      </DefaultModalContent>
      <DefaultModalActions style={{marginRight: "0.5rem"}}>
       <Button
       style={{ width: 110 }}
       variant="contained"
       color="primary"
       type="submit"
       disabled={!dirty}>
       Confirmar
       </Button>
     </DefaultModalActions>
     </form>
    );
   };
import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { InformationContext } from "..";
import ListFiles from "../../../../Components/ListFiles";
import ModalUpload from "./Modal/ModalUpload";
import { useCompany } from "../../../../Services/options/useCompany";

const useStyles = makeStyles((theme: Theme) => ({
  root:{

  },
  main: {
    [theme.breakpoints.up("xs")]: {width: "100%", padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("sm")]: {width: "80%", padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("md")]: {width: "60%", padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("lg")]: {width: "50%", padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("xl")]: {width: "40%", padding: theme.spacing(0, 1, 0, 1),},
  },
  actionButton:{
    fontFamily: 'Montserrat',
    letterSpacing: 1.2,
    fontWeight: "bold",
    fontSize: 12,
    borderRadius: "1rem",
    padding: "0.4rem 1.5rem",
    width:"250px"
  },
 
}));

const TabAttachments = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { attach, updateData } = React.useContext(InformationContext);
  const { enqueueSnackbar } = useSnackbar();
  const { loading, removeUpload, openAttachment } = useCompany();
  const [openView, setOpenView] = React.useState(false);
  const [option, setOption] = React.useState(null);
 
  const onDelete = async(fileId: any)=> {
    const buffer ={
      cnpj: id,
      fileId: fileId,
    }
     await removeUpload(buffer).then(r=>{
      const { data } = r;
      if(!data.hasError){
       enqueueSnackbar("Arquivo apagado", { variant: "info" });
       updateData()
      }else{
       enqueueSnackbar("Erro", { variant: "error" });
      }
     }).catch(e=>{

     })
  }

  const onOpenAttachment = async (path: string) => {
      await openAttachment(path).then(r=>{
        const { data } = r;
        window.open(data.url);
      });
  }

  const handleOpenView =(id: any)=>{
    setOption(id)
    setOpenView(true)
  }

  const handleCloseView =()=>{
    setOpenView(false)
    setOption(null)
  }

  const getSelectContent = React.useCallback((option: any) => { 
    switch (option) {
     case 0: return <ModalUpload open={openView} close={handleCloseView}/>
     default: return null;
    }
  }, [option]);

  return (
 <>
  {getSelectContent(option)}
  <Grid container justifyContent="flex-start" alignItems="center" style={{margin: "1rem 0rem"}}>
   <Grid item xs={12} style={{ justifyContent:"center", display:"flex"}}>
    <Button 
     className={classes.actionButton}
     variant="contained"
     color="primary"
     onClick={()=>handleOpenView(0)}
     >
     Novo arquivo
    </Button>
   </Grid> 
   <Grid item xs={12}>
    <ListFiles onDelete={onDelete} loading={loading} list={attach}/> 
   </Grid> 
  </Grid>
 </>    
  );
};

export default TabAttachments;

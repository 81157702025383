import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  subTitle: {
    fontWeight: 'bold',
    letterSpacing: '0px',
    fontFamily: 'Montserrat',
    color: theme.palette.secondary.main,
    marginBottom: '0.5rem',
    [theme.breakpoints.up('xs')]: { fontSize: 15 },
    [theme.breakpoints.up('xl')]: { fontSize: 17 },
  },
  listTextItem: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('xs')]: { fontSize: 14 },
    [theme.breakpoints.up('md')]: { fontSize: 15 },
    [theme.breakpoints.up('xl')]: { fontSize: 16 },
  },
  listTextSubItem: {
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    margin: '0px 0px 0px 10px',
    [theme.breakpoints.up('xs')]: { fontSize: 12 },
    [theme.breakpoints.up('md')]: { fontSize: 13 },
    [theme.breakpoints.up('xl')]: { fontSize: 14 },
  },
}));

interface IListItem {
  icon: any;
  text: string;
  data: any;
}

interface IDetailList {
  title?: String;
  list: IListItem[];
  className?: any;
}

interface IListTwoItem {
  icon: any;
  primary?:{
    text?: string;
    data?: any;
  };
  secondary?:{
    text?: string;
    data?: any;
  }
}

interface IDetailTwoList {
  title?: String;
  list: IListTwoItem[];
  className?: any;
}

export const ShowDetailList = ({ title, list, className }: IDetailList) => {
  const classes = useStyles();
  return (
    <List
      className={className}
      subheader={
        title && <Typography className={classes.subTitle}>{title}</Typography>
      }
    >
      {list
        .filter((r: IListItem) => r.data !== null)
        .map((value: IListItem) => (
          <ListItem key={value.text}>
            <ListItemIcon>{value.icon}</ListItemIcon>
            <Box display="flex" alignItems="center">
              <Typography component="span" className={classes.listTextItem}>
                {value.text}
              </Typography>
              <Typography component="div" className={classes.listTextSubItem}>
                {value.data ? value.data : <Skeleton width="100px" />}
              </Typography>
            </Box>
          </ListItem>
        ))}
    </List>
  );
};

export const ShowTwoDetailList = ({ title, list, className }: IDetailTwoList) => {
  const classes = useStyles();
  return (
    <List
      className={className}
      subheader={
        title && <Typography className={classes.subTitle}>{title}</Typography>
      }
    >
      {list
        .filter((r: IListTwoItem) => r.primary?.data !== null)
        .map((value: IListTwoItem, index) => (
          <ListItem key={`${value.primary?.data}-${index}`} alignItems="flex-start">
            <ListItemIcon>{value.icon}</ListItemIcon>
            <ListItemText
              primary={
                <Box display="flex" alignItems="center">
                  <Typography component="span" className={classes.listTextItem}>
                    {value.primary?.text}
                  </Typography>
                  <Typography
                    component="div"
                    className={classes.listTextSubItem}
                  >
                    {value.primary?.data ? value.primary?.data : <Skeleton width="100px" />}
                  </Typography>
                </Box>
              }
              secondary={
                <>
                { value.secondary?.data && <Box display="flex" alignItems="center">
                  <Typography component="span" className={classes.listTextItem}>
                    {value.secondary?.text}
                  </Typography>
                  <Typography
                    component="div"
                    className={classes.listTextSubItem}
                  >
                    {value.secondary?.data ? value.secondary?.data : <Skeleton width="100px" />}
                  </Typography>
                </Box>}
                </>
              }
            />
          </ListItem>
        ))}
    </List>
  );
};

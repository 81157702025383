import React from 'react'
import * as Icons from "@material-ui/icons";
import { Grid } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DefaultModal, { DefaultModalContent } from '../../../../../../Components/DefaultModal';
import ActionDelete from './ActionDelete';
import ActionEdit from './ActionEdit';
import { InformationContext } from '../../..';
import { formatCEP, FormatCNPJ } from '../../../../../../Components/Helper/FormatValues';
import { ShowDetailList } from '../../../../../../Components/ShowDetailList';

import { LegalPerson as InterfacePerson } from 'kikai-model/src/interface/company/Owner';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root:{
   minHeight: "14rem",
  },
  main: {
    [theme.breakpoints.up("xs")]: { minWidth: 380 },
    [theme.breakpoints.up("sm")]: { minWidth: 380 },
    [theme.breakpoints.up("md")]: { minWidth: 450 },  
    [theme.breakpoints.up("lg")]: { minWidth: 550 },
    [theme.breakpoints.up("xl")]: { minWidth: 650 },
  },
  speedDial:{
    transform: 'scale(0.825)',
  },
})
);

export const ActionShow = ({id, open, close}: any) => {
  const classes = useStyles();
  const [openDial, setOpenDial] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [select, setSelect] = React.useState(null);
  const { legalPerson } = React.useContext(InformationContext);
  const selectData : InterfacePerson = legalPerson[id]

  const listInfo = [
    {
      icon: <Icons.Domain />,
      text: "Razão social: ",
      data: selectData?.company_name,
    },
    {
      icon: <Icons.Dashboard />,
      text: "CNPJ: ",
      data: selectData?.cnpj && FormatCNPJ(selectData?.cnpj),
    }, 
    {
      icon: <Icons.NotListedLocation />,
      text: "Cidade: ",
      data: selectData?.city,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "Bairro: ",
      data: selectData?.district,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "Logradouro: ",
      data: selectData?.address,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "Complemento: ",
      data: selectData?.complement,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "Número: ",
      data: selectData?.number,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "UF: ",
      data: selectData?.state,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "CEP: ",
      data: selectData?.zip_code && formatCEP(selectData?.zip_code),
    },
  ]     

  const actions = [
    { icon: <Icons.Edit />, 
      name: 'Atualizar beneficiário',
      option: 0, 
      disabled: false},  
    { icon: <Icons.Cancel/>, 
      name: 'Apagar beneficiário',
      option: 1,  
      disabled: false},     
    ]

  const handlActionsOpen = (e:any) => {
    if(e.type === "mouseenter" || e.type === "click")
    setOpenDial(true);
  };

  const handlActionsClose = (e:any) => {//mouseleave
    if(e.type === "mouseleave")
    setOpenDial(false);
  };

  const handleOpenView =(id: any)=>{
    setSelect(id)
    setOpenView(true)
  }

  const handleCloseView =()=>{
    setOpenView(false)
    setSelect(null)
  }

  const getSelectContent = React.useCallback((select: any) => { 
    switch (select) {
     case 0: return <ActionEdit   open={openView} close={handleCloseView}/>
     case 1: return <ActionDelete open={openView} close={handleCloseView}/>
     default: return null;
    }
  }, [select]);
  return (
 <>
  {getSelectContent(select)}
   <DefaultModal open={open} handleClose={close} title="Sócio - Pessoa Jurídica" maxWidth="lg">
    <DefaultModalContent dividers className={classes.root}>
     <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start"className={classes.main}>
      <Grid item xs={12} style={{width:"100%"}}>
       <ShowDetailList list={listInfo} />
      </Grid>  
     </Grid>  
    </DefaultModalContent>
   </DefaultModal>
  </>
  )
 }

export default ActionShow
import React from 'react'
import {
  Grid,
  Container,
  createStyles,
  makeStyles,
  Paper,
} from "@material-ui/core";
import Table from './Table';
import CurrentPageInfo from '../../Components/Helper/CurrentPageInfo';
import { useInvoice } from '../../Services/options/useInvoice';
import { Filter } from './Filter';
import { formatArrayToObject, formatDate } from '../../Components/Helper/FormatValues';
import moment from 'moment';

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: "100%",
      padding: theme.spacing(2),
      borderRadius: "10px",
    },
    table:{
      borderRadius: "0.6rem",
      [theme.breakpoints.up("xs")]: { minHeight: 450, },
      [theme.breakpoints.up("sm")]: { minHeight: 450, },
      [theme.breakpoints.up("md")]: { minHeight: 450, },
      [theme.breakpoints.up("lg")]: { minHeight: 450, },
      [theme.breakpoints.up("xl")]: { minHeight: 610, },
    },
  })
);

interface PaymentsProps {
  _id: string,
  document_id: string,
  financial_operator: string,
  createdAt: string,
  liquidation_date: string,
  expected_date: string,
  type: {
    id: string,
    name: string,
  },
  people: {
    corporate_name:string,
    cnpj:string,
  },
  total: string,
}
 
const Receipts = () => {
  const classes = useStyles();
  const { loading, error, searchList, listByNature } = useInvoice();
  const [data, setData] = React.useState<any[]>([]);
  const [search, setSearch] = React.useState({data:[{natureId: '1'}]});

  function status(value: any){
    if(value?.liquidation_date === undefined) return "";
    if(value?.liquidation_date === null){
      if(moment(formatDate(value?.expected_date)).isSameOrAfter(formatDate(new Date())))
      //  return "A vencer"
      //   else 
        return "Não efetuado"
     }else 
      return "Efetuado"
  }

  const itemList = (list: PaymentsProps[]) =>{
    return list.map((item: PaymentsProps)=>({
      id: item._id,
      document_id: item?.document_id,
      financial_operator: item?.financial_operator,
      createdAt: item.createdAt,
      expected_date: item.expected_date,
      liquidation_date: item.liquidation_date,
      status: status(item),
      type: item.type.name,
      corporate_name: item.people.corporate_name,
      cnpj: item.people.cnpj,
      value: item.total,
    }))
   }

   const updateData = async () => {
    if(search.data.length > 1){
      const aux = formatArrayToObject(search.data)
      await searchList(aux).then(r=>{
        const data: PaymentsProps[] = r.data?.data; 
        setData(itemList(data))
      }
     )
    } else {
    await listByNature("1").then(r=>{
      const data: PaymentsProps[] = r.data?.data; 
     setData(itemList(data))
   }
  )
 }
 };

  React.useEffect(()=>{
    updateData();
  },[search]);
 
 return (
 <Container maxWidth="xl">
  <CurrentPageInfo primary="Recebimentos"/>
  <Grid item xs={12}>
     <Paper className={classes.paper}>
      <Filter setSearch={setSearch}/> 
    </Paper> 
   </Grid>        

    <Grid item xs={12} style={{marginTop: "0.5rem"}}>
     <Paper className={classes.table}>
      <Table data={data} error={error} load={loading} updateData={updateData}/> 
     </Paper>
    </Grid>
 </Container>   
 )
}

export default Receipts
import React from "react";
import { Box, Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import { FormatCNPJ, showDate, FormatRealNumber } from "../../Components/Helper/FormatValues";
import CustomTable from "../../Components/Table";
import ModalAccept from "./Description/TabInformation/Modal/Accept";
import { jsonToExcel } from "../../Components/Helper/SaveFileExcel";
import moment from "moment";
import ModalPaymentsAcceptCheckeds from "./Description/TabInformation/Modal/AcceptCheckeds";
 
interface PropsTable {
  data     : any;
  load     : boolean;
  error    : any;
  updateData: any;
}
 
export default function ReadTable({data, error, load, updateData}:PropsTable) {
  const [openAccept, setOpenAccept] = React.useState({id: "", open: false})
  const options = {
    selectableRows: "multiple",
    selectableRowsHideCheckboxes: false,
    selectableRowsHeader: true,
    selectToolbarPlacement: 'replace',
    elevation: 0,
   customToolbarSelect: (
     selectedRows: any,
     displayData: any,
     setSelectedRows: any
   ) => (
     <CustomToolbarSelect
       selectedRows={selectedRows}
       displayData={displayData}
       setSelectedRows={setSelectedRows}
       updateData={updateData}
     />
   ),
 };

  const handleOpen =(id: string, status: boolean)=>{
    if(status) return

    setOpenAccept({id, open: true})
  }

  const handleClose =()=>{
    setOpenAccept({id: "", open: false})
  }

  const actions = ({tableMeta}: any) =>{
    const rowId = tableMeta.rowData[0];
    const rowStatus = Boolean(tableMeta.rowData[7] === "Efetuado");
    return (
      <Grid
        container
        spacing={1}
        direction="row"
        wrap="nowrap"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
        <Tooltip placement="top" title="Confirmar pagamento" aria-label="view">
          <span>
           <IconButton color="secondary" aria-label="open" style={rowStatus ? { padding: 0 }: {padding: 0, color: "#B6B6B6"}}
            //disabled={rowStatus}
            onClick={() => handleOpen(rowId, rowStatus) }
           >
             <Icons.CheckCircle />
           </IconButton>
          </span>
         </Tooltip>
        </Grid>
        <Grid item xs={12}>
         <Tooltip placement="top" title="Visualizar" aria-label="view">
          <IconButton size="small" color="primary" aria-label="open"
           onClick={() => window.open(`/payments/description/${rowId}`, '_blank') }
          >
            <Icons.Description/>
          </IconButton>
         </Tooltip>
        </Grid>
      </Grid>
    );
  }
  const columns = [
    { name: "id",label: "Id", options: { display: "false" } },
    { name: "action",
      label: "Ação",
      options: { filter: true, sort: true,
      customBodyRender: (value: any, tableMeta: any, updateValue: any) =>  actions({tableMeta})},
    },
    { name: "document_id",label: "Número", options: { display: "true" } },
    { name: "financial_operator",label: "Operador financeiro", options: { filter: true, sort: true, 
      customBodyRender: (value:any) => FormatCNPJ(value) },
   },
    { name: "createdAt",
      label: "Data de solicitação",
      options: { filter: true, sort: true, 
       customBodyRender: (value:any) => showDate(value, "-") },
    },
    { name: "expected_date",
      label: "Data de vencimento",
      options: { filter: true, sort: true, 
       customBodyRender: (value:any) => showDate(value, "-") },
    },
    { name: "liquidation_date",
      label: "Data de pagamento",
      options: { filter: true, sort: true, 
       customBodyRender: (value:any) => showDate(value, "-") },
    },
  
    { name: "status",
      label: "Status",
      options: { filter: true, sort: true, },},
  
    { name: "type",
      label: "Tipo",
      options: { filter: true, sort: true, },},
  
    { name: "corporate_name",
      label: "Razão social",
      options: { filter: true, sort: true, },},
  
    { name: "cnpj",
      label: "CNPJ",
      options: { filter: true, sort: true, 
       customBodyRender: (value:any) => FormatCNPJ(value) },
    },
  
    { name: "value",
      label: "Valor",
      options: { filter: true, sort: true, 
       customBodyRender: (value:any) => FormatRealNumber(value) },
    },

   ];
 
  
   return (
    <>
     {openAccept.open && <ModalAccept open={openAccept.open} close={handleClose} id={openAccept.id} updateData={updateData}/> } 
    <CustomTable data={data} columns={columns} opt={options} error={error} load={load} elevation={0}/>
    </>
   );
 }

 const CustomToolbarSelect = ({
  selectedRows,
  displayData,
  updateData,
}: any) => {
  const [modalAccept, setModalAccept] = React.useState({
    open: false,
    ids: [],
  });

  const handleAcceptCheckeds = () => {
    const aux = selectedRows.data.map(
      ({ index }: any) => ({ id: displayData[index].data[0],
                             status: displayData[index].data[7],}));
    setModalAccept({ open: true, ids: aux });
  };

  const handleCloseModalCheckeds = () => {
    setModalAccept({ open: false, ids: [] });
  };

  const handleFormat = () => {
    const aux = selectedRows.data.map(({ index }: any) => ({
      id: displayData[index].data[0],
      'Número': displayData[index].data[2],
      'Operador financeiro': displayData[index].data[3],
      "Data de solicitação": displayData[index].data[4],
      "Data de vencimento": displayData[index].data[5],
      "Data de pagamento": displayData[index].data[6],
      Status: displayData[index].data[7],
      Tipo: displayData[index].data[8],
      "Razão social": displayData[index].data[9],
      CNPJ: displayData[index].data[10],
      Valor: displayData[index].data[11],
    }));
     jsonToExcel(aux, `Pagamentos - ${moment().format('DD-MM-YYYY HH-mm-ss')}`);
  };

  return (
    <Box 
      style={{
        gap: '2rem',
        display: 'flex',
        padding: "0rem 1rem",
        justifyContent: "start",
        width: "88%", 
      }}
    >
      {modalAccept.open && (
        <ModalPaymentsAcceptCheckeds
          open={modalAccept.open}
          close={handleCloseModalCheckeds}
          selects={modalAccept.ids}
          updateData={updateData}
        />
      )}  
      <Box>
        <Tooltip placement="top" title="Confirmar pagamento" aria-label="view">
          <span>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              aria-label="open"
              style={{ padding: "0.5rem 1rem", borderRadius: "1rem" }}
              startIcon={<Icons.CheckCircle />}
              onClick={handleAcceptCheckeds}
            >
              Confirmar Pagamento
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip placement="top" title="Visualizar" aria-label="view">
        <span>
            <Button
              size="small"
              variant="contained"
              color="primary"
              aria-label="open"
              style={{ padding: "0.5rem 1rem", borderRadius: "1rem" }}
              startIcon={<Icons.Save  />}
              onClick={handleFormat}
            >
              Baixar
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};

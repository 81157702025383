import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CurrentPageInfo from '../../../Components/Helper/CurrentPageInfo';

import TabInformation from './TabInformation';
import TabObservation from './TabObservation';
import { useInvoice } from '../../../Services/options/useInvoice';
import { useSnackbar } from 'notistack';
import TabAttachments from './TabAttachments';
import { useAnticipation } from '../../../Services/options/useAnticipation';
import ModalInvalidInvoice from './TabInformation/Modal/InvalidInvoice';
import { FormatCNPJ } from '../../../Components/Helper/FormatValues';
import { Container } from '@material-ui/core';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="div" p={3}>
         {children} 
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "10px",
  },
  tabs:{
   borderRadius: "10px 10px 0px 0px",
  }
}));

export const ReceiptsContext = React.createContext<any>(null);

export default function ReceiptsDescription() {
  const classes = useStyles();
  const { id }  = useParams();
  const [data, setData] = React.useState<any>(null);
  const [ observation, setObservation] = React.useState<any>();
  const [ attach, setAttach] = React.useState<any>([]);
  const [ bank, setBank] = React.useState<any>([])
  const [ isValid, setIsValid] = React.useState<boolean>(false)
  const { validate, searchList } = useInvoice();
  const { description: descriptionAnticipation } = useAnticipation()
  const [ value, setValue ] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => setValue(newValue);
  const [open, setOpen] = React.useState<boolean>(false)

  const title = `${FormatCNPJ(data?.people?.cnpj)} - ${data?.people?.corporate_name} - ${data?.type?.name}`;

  const updateData = async ()=>{
    const buffer = { 
        _id: id 
    }
    try {
     const response = await searchList(buffer);
     const data = response.data.data[0];
     setData(data);
     setObservation(data.observation);
     setAttach(data.attachment);

     await descriptionAnticipation(data.anticipation_id).then(r=>{
      const data = r.data.data[0];
      setBank(data.bank)
     });

     const isValidInvoice = await validate(data);
     if(!isValidInvoice.data.hasError){
      setIsValid(true);// seta como valido o registo
     }else{
      enqueueSnackbar("Falha na autenticidade do registro", { variant: "error" });
      setOpen(true);//caso seja invalido o registro abre o modal de aviso
     }
   } catch (error) {
    enqueueSnackbar("Erro", { variant: "error" });
   }
  }

  React.useEffect(()=>{
    updateData();
  },[]);

 return (
  <>
   <ModalInvalidInvoice open={open} setOpen={()=>setOpen(false)}/>
   <ReceiptsContext.Provider value={{
    updateData, data, isValid,
    observation, setObservation,
    attach, setAttach,
    bank, setBank,
  }}>
  <Container maxWidth={"xl"}>
   <CurrentPageInfo primary="Recebimentos" secondary={title} loading={data === null} />
    <div className={classes.root}>
      <AppBar position="static" className={classes.tabs}>
        <Tabs value={value} onChange={handleChange} aria-label="tab description" centered >
          <Tab label="Informações" {...a11yProps(0)} />
          <Tab label="Anexos"      {...a11yProps(1)} />
          <Tab label="Observações" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TabInformation/>
      </TabPanel>
      <TabPanel value={value} index={1}>
       <TabAttachments/>
      </TabPanel>
      <TabPanel value={value} index={2}>
       <TabObservation/>
      </TabPanel>
    </div>
    </Container>
   </ReceiptsContext.Provider>
  </>    
 );
}

import React from 'react'
import * as Icons from "@material-ui/icons";
import { Grid } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DefaultModal, { DefaultModalContent } from '../../../../../Components/DefaultModal';
import ActionDelete from './ActionDelete';
import ActionEdit from './ActionEdit';
import { InformationContext } from '../..';
import { formatCEP, FormatCNPJ } from '../../../../../Components/Helper/FormatValues';
import { ShowDetailList } from '../../../../../Components/ShowDetailList';

import { LegalPerson as InterfacePerson } from 'kikai-model/src/interface/company/Owner';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root:{
   minHeight: "14rem",
  },
  main: {
    [theme.breakpoints.up("xs")]: { minWidth: 380 },
    [theme.breakpoints.up("sm")]: { minWidth: 380 },
    [theme.breakpoints.up("md")]: { minWidth: 450 },  
    [theme.breakpoints.up("lg")]: { minWidth: 550 },
    [theme.breakpoints.up("xl")]: { minWidth: 650 },
  },
  speedDial:{
    transform: 'scale(0.825)',
  },
})
);

export const ActionShow = ({select, open, close}: any) => {
  const classes = useStyles();
  const [openDial, setOpenDial] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [options, setOptions] = React.useState(null);
  const { formik } = React.useContext(InformationContext);
  const selectData: InterfacePerson = formik.values.legalPerson[select];

  const listInfo = [
    {
      icon: <Icons.Domain />,
      text: "Razão social: ",
      data: selectData?.company_name,
    },
    {
      icon: <Icons.Dashboard />,
      text: "CNPJ: ",
      data: selectData?.cnpj && FormatCNPJ(selectData?.cnpj),
    }, 
    {
      icon: <Icons.NotListedLocation />,
      text: "Cidade: ",
      data: selectData?.city,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "Bairro: ",
      data: selectData?.district,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "Logradouro: ",
      data: selectData?.address,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "Complemento: ",
      data: selectData?.complement,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "Número: ",
      data: selectData?.number,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "UF: ",
      data: selectData?.state,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "CEP: ",
      data: selectData?.zip_code && formatCEP(selectData?.zip_code),
    },
  ]     

  const actions = [
    { icon: <Icons.Edit />, 
      name: 'Atualizar',
      option: 0, 
      disabled: false},  
    { icon: <Icons.Cancel/>, 
      name: 'Apagar',
      option: 1,  
      disabled: false},     
    ]

  const handlActionsOpen = (e:any) => {
    if(e.type === "mouseenter" || e.type === "click")
    setOpenDial(true);
  };

  const handlActionsClose = (e:any) => {//mouseleave
    if(e.type === "mouseleave")
    setOpenDial(false);
  };

  const handleOpenView =(id: any)=>{
    setOptions(id)
    setOpenView(true)
  }

  const handleCloseView =()=>{
    setOpenView(false)
    setOptions(null)
  }

  const handleFullClose=()=>{
    handleCloseView()
    close()
  }

  const getSelectContent = React.useCallback((select: any) => { 
    switch (select) {
     case 0: return <ActionEdit   select={selectData} open={openView} close={handleCloseView}/>
     case 1: return <ActionDelete select={selectData} open={openView} close={handleCloseView} handleFullClose={handleFullClose}/>
     default: return null;
    }
  }, [options]);
  return (
 <>
  {getSelectContent(options)}
   <DefaultModal open={open} handleClose={close} title="Sócio - Pessoa Jurídica" maxWidth="lg">
    <DefaultModalContent dividers className={classes.root}>
     <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start"className={classes.main}>
      <Grid item xs={12}>
        <SpeedDial
          ariaLabel="SpeedDialClick"
          className={classes.speedDial}
          icon={ <Icons.Settings  />  }
          direction="right"
          FabProps={{size:"small" }}
          onClose={handlActionsClose}
          onOpen={handlActionsOpen}
          open={openDial} 
          >
          {actions.map((action: any) => (
           <SpeedDialAction
            tooltipPlacement="top"
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            hidden={action.disabled}
            onClick={()=>handleOpenView(action.option)}
            />
          ))}
         </SpeedDial>  
       </Grid>
      <Grid item xs={12} style={{width:"100%"}}>
       <ShowDetailList list={listInfo} />
      </Grid>  
     </Grid>  
    </DefaultModalContent>
   </DefaultModal>
  </>
  )
 }

export default ActionShow